import React, { useState, useEffect } from 'react';
import { FaTicketAlt, FaUsers, FaExclamationCircle, FaUserAlt, FaThumbsUp, FaThumbsDown } from 'react-icons/fa';
import { MdSecurity, MdOutlineViewTimeline, MdDateRange, MdModeEdit, MdSave, MdEditOff, MdOutlineTypeSpecimen, MdBookmark } from 'react-icons/md';
import { BsPersonLinesFill } from "react-icons/bs";
import { SiJira } from "react-icons/si";
import { LuBrainCog } from "react-icons/lu";
import { BiInfoCircle } from 'react-icons/bi';
import { Oval } from 'react-loader-spinner';
import axios from 'axios';
import './ThreatModelDetails.scss';
import RegenerateFeedbackModal from './RegenerateFeedbackModal';

interface ThreatModel {
    exercise_type: string;
    jira_issue_id: string;
    issue_type: string;
    assignee: string;
    jira_release: string;
    jira_title: string;
    last_updated: string;
    priority: string;
    product_team: string;
    security_relevance_score: number;
    threat_model: string;
    requestor: string;
    feedback: 'Liked' | 'Disliked' | 'Neutral';
    [key: string]: any; // Threat Model versioning
}

interface ThreatModelDetailsProps {
    model: ThreatModel;
    onClose: () => void;
    onModelUpdate: (model: ThreatModel) => void;
    onRegenerateRequest: () => void;
}

interface MetadataItemProps {
    icon: React.ReactNode;
    label: string;
    value: string | number;
    isEditing?: boolean;
    onEdit?: (value: number) => void;
    isScore?: boolean;
}

interface InfoBannerProps {
    exerciseType: string;
}

const MetadataItem = ({ icon, label, value, isEditing, onEdit, isScore }: MetadataItemProps) => (
    <div className="metadata-item">
        <div className="metadata-label">
            {icon}
            <span>{label}</span>
        </div>
        <div className="metadata-value">
            {isEditing && isScore ? (
                <input
                    type="number"
                    defaultValue={value}
                    onBlur={(e) => onEdit?.(Number(e.target.value))}
                    className="score-editor"
                />
            ) : (
                value
            )}
        </div>
    </div>
);

const ActionButton = ({
    onClick,
    icon,
    label,
    variant,
    disabled = false,
    isLoading = false
}: {
    onClick: () => void;
    icon: React.ReactNode;
    label: string;
    variant?: string;
    disabled?: boolean;
    isLoading?: boolean;
}) => (
    <button
        onClick={onClick}
        className={`action-button ${variant || ''}`}
        disabled={disabled}
    >
        {isLoading ? (
            <Oval
                visible={true}
                height="16"
                width="16"
                color="#ffffff"
                secondaryColor="#ffffff"
                ariaLabel="loading"
            />
        ) : icon}
        <span>{label}</span>
    </button>
);

const InfoBanner = ({ exerciseType }: InfoBannerProps) => (
    <div className="info-banner">
        <BiInfoCircle className="info-icon" />
        <p>
            This {exerciseType} exercise may be general and should be reviewed with product security team prior to passing recommendations to development teams.
        </p>
    </div>
);

export const ThreatModelDetails: React.FC<ThreatModelDetailsProps> = ({
    model,
    onClose,
    onModelUpdate,
    onRegenerateRequest
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedThreatModel, setEditedThreatModel] = useState(model.threat_model);
    const [editedScore, setEditedScore] = useState(model.security_relevance_score);
    const [selectedVersion, setSelectedVersion] = useState('current');
    const [isRegenerateModalOpen, setIsRegenerateModalOpen] = useState(false);
    const [isWaitingForRegeneration, setIsWaitingForRegeneration] = useState(false);

    useEffect(() => {
        if (isWaitingForRegeneration && !isEditing && selectedVersion === 'current') {
            const refreshInterval = setInterval(async () => {
                try {
                    const response = await axios.get('/threat-modeling');
                    const updatedModel = response.data.find((item: ThreatModel) =>
                        item.jira_issue_id === model.jira_issue_id
                    );
                    if (updatedModel && JSON.stringify(updatedModel) !== JSON.stringify(model)) {
                        onModelUpdate(updatedModel);
                        setIsWaitingForRegeneration(false);
                    }
                } catch (error) {
                    console.error('Error refreshing data:', error);
                }
            }, 10000);

            return () => clearInterval(refreshInterval);
        }
    }, [isWaitingForRegeneration, isEditing, model, selectedVersion, onModelUpdate]);

    const parseName = (text: string | null): string => {
        if (!text) return '';
        
        // Special cases
        if (text.toLowerCase() === 'arb-review') {
            return 'ARB Review';
        } else if (text.toLowerCase() === 'threat-modeling') {
            return 'Threat Modeling';
        }
        
        // Regular formatting
        const namePart = text.split('@')[0];
        const parts = namePart.split(/[._-]/).map(part =>
            part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
        );
        return parts.length >= 2 ? `${parts.slice(0, -1).join(' ')} ${parts.slice(-1)}` : parts[0] || '';
    };

    const formatDateTime = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short'
        });
    };

    const getVersions = () => {
        const versions = [{ key: 'current', date: model.last_updated, content: model.threat_model }];

        Object.keys(model).forEach(key => {
            if (key.startsWith('threat_model_v')) {
                const versionNumber = key.split('_v')[1];
                const dateKey = `threat_model_date_v${versionNumber}`;
                versions.push({
                    key: `v${versionNumber}`,
                    date: model[dateKey],
                    content: model[key]
                });
            }
        });

        return versions.sort((a, b) => {
            if (a.key === 'current') return -1;
            if (b.key === 'current') return 1;
            return Number(b.key.slice(1)) - Number(a.key.slice(1));
        });
    };

    const handleFeedbackClick = async (feedback: 'Liked' | 'Disliked' | 'Neutral') => {
        const newFeedback = model.feedback === feedback ? 'Neutral' : feedback;
        try {
            const params = new URLSearchParams({
                columnName: 'feedback',
                newValue: newFeedback
            });
            await axios.put(`/threat-modeling/${model.jira_issue_id}?${params.toString()}`);
            onModelUpdate({ ...model, feedback: newFeedback });
        } catch (error) {
            console.error("Error updating feedback:", error);
        }
    };

    const handleSave = async () => {
        try {
            let updatedModel = { ...model };
            let hasUpdates = false;

            if (editedThreatModel !== model.threat_model) {
                const threatModelParams = new URLSearchParams({
                    columnName: 'threat_model',
                    newValue: editedThreatModel
                });
                await axios.put(`/threat-modeling/${model.jira_issue_id}?${threatModelParams.toString()}`);
                updatedModel.threat_model = editedThreatModel;
                hasUpdates = true;
            }

            if (editedScore !== model.security_relevance_score) {
                await axios.put(
                    `/threat-modeling/update-relevance-score/${model.jira_issue_id}`,
                    editedScore,
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                );
                updatedModel.security_relevance_score = editedScore;
                hasUpdates = true;
            }

            if (hasUpdates) {
                onModelUpdate(updatedModel);
            }
            setIsEditing(false);
        } catch (error) {
            console.error("Error updating threat model:", error);
        }
    };

    const handleCancel = () => {
        setEditedThreatModel(model.threat_model);
        setEditedScore(model.security_relevance_score);
        setIsEditing(false);
    };

    const handleRegenerate = async (feedback: string) => {
        try {
            const formattedContent = `${model.jira_issue_id},${feedback}`;
            const exerciseType = model.exercise_type;
            const messagePayload = {
                message: JSON.stringify({
                    EXERCISE_TYPE: exerciseType,
                    INPUT_TYPE: 'regenerate',
                    CONTENT: formattedContent
                })
            };
            await axios.post('/threat-modeling/send-to-sns', messagePayload);
            onRegenerateRequest();
        } catch (error) {
            console.error('Error sending regeneration request:', error);
            throw error;
        }
    };

    const metadataItems = [
        { icon: <MdOutlineTypeSpecimen className="icon" />, label: "Exercise Type", value: parseName(model.exercise_type) },
        { icon: <SiJira className="icon" />, label: "Jira Issue ID", value: model.jira_issue_id },
        { icon: <MdBookmark className="icon" />, label: "Jira Issue Type", value: model.issue_type },
        { icon: <MdOutlineViewTimeline className="icon" />, label: "Fix Version/Release", value: model.jira_release },
        { icon: <FaUsers className="icon" />, label: "Product Team", value: model.product_team },
        { icon: <FaExclamationCircle className="icon" />, label: "Priority", value: model.priority },
        { icon: <FaUserAlt className="icon" />, label: "Assignee", value: model.assignee },
        {
            icon: <MdSecurity className="icon" />,
            label: "Score",
            value: isEditing ? editedScore : model.security_relevance_score,
            isEditing,
            onEdit: (value: number) => setEditedScore(value),
            isScore: true
        },
        { icon: <BsPersonLinesFill className="icon" />, label: "Requestor", value: parseName(model.requestor) },
        { icon: <MdDateRange className="icon" />, label: "Last Updated", value: formatDateTime(model.last_updated) }
    ];

    const versions = getVersions();

    return (
        <div className="threat-model-details">
            <header className="header">
                <div className="header-container">
                    <div className="title-section">
                        <FaTicketAlt className="title-icon" />
                        <h1>{model.jira_title}</h1>
                    </div>

                    <div className="actions-section">
                        <select
                            value={selectedVersion}
                            onChange={(e) => setSelectedVersion(e.target.value)}
                            className="version-dropdown"
                        >
                            {versions.map(version => (
                                <option key={version.key} value={version.key}>
                                    {version.key === 'current'
                                        ? `Current (${formatDateTime(version.date)})`
                                        : `Version ${version.key} (${formatDateTime(version.date)})`
                                    }
                                </option>
                            ))}
                        </select>

                        <div className="button-group">
                            <ActionButton
                                onClick={() => handleFeedbackClick('Liked')}
                                icon={<FaThumbsUp />}
                                label="Like"
                                variant={`feedback ${model.feedback === 'Liked' ? 'liked' : ''}`}
                            />
                            <ActionButton
                                onClick={() => handleFeedbackClick('Disliked')}
                                icon={<FaThumbsDown />}
                                label="Dislike"
                                variant={`feedback ${model.feedback === 'Disliked' ? 'disliked' : ''}`}
                            />

                            {isEditing ? (
                                <>
                                    <ActionButton
                                        onClick={handleCancel}
                                        icon={<MdEditOff />}
                                        label="Cancel"
                                        variant="cancel"
                                    />
                                    <ActionButton
                                        onClick={handleSave}
                                        icon={<MdSave />}
                                        label="Save"
                                        variant="primary"
                                    />
                                </>
                            ) : (
                                <>
                                    <ActionButton
                                        onClick={() => setIsEditing(true)}
                                        icon={<MdModeEdit />}
                                        label="Edit"
                                        variant="primary"
                                        disabled={selectedVersion !== 'current'}
                                    />
                                    <ActionButton
                                        onClick={() => setIsRegenerateModalOpen(true)}
                                        icon={<LuBrainCog />}
                                        label="Regenerate"
                                        variant="primary"
                                        disabled={selectedVersion !== 'current' || isWaitingForRegeneration}
                                        isLoading={isWaitingForRegeneration}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <button className="close-button" onClick={onClose} aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </header>

            <main className="content">
                <aside className="metadata">
                    <div className="metadata-container">
                        {metadataItems.map((item) => (
                            <MetadataItem
                                key={item.label}
                                icon={item.icon}
                                label={item.label}
                                value={item.value}
                                isEditing={item.isEditing}
                                onEdit={item.onEdit}
                                isScore={item.isScore}
                            />
                        ))}
                    </div>
                    <InfoBanner
                        exerciseType={parseName(model.exercise_type)}
                    />
                </aside>

                <section className="model-content">
                    {isEditing ? (
                        <textarea
                            value={editedThreatModel}
                            onChange={(e) => setEditedThreatModel(e.target.value)}
                            className="model-editor"
                            spellCheck="false"
                            aria-label="Threat model content"
                        />
                    ) : (
                        <pre className="model-display">
                            {selectedVersion === 'current'
                                ? model.threat_model
                                : model[`threat_model_${selectedVersion}`]}
                        </pre>
                    )}
                </section>
            </main>

            <RegenerateFeedbackModal
                isOpen={isRegenerateModalOpen}
                onClose={() => setIsRegenerateModalOpen(false)}
                onRegenerate={handleRegenerate}
                onRegenerationStart={() => setIsWaitingForRegeneration(true)}
            />
        </div>
    );
};

export default ThreatModelDetails;