import { Button, TextField } from "@mui/material";
import axios from "axios";
import { useState } from "react";

export const AdminPage: React.FC = () => {
    const [exceptionsUser, setExceptionsUser] = useState<string>("");
    const [policiesUser, setPoliciesUser] = useState<string>("");

    const addExceptionEditor = async () => {
        await axios.post(`/access-check/exception/${exceptionsUser}`, null);
        setExceptionsUser("");
    }

    const addPolicyEditor = async () => {
        await axios.post(`/access-check/policy/${policiesUser}`, null);
        setPoliciesUser("");
    }

    return <>
        <div>
            <div>
                <TextField label="Exceptions user" variant="outlined"
                                                onChange={(e) => setExceptionsUser(e.target.value)}
                                                value={exceptionsUser}
                                                sx={{
                                                    color: "white",
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                                    },
                                                    '.MuiSvgIcon-root ': {
                                                    fill: "white !important",
                                                    },
                                                    label: {color: "gray"},
                                                }}
                                            />
                                            <Button 
                                             onClick={addExceptionEditor}
                                             >Add</Button>
            </div>
            <div>
                <TextField label="Policies user" variant="outlined"
                                                onChange={(e) => setPoliciesUser(e.target.value)}
                                                value={policiesUser}
                                                sx={{
                                                    color: "white",
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                                    },
                                                    '.MuiSvgIcon-root ': {
                                                    fill: "white !important",
                                                    },
                                                    label: {color: "gray"},
                                                }}
                                            />
                                            <Button 
                                             onClick={addPolicyEditor}
                                             >Add</Button>
            </div>
        </div>
    </>;
};

export default AdminPage;