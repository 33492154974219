import React from "react";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { BsGraphUp, BsJournalText } from "react-icons/bs";
import { RiShieldFlashFill } from "react-icons/ri";
import { BiSolidLock } from "react-icons/bi";
import { FaShieldAlt } from "react-icons/fa";
import { MdSpaceDashboard, MdSettings } from "react-icons/md";
import "./SideNav.scss";

const logo = { icon: RiShieldFlashFill, text: "CyberHub" };

const mainNavItems = [
    { to: "/", icon: MdSpaceDashboard, text: "Dashboard" },
    { to: "/assets", icon: BsJournalText, text: "Asset Register" },
    { to: "/secrets-scanner", icon: BiSolidLock, text: "Secrets Scanner" },
    { to: "/threat-modeling", icon: FaShieldAlt, text: "Threat Modeling" },
    { to: "/reporting", icon: BsGraphUp, text: "Reporting" },
];

const settingsItem = { to: "/settings", icon: MdSettings, text: "Settings" };

const container = {
    hidden: { opacity: 0, x: -10 },
    show: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 0.3,
            staggerChildren: 0.05,
            delayChildren: 0.1,
            ease: "easeOut"
        },
    },
};

const item = {
    hidden: { opacity: 0, x: -5 },
    show: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 0.2,
            ease: "easeOut"
        }
    },
};

export const SideNav: React.FC = () => {
    return (
        <div className="sidenav-container">
            <motion.nav
                initial="hidden"
                animate="show"
                variants={container}
                className="nav-content"
            >
                <motion.ul className="nav-list">
                    <motion.li
                        variants={item}
                        className="nav-item logo"
                    >
                        <NavLink to="/" className="nav-link logo-link">
                            <logo.icon className="logo-icon" />
                            <span className="nav-text">
                                {logo.text}
                            </span>
                        </NavLink>
                    </motion.li>
                    {mainNavItems.map((navItem) => (
                        <motion.li
                            key={navItem.to}
                            variants={item}
                            className="nav-item tab"
                        >
                            <NavLink
                                to={navItem.to}
                                className={({ isActive }) =>
                                    `nav-link ${isActive ? 'active' : ''}`
                                }
                            >
                                {({ isActive }) => (
                                    <>
                                        <navItem.icon className="nav-icon" />
                                        <span className="nav-text">
                                            {navItem.text}
                                        </span>
                                        <div className="active-background" />
                                    </>
                                )}
                            </NavLink>
                        </motion.li>
                    ))}
                </motion.ul>
                <motion.div variants={item} className="settings-container">
                    <NavLink
                        to={settingsItem.to}
                        className={({ isActive }) =>
                            `nav-link ${isActive ? 'active' : ''}`
                        }
                    >
                        {({ isActive }) => (
                            <>
                                <settingsItem.icon className="nav-icon" />
                                <span className="nav-text">
                                    {settingsItem.text}
                                </span>
                                <div className="active-background" />
                            </>
                        )}
                    </NavLink>
                </motion.div>
            </motion.nav>
        </div>
    );
};