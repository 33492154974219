import React from 'react';
import { motion } from 'framer-motion';

interface AnimatedCheckmarkProps {
    size?: number;
    strokeWidth?: number;
}

const checkmarkVariants = {
    hidden: {
        pathLength: 0,
        opacity: 0,
    },
    visible: {
        pathLength: 1,
        opacity: 1,
        transition: {
            pathLength: { 
                type: "tween", 
                duration: 0.4,
                ease: "easeOut"
            },
            opacity: { 
                duration: 0.2
            }
        }
    }
};

const AnimatedCheckmark: React.FC<AnimatedCheckmarkProps> = ({ 
    size = 20,
    strokeWidth = 4
}) => (
    <motion.svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <motion.path
            d="M20 6L9 17L4 12"
            variants={checkmarkVariants}
            initial="hidden"
            animate="visible"
        />
    </motion.svg>
);

export default AnimatedCheckmark;