import React from 'react';
import './FilterTags.scss';

interface FilterTag {
    label: string;
}

interface FilterTagsProps {
    selectedFilters: {
        scale?: string;
        dateRange?: {
            startDate: Date;
            endDate: Date;
        };
        scanTypes?: string[];
    };
}

const FilterTags: React.FC<FilterTagsProps> = ({
    selectedFilters
}) => {
    const getActiveTags = (): FilterTag[] => {
        const tags: FilterTag[] = [];

        if (selectedFilters.scale) {
            tags.push({
                label: `${selectedFilters.scale === 'day' ? 'Daily' :
                    selectedFilters.scale === 'week' ? 'Weekly' : 'Monthly'}`
            });
        }

        if (selectedFilters.dateRange?.startDate && selectedFilters.dateRange?.endDate) {
            const formatDisplayDate = (date: Date) => {
                const d = new Date(date);
                return `${String(d.getMonth() + 1).padStart(2, '0')}/${String(d.getDate()).padStart(2, '0')}/${d.getFullYear()}`;
            };

            const start = formatDisplayDate(selectedFilters.dateRange.startDate);
            const end = formatDisplayDate(selectedFilters.dateRange.endDate);
            tags.push({ label: `${start} - ${end}` });
        }

        if (selectedFilters.scanTypes && selectedFilters.scanTypes.length > 0 && !selectedFilters.scanTypes.includes('all')) {
            tags.push({ label: `${selectedFilters.scanTypes.join(', ')}` });
        }

        return tags;
    };

    const activeTags = getActiveTags();

    if (activeTags.length === 0) return null;

    return (
        <div className="filter-tags">
            {activeTags.map((tag, index) => (
                <div key={index} className="filter-tag">
                    {tag.label}
                </div>
            ))}
        </div>
    );
};

export default FilterTags;