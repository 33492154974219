import React, { useState, useEffect } from "react";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import { FaPlus, FaTrash } from "react-icons/fa";
import { MdCheck, MdError, MdClose, MdSave } from "react-icons/md";
import { Exception, Condition } from "./Exception";

import "./ExceptionForm.scss";

interface ExceptionFormProps {
  assetId: string | undefined;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (exception: Exception) => void;
}

const CONDITION_TYPES: Condition["type"][] = [
  "file_path",
  "file_name",
  "module",
  "cwe",
  "cve",
];

const ExceptionForm: React.FC<ExceptionFormProps> = ({
  assetId,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [exceptionName, setExceptionName] = useState("");
  const [action, setAction] = useState<"ignore">("ignore");
  const [conditions, setConditions] = useState<Condition[]>([
    { type: "file_path", operator: "equals", value: "", action: "ignore" },
  ]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    if (!isOpen) {
      setExceptionName("");
      setAction("ignore");
      setConditions([
        { type: "file_path", operator: "equals", value: "", action: "ignore" },
      ]);
      setError(null);
      setSuccessMessage(null);
    }
  }, [isOpen]);

  // Get already selected condition types (excluding the current index)
  const getUsedConditionTypes = (currentIndex: number): Condition["type"][] => {
    return conditions
      .filter((_, i) => i !== currentIndex)
      .map((condition) => condition.type);
  };

  const handleAddCondition = () => {
    const usedTypes = conditions.map((c) => c.type);
    const availableType = CONDITION_TYPES.find(
      (type) => !usedTypes.includes(type)
    );

    if (!availableType) {
      return;
    }

    setConditions([
      ...conditions,
      {
        type: availableType,
        operator: "equals",
        value: "",
        action: "ignore",
      },
    ]);
  };

  const handleRemoveCondition = (index: number) => {
    setConditions(conditions.filter((_, i) => i !== index));
  };

  const handleConditionChange = (
    index: number,
    field: keyof Condition,
    value: string
  ) => {
    if (field === "type") {
      if (!CONDITION_TYPES.includes(value as Condition["type"])) {
        return;
      }

      const typeValue = value as Condition["type"];
      const usedTypes = getUsedConditionTypes(index);

      if (usedTypes.includes(typeValue)) {
        // Don't allow selecting already used types
        return;
      }

      const newConditions = [...conditions];
      newConditions[index] = {
        ...newConditions[index],
        type: typeValue,
        value: "",
      };
      setConditions(newConditions);
    } else if (field === "value") {
      const newConditions = [...conditions];
      newConditions[index] = {
        ...newConditions[index],
        value: value,
      };
      setConditions(newConditions);
    } else {
      // For other field changes (like operator)
      const newConditions = [...conditions];
      newConditions[index] = {
        ...newConditions[index],
        [field]: value,
      };
      setConditions(newConditions);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    setSuccessMessage(null);

    if (!exceptionName.trim()) {
      setError("Exception name is required");
      setIsSubmitting(false);
      return;
    }

    if (
      conditions.some((c) => {
        return !c.value?.trim();
      })
    ) {
      setError("Condition value is required");
      setIsSubmitting(false);
      return;
    }

    const assetExceptionsResponse = await axios.get(
      `/exception-management/asset/${assetId}`
    );
    let sameNameException = assetExceptionsResponse.data.find(
      (e: Exception) =>
        e.exceptionName.toUpperCase() === exceptionName.trim().toUpperCase()
    );
    if (sameNameException) {
      setError("An exception with the same name already exists");
      setIsSubmitting(false);
      return;
    }

    try {
      const exception: Partial<Exception> = {
        exceptionName,
        conditions: conditions.map((c) => ({ ...c, action })),
      };

      onSubmit(exception as Exception);
      setExceptionName("");
      setAction("ignore");
      setConditions([
        { type: "file_path", operator: "equals", value: "", action: "ignore" },
      ]);
      setSuccessMessage("Exception created successfully");

      setTimeout(() => {
        setSuccessMessage(null);
        onClose();
      }, 5000);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError(`Error: ${error.message}`);
      } else {
        setError("An unexpected error occurred");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  // Simple function to get display name for type
  const getTypeDisplayName = (type: Condition["type"]): string => {
    switch (type) {
      case "file_path":
        return "File Path";
      case "file_name":
        return "File Name";
      case "module":
        return "Module";
      case "cwe":
        return "CWE";
      case "cve":
        return "CVE";
      default:
        return type;
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content add-threat-model-modal exceptions">
        <h3>
          <FaPlus className="icon" />
          New Exception
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="exceptionName">Exception Name:</label>
            <input
              type="text"
              id="exceptionName"
              value={exceptionName}
              onChange={(e) => setExceptionName(e.target.value)}
              placeholder="Enter exception name"
              disabled={isSubmitting}
            />
          </div>

          <div className="form-group">
            <label htmlFor="exceptionAction">Action:</label>
            <select
              id="exceptionAction"
              value={action}
              onChange={(e) => setAction(e.target.value as "ignore")}
              disabled={isSubmitting}
            >
              <option value="ignore">Ignore</option>
            </select>
          </div>

          {conditions.map((condition, index) => {
            const usedTypes = getUsedConditionTypes(index);

            return (
              <div key={index} className="condition-group">
                {index > 0 && (
                  <div className="form-group">
                    <select disabled={isSubmitting}>
                      <option value="AND">AND</option>
                    </select>
                  </div>
                )}
                <div className="form-group conditions">
                  {index > 0 && (
                    <button
                      type="button"
                      onClick={() => handleRemoveCondition(index)}
                      className="delete-condition"
                      disabled={isSubmitting}
                    >
                      <FaTrash />
                    </button>
                  )}
                  <label>Condition {index + 1}:</label>
                  <div className="condition-row">
                    <div className="dropdown-group">
                      <select
                        value={condition.type}
                        onChange={(e) =>
                          handleConditionChange(index, "type", e.target.value)
                        }
                        disabled={isSubmitting}
                      >
                        <option value="not_specified">Not Specified</option>

                        {/* Show only types that aren't used in other conditions */}
                        {CONDITION_TYPES.map((type) => {
                          // Show if it's not used elsewhere, or if it's the current type
                          if (
                            !usedTypes.includes(type) ||
                            type === condition.type
                          ) {
                            return (
                              <option key={type} value={type}>
                                {getTypeDisplayName(type)}
                              </option>
                            );
                          }
                          return null;
                        })}
                      </select>
                    </div>
                    <div className="dropdown-group">
                      <select
                        value={condition.operator}
                        onChange={(e) =>
                          handleConditionChange(
                            index,
                            "operator",
                            e.target.value
                          )
                        }
                        disabled={isSubmitting}
                      >
                        <option value="equals">Equals</option>
                      </select>
                    </div>
                    <input
                      type="text"
                      value={condition.value}
                      onChange={(e) =>
                        handleConditionChange(index, "value", e.target.value)
                      }
                      placeholder="Enter value"
                      disabled={isSubmitting}
                    />
                  </div>
                </div>
              </div>
            );
          })}

          <button
            type="button"
            onClick={handleAddCondition}
            className="add-condition-btn"
            disabled={
              isSubmitting || conditions.length >= CONDITION_TYPES.length
            }
          >
            Add Condition
          </button>

          {error && (
            <div className="error-message">
              <MdError /> {error}
            </div>
          )}
          {successMessage && (
            <div className="success-message">
              <MdCheck /> {successMessage}
            </div>
          )}

          <div className="button-group">
            <button
              type="button"
              onClick={onClose}
              className="cancel-btn"
              disabled={isSubmitting}
            >
              <MdClose />
              Cancel
            </button>
            <button
              type="submit"
              className="submit-btn"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <Oval
                    visible={true}
                    height="16"
                    width="16"
                    color="#ffffff"
                    secondaryColor="#ffffff"
                    ariaLabel="loading"
                  />
                  Submitting...
                </>
              ) : (
                <>
                  <MdSave />
                  Create Exception
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ExceptionForm;
