import React from 'react';
import { BsGraphUp } from "react-icons/bs";
import { 
    BarChart, 
    Bar,
    LineChart,
    Line,
    XAxis, 
    YAxis, 
    CartesianGrid, 
    Tooltip, 
    Legend, 
    ResponsiveContainer
} from 'recharts';
import { motion } from "framer-motion";
import { LoadingCardContent } from '../shared/LoadingCardContent';

interface CombinedDataPoint {
    timeStamp: string;
    [key: string]: string | number;
}

interface ProcessedDataPoint {
    timeStamp: string;
    open: number;
    created: number;
    inProgress: number;
    blocked: number;
    total: number;
}

interface CustomTooltipProps {
    active?: boolean;
    payload?: Array<{
        name: string;
        value: number;
        color: string;
    }>;
    label?: string;
}

const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
};

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="tooltip-label">{`Date: ${label}`}</p>
                {payload.map((entry, index) => (
                    <p key={index} style={{ color: entry.color }}>
                        {`${entry.name}: ${entry.value}`}
                    </p>
                ))}
            </div>
        );
    }
    return null;
};

const CustomBarLabel = (props: any): React.ReactElement<SVGElement> => {
    const { x, y, width, value } = props;

    if (typeof x === 'undefined' || typeof y === 'undefined' ||
        typeof width === 'undefined' || typeof value === 'undefined') {
        return <g />;
    }

    const formattedValue = value >= 1000 ? `${(value / 1000).toFixed(1)}k` : value;
    return (
        <text
            x={x + width / 2}
            y={y - 10}
            className="bar-label"
            textAnchor="middle"
            fontSize="12"
            fontWeight="600"
        >
            {formattedValue}
        </text>
    );
};

interface VulnerabilitiesChartCardProps {
    data: CombinedDataPoint[];
    isLoading: boolean;
}

export const VulnerabilitiesChartCard: React.FC<VulnerabilitiesChartCardProps> = ({ data, isLoading }) => {
    // Process data to combine all scan types
    const processedData = React.useMemo<ProcessedDataPoint[]>(() => {
        if (!data || !data.length) return [];
        
        return data.map(dataPoint => {
            // Initialize aggregated data
            const aggregated: Omit<ProcessedDataPoint, 'total'> = {
                timeStamp: dataPoint.timeStamp,
                open: 0,
                created: 0,
                inProgress: 0,
                blocked: 0
            };
            
            // Process all keys in the data point
            Object.keys(dataPoint).forEach(key => {
                if (key === 'timeStamp') return;
                
                const keyParts = key.split('-');
                if (keyParts.length !== 2) return;
                
                const status = keyParts[1];
                
                // Aggregate values based on status
                if (status === 'open') {
                    aggregated.open += Number(dataPoint[key] || 0);
                } else if (status === 'created') {
                    aggregated.created += Number(dataPoint[key] || 0);
                } else if (status === 'inProgress') {
                    aggregated.inProgress += Number(dataPoint[key] || 0);
                } else if (status === 'blocked') {
                    aggregated.blocked += Number(dataPoint[key] || 0);
                }
            });
            
            // Add a total field for status items
            return {
                ...aggregated,
                total: aggregated.created + aggregated.inProgress + aggregated.blocked
            };
        });
    }, [data]);

    const openYAxisRange = React.useMemo(() => {
        if (!processedData.length) return [0, 100];
        
        const openValues = processedData.map(item => item.open);
        const min = Math.min(...openValues);
        const max = Math.max(...openValues);
        
        return [
            Math.floor(Math.max(0, min - (max - min) * 0.1)),
            Math.ceil(max + (max - min) * 0.1)
        ];
    }, [processedData]);

    const CardWrapper = ({ children }: { children: React.ReactNode }) => (
        <motion.div 
            className="stat-card"
            style={{ gridColumn: '1 / -1', minHeight: '600px' }}
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.3 }}
        >
            <div className="card-header">
                <div className="title">
                    <BsGraphUp size={18} />
                    <span>SECBUGs Weekly Overview</span>
                </div>
            </div>
            <div className="card-content" style={{ display: 'block' }}>
                {children}
            </div>
        </motion.div>
    );

    if (isLoading) {
        return (
            <CardWrapper>
                <LoadingCardContent />
            </CardWrapper>
        );
    }

    if (!data?.length) {
        return (
            <CardWrapper>
                <div>
                    <span>No data available</span>
                </div>
            </CardWrapper>
        );
    }

    return (
        <CardWrapper>
            <div>
                {/* Top Line Chart for Open Issues */}
                <div>
                    <ResponsiveContainer height={300}>
                        <LineChart
                            data={processedData}
                            margin={{ top: 20, right: 30, left: 20, bottom: 40 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" stroke="#555" />
                            <XAxis 
                                dataKey="timeStamp"
                                interval={0}
                                angle={-45}
                                textAnchor="end"
                                height={60}
                            />
                            <YAxis 
                                domain={openYAxisRange}
                                allowDataOverflow={true}
                                tickFormatter={(value) => Math.round(value).toString()}
                            >
                            </YAxis>
                            <Tooltip content={<CustomTooltip />} />
                            <Legend verticalAlign="top" height={36} />
                            <Line
                                type="monotone"
                                dataKey="open"
                                name="Open Issues"
                                stroke="#3B82F6"
                                strokeWidth={2}
                                activeDot={{ r: 8 }}
                                dot={{ r: 4 }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </div>

                {/* Bottom Bar Chart for Created, InProgress, Blocked */}
                <div style={{ width: '100%', height: '300px' }}>
                    <ResponsiveContainer height={300}>
                        <BarChart
                            data={processedData}
                            margin={{ top: 20, right: 30, left: 20, bottom: 40 }}
                            barSize={20}
                        >
                            <CartesianGrid strokeDasharray="3 3" stroke="#555" />
                            <XAxis 
                                dataKey="timeStamp"
                                interval={0}
                                angle={-45}
                                textAnchor="end"
                                height={60}
                            />
                            <YAxis>
                            </YAxis>
                            <Tooltip content={<CustomTooltip />} />
                            <Legend verticalAlign="top" height={36} />
                            <Bar
                                dataKey="created"
                                name="Created"
                                fill="#3B82F6"
                                label={CustomBarLabel}
                            />
                            <Bar
                                dataKey="inProgress"
                                name="In Progress"
                                fill="#FFCF44"
                                label={CustomBarLabel}
                            />
                            <Bar
                                dataKey="blocked"
                                name="Blocked"
                                fill="#FD4433"
                                label={CustomBarLabel}
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </CardWrapper>
    );
};