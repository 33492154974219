import axios from "axios";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import {VscError} from "react-icons/vsc";

import React from "react";

import { Box, MenuItem, Paper, Select, SelectChangeEvent, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Button } from "react-bootstrap";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { TabContext, TabPanel } from "@mui/lab";
import { useParams } from "react-router-dom";
import { PolicyAuditFilter } from "./PolicyAuditFilter";
import { PolicyAuditDto } from "./PolicyAuditDto";
import { PolicyAuditType } from "./PolicyAuditType";
import AnimatedShieldLoader from "../../shared/AnimatedShieldLoader";
import SectionCard from "../../AssetRecords/SectionCard";
import { AssetRecord } from "../../AssetRecords/AssetRecord";

const fetchData = async (startDate: Date, endDate: Date, userEmail:string, owner: string | undefined, policyName: string|undefined, canonicalId: string|null): Promise<PolicyAuditDto[]> => {
    let filter: PolicyAuditFilter = {
        startDate: startDate,
        endDate: endDate,
        userEmail: userEmail,
        owner: owner,
        assetId: undefined,
        policyName: policyName,
        assetCanonicalId: canonicalId
    }

    let response = await axios.post("/audit/policy", filter);

    return response.data
}

interface info{
    emails: string[];
    assets: AssetRecord[];
}
const fetchUsers = async (): Promise<info> => {
    let response = await axios.get("/UserInfo")

    var emails = response.data.map((user: any) => user.email)
    let assetResponse = await axios.get("/assets/all")
    var assets = assetResponse.data

    return {emails, assets};
}

export const PolicyAuditLogs: React.FC = () => {
    const {
        data,
        isLoading,
        isSuccess
    } = useQuery(["get-users"], fetchUsers);

    const params = useParams<{ id: string }>();
    const currentDate = new Date();
    const initialStartDate = new Date(currentDate.setDate(currentDate.getDate() - 1));

    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(new Date());
    const [userEmail, setUserEmail] = useState<string>("_");
    const [ownerEmail, setOwnerEmail] = useState<string | undefined>("_");
    const [policyName, setPolicyName] = useState<string>("");
    const [canonicalId, setCanonicalId] = useState<string | null>(null);

    const [logs, setLogs] = useState<PolicyAuditDto[]>([]);
    const [selectedTab, setSelectedTab] = useState(PolicyAuditType.Crud);
    const [isDataLoad, setIsDataLoad] = useState(false);

    useEffect(() => {
        if(params.id) {
            getData();
        }
    // eslint-disable-next-line
    }, []);

    const getData = () => {
        setIsDataLoad(true);
        let email = userEmail === "_" ? "" : userEmail;
        let ownEmail = ownerEmail === "_" ? "" : ownerEmail;
        fetchData(startDate, endDate, email, ownEmail, policyName, canonicalId).then(
            (data) => {
                setLogs(data);
                setIsDataLoad(false);
            });
    }

    const handleUserChange = (event: SelectChangeEvent) => {
        setUserEmail(event.target.value);
    }

    const handleOwnerChange = (event: SelectChangeEvent) => {
        setOwnerEmail(event.target.value);
    }
    

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });
    };

    const prepareAssets = (assets: string[]) => {
        if (!assets || assets.length === 0) return <span></span>;
        let foundAssets = data?.assets.filter((asset: AssetRecord) => assets.includes(asset.id));

        if (foundAssets && foundAssets.length > 0) {
            return foundAssets.map((asset: AssetRecord) => {
                return <span key={asset.id}>{asset.canonicalId}</span>
            })
        }
    }
    const parseEmailToName = (email: string | null): string => {
        if (!email) return '';
        const namePart = email.split('@')[0];
        const parts = namePart.split(/[._]/).map(part =>
            part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
        );
        return parts.length >= 2 ? `${parts.slice(0, -1).join(' ')} ${parts.slice(-1)}` : parts[0] || '';
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    if (isLoading) {
        return <div id="loader">
            <AnimatedShieldLoader />
        </div>
    }

    if (isSuccess) {
        return (
            <>
            <div className="audit-logs">
                <div className="filter">
                    <SectionCard title="Policy Audit Logs">
                        <div className="info-grid" style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "10px" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    sx={{
                                        color: "white",
                                        '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(228, 219, 233, 0.25)',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(228, 219, 233, 0.25)',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(228, 219, 233, 0.25)',
                                        },
                                        '.MuiSvgIcon-root ': {
                                        fill: "inherit !important",
                                        },
                                        label: {color: "gray"},
                                    }}
                                    className="date-picker"
                                    label="Start Date"
                                    value={dayjs(startDate.toISOString().split('T')[0])}
                                    onChange={(value) => setStartDate(dayjs(value).toDate())}/>
                                <DatePicker
                                    sx={{
                                        color: "white",
                                        '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(228, 219, 233, 0.25)',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(228, 219, 233, 0.25)',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(228, 219, 233, 0.25)',
                                        },
                                        '.MuiSvgIcon-root ': {
                                        fill: "inherit !important",
                                        },
                                        label: {color: "gray"},
                                    }}
                                    className="date-picker"
                                    label="End Date"
                                    value={dayjs(endDate.toISOString().split('T')[0])}
                                    onChange={(value) => setEndDate(dayjs(value).toDate())}/>
                            </LocalizationProvider>
                            <Select
                                size="small"
                                label="Actor"

                                sx={{
                                    color: "white",
                                    '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '.MuiSvgIcon-root ': {
                                    fill: "white !important",
                                    }
                                }}
                                    id="demo-simple-select1"
                                    value={userEmail}
                                    //label="Actor"
                                    onChange={handleUserChange}
                                >
                                    <MenuItem value={"_"}>All Actors</MenuItem>
                                    {data?.emails.map((email: string) => <MenuItem key={email} value={email}>{parseEmailToName(email)}</MenuItem>)}
                            </Select>
                            <Select
                                size="small"
                                sx={{
                                    color: "white",
                                    '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '.MuiSvgIcon-root ': {
                                    fill: "white !important",
                                    }
                                }}
                                    id="demo-simple-select"
                                    value={ownerEmail}
                                    label="Owner"
                                    onChange={handleOwnerChange}
                                >
                                    <MenuItem value={"_"}>All Owners</MenuItem>
                                    {data?.emails.map((email: string) => <MenuItem key={email} value={email}>{parseEmailToName(email)}</MenuItem>)}
                            </Select>
                            <TextField label="Asset CanonicalId" variant="outlined"
                                onChange={(e) => setCanonicalId(e.target.value)}
                                // error={entityValidationMessage !== undefined}
                                // helperText={entityValidationMessage}
                                sx={{
                                    color: "white",
                                    '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '.MuiSvgIcon-root ': {
                                    fill: "white !important",
                                    },
                                    label: {color: "gray"},
                                }}
                                value={canonicalId ?? ""}
                            />
                            <TextField label="Policy name" variant="outlined"
                                onChange={(e) => setPolicyName(e.target.value)}
                                sx={{
                                    color: "white",
                                    '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '.MuiSvgIcon-root ': {
                                    fill: "white !important",
                                    },
                                    label: {color: "gray"},
                                }}
                            />
                            <Button style={{gridColumn: "3"}} onClick={getData}>Load data</Button>
                        </div>
                    </SectionCard>
                </div>

                <Box sx={{ width: '100%' }}>
                    <TabContext value={selectedTab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs textColor="inherit" value={selectedTab} onChange={handleTabChange} aria-label="action-tabs">
                                <Tab label="Audit" value={PolicyAuditType.Crud}  />
                                <Tab label="Usage" value={PolicyAuditType.Usage}  />
                            </Tabs>
                        </Box>
                        {isDataLoad === true && 
                        <div id="table-loader">
                            <AnimatedShieldLoader />
                        </div>}
                        {isDataLoad === false && <>
                        <TabPanel value={PolicyAuditType.Crud}>
                            <TableContainer component={Paper} className="application-table-paper">
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Actor</TableCell>
                                            <TableCell>Owner</TableCell>
                                            <TableCell>Policy Name</TableCell>
                                            <TableCell>Message</TableCell>
                                            <TableCell>Policy Assets</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {logs.filter(x => PolicyAuditType[PolicyAuditType[x.logType]] === PolicyAuditType[PolicyAuditType.Crud]).map((x, index) => 
                                        <TableRow key={index}>
                                            <TableCell>{formatDate(x.logDate.toString())}</TableCell>
                                            <TableCell>{parseEmailToName(x.user)}</TableCell>
                                            <TableCell>{parseEmailToName(x.owner)}</TableCell>
                                            <TableCell>{x.policyName}</TableCell>
                                            <TableCell>{x.eventMessage}</TableCell>
                                            <TableCell>{prepareAssets(x.assets)}</TableCell>
                                        </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                        <TabPanel value={PolicyAuditType.Usage}>
                            <TableContainer component={Paper} className="application-table-paper">
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Owner</TableCell>
                                            <TableCell>Policy name</TableCell>
                                            <TableCell>Message</TableCell>
                                            <TableCell>Result</TableCell>
                                            <TableCell>Asset</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {logs.filter(x => PolicyAuditType[PolicyAuditType[x.logType]] === PolicyAuditType[PolicyAuditType.Usage]).map((x, index) => 
                                        <TableRow key={index}>
                                            <TableCell>{formatDate(x.logDate.toString())}</TableCell>
                                            <TableCell>{parseEmailToName(x.owner)}</TableCell>
                                            <TableCell>{x.policyName}</TableCell>
                                            <TableCell>{x.eventMessage}</TableCell>
                                            <TableCell>{x.evaluationResult}</TableCell>
                                            <TableCell>{data?.assets.find(a => a.id === x.actionOnAsset)?.canonicalId}</TableCell>
                                        </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                        </>}
                    </TabContext>
                </Box>
            </div>
            </>
        )
    }

    return <><VscError /> Failed to load, please, check console</>
}
