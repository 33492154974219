import React, { useState, useMemo, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    TableSortLabel,
    IconButton,
    TextField,
    InputAdornment,
    Menu
} from '@mui/material';
import { useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import AnimatedShieldLoader from '../shared/AnimatedShieldLoader';
import {
    FaEye,
    FaPlus,
    FaThumbsUp,
    FaThumbsDown,
    FaMeh,
    FaChartBar,
    FaTrash,
    FaSearch,
    FaFilter
} from 'react-icons/fa';
import { RiCloseCircleFill } from "react-icons/ri";
import { useLocation } from 'react-router-dom';
import AddThreatModelModal from './AddThreatModelModal';
import ThreatModelDetails from './ThreatModelDetails';
import { CiViewColumn } from "react-icons/ci";
import { MdOutlineFilterList, MdOutlineFilterListOff, MdOutlineClose } from 'react-icons/md';

import './ThreatModelingList.scss';

interface ThreatModel {
    exercise_type: string;
    jira_issue_id: string;
    issue_type: string;
    assignee: string;
    jira_release: string;
    jira_title: string;
    last_updated: string;
    priority: string;
    product_team: string;
    security_relevance_score: number;
    threat_model: string;
    requestor: string;
    feedback: 'Liked' | 'Disliked' | 'Neutral';
    [key: string]: any; // Threat Model versioning
}

type Order = 'asc' | 'desc';

type ColumnConfig = {
    field: keyof ThreatModel | 'actions';
    label: string;
    canHide: boolean;
};

type FilterState = {
    [K in keyof ThreatModel | 'actions']?: Set<string>;
};

const COLUMNS: ColumnConfig[] = [
    { field: 'exercise_type', label: 'Type', canHide: true },
    { field: 'jira_release', label: 'Fix Version/Release', canHide: true },
    { field: 'jira_issue_id', label: 'Jira Issue ID', canHide: false },
    { field: 'jira_title', label: 'Jira Title', canHide: true },
    { field: 'product_team', label: 'Product Team', canHide: true },
    { field: 'priority', label: 'Priority', canHide: true },
    { field: 'requestor', label: 'Requestor', canHide: true },
    { field: 'last_updated', label: 'Last Updated', canHide: true },
    { field: 'security_relevance_score', label: 'Score', canHide: true },
    { field: 'feedback', label: 'Feedback', canHide: true },
    { field: 'actions', label: 'Actions', canHide: false }
];

const getFeedbackIcon = (feedback: string) => {
    switch (feedback) {
        case "Liked":
            return <FaThumbsUp size={15} />;
        case "Disliked":
            return <FaThumbsDown size={15} />;
        case "Neutral":
            return <FaMeh size={15} />;
        default:
            return <FaMeh size={15} />;
    }
};

const getIssueTypeLetter = (issueType: string | null) => {
    if (!issueType) return 'U';
    return issueType.charAt(0).toUpperCase();
};

const getIssueTypeBadgeClass = (issueType: string | null) => {
    if (!issueType) return 'Other';
    const type = issueType.toLowerCase();
    if (['task', 'bug', 'epic', 'story'].includes(type)) {
        return issueType;
    }
    return 'Other';
};

const getExerciseTypeDisplay = (exerciseType: string) => {
    if (!exerciseType) return '';
    return exerciseType === 'threat-modeling' ? 'TM' : 'ARB';
};

export const ThreatModelScanner: React.FC = () => {
    const [sortConfig, setSortConfig] = useState<{ field: keyof ThreatModel; direction: Order }>({
        field: 'jira_release',
        direction: 'asc'
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [selectedModel, setSelectedModel] = useState<ThreatModel | null>(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const location = useLocation();
    const queryClient = useQueryClient();
    const [columnMenuAnchor, setColumnMenuAnchor] = useState<null | HTMLElement>(null);
    const [visibleColumns, setVisibleColumns] = useState<Set<string>>(() => {
        const saved = localStorage.getItem('threatModelVisibleColumns');
        return saved ? new Set(JSON.parse(saved)) : new Set(
            COLUMNS
                .filter(col => !col.canHide || !['priority', 'requestor'].includes(col.field as string))
                .map(col => col.field as string)
        );
    });
    const [filterMenuAnchor, setFilterMenuAnchor] = useState<null | HTMLElement>(null);
    const [filterAnchorEl, setFilterAnchorEl] = useState<{ [key: string]: HTMLElement | null }>({});
    const [columnFilters, setColumnFilters] = useState<FilterState>(() => {
        const saved = localStorage.getItem('threatModelColumnFilters');
        return saved ? JSON.parse(saved, (key, value) => {
            return value instanceof Object && value.hasOwnProperty('values')
                ? new Set(value.values)
                : value;
        }) : {};
    });
    const [activeFilters, setActiveFilters] = useState<Set<string>>(() => {
        const saved = localStorage.getItem('threatModelActiveFilters');
        return saved ? new Set(JSON.parse(saved)) : new Set();
    });

    const { data: threatModels, isLoading, isError, error } = useQuery('threatModels', async () => {
        const response = await axios.get('/threat-modeling');
        return response.data;
    });

    const parseEmailToName = (email: string | null): string => {
        if (!email) return '';
        const namePart = email.split('@')[0];
        const parts = namePart.split(/[._-]/).map(part =>
            part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
        );
        return parts.length >= 2 ? `${parts.slice(0, -1).join(' ')} ${parts.slice(-1)}` : parts[0] || '';
    };

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });
    };

    useEffect(() => {
        setPage(0);
    }, [searchQuery]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const issueIds = searchParams.get('issues');
        if (issueIds) {
            setSearchQuery(issueIds);
        }
    }, [location]);

    const handleSort = (field: keyof ThreatModel) => {
        setSortConfig(prev => ({
            field,
            direction: prev.field === field && prev.direction === 'asc' ? 'desc' : 'asc'
        }));
    };

    const getFilteredValues = (field: keyof ThreatModel) => {
        const values = new Set<string>();
        values.add('Empty');

        if (!threatModels) return Array.from(values);

        const valuesArray: string[] = [];

        threatModels.forEach(item => {
            const value = item[field];

            if (field === 'last_updated' && value) {
                const date = new Date(String(value));
                if (!isNaN(date.getTime())) {
                    valuesArray.push(date.toLocaleDateString());
                }
            } else if (Array.isArray(value)) {
                value.filter(Boolean).forEach(val => {
                    if (val) valuesArray.push(String(val));
                });
            } else if (value) {
                valuesArray.push(String(value));
            }
        });

        valuesArray.sort((a, b) => {
            if (field === 'last_updated') {
                return new Date(a).getTime() - new Date(b).getTime();
            }
            return a.toLowerCase().localeCompare(b.toLowerCase());
        });

        valuesArray.forEach(value => values.add(value));
        return Array.from(values);
    };

    const handleFilterClick = (event: React.MouseEvent<HTMLElement>, field: string) => {
        event.stopPropagation();
        setFilterAnchorEl(prev => ({
            ...prev,
            [field]: event.currentTarget
        }));
    };

    const clearColumnFilter = (event: React.MouseEvent<HTMLElement>, field: string) => {
        event.stopPropagation();
        setColumnFilters(prev => {
            const newFilters = { ...prev };
            delete newFilters[field];
            localStorage.setItem('threatModelColumnFilters', JSON.stringify(newFilters, (key, value) => {
                return value instanceof Set ? { values: Array.from(value) } : value;
            }));
            return newFilters;
        });
    };

    const handleFilterClose = (field: string) => {
        setFilterAnchorEl(prev => ({
            ...prev,
            [field]: null
        }));
    };

    const toggleFilter = (field: string, value: string) => {
        setColumnFilters(prev => {
            const newFilters = { ...prev };
            if (!newFilters[field]) {
                newFilters[field] = new Set([value]);
            } else {
                const currentSet = newFilters[field];
                if (currentSet) {
                    if (currentSet.has(value)) {
                        currentSet.delete(value);
                        if (currentSet.size === 0) {
                            delete newFilters[field];
                        }
                    } else {
                        currentSet.add(value);
                    }
                }
            }
            localStorage.setItem('threatModelColumnFilters', JSON.stringify(newFilters, (key, value) => {
                return value instanceof Set ? { values: Array.from(value) } : value;
            }));
            return newFilters;
        });
    };

    const renderFilterMenu = (field: keyof ThreatModel | 'actions') => (
        <Menu
            anchorEl={filterAnchorEl[field]}
            open={Boolean(filterAnchorEl[field])}
            onClose={() => handleFilterClose(field as string)}
            PaperProps={{
                className: "column-menu-paper"
            }}
        >
            <div className="filter-section">
                <h3>Filter Values</h3>
                {getFilteredValues(field as keyof ThreatModel).map((value) => (
                    <div key={value} className="checkbox-container">
                        <input
                            type="checkbox"
                            id={`filter-${String(field)}-${value}`}
                            checked={columnFilters[field]?.has(value) || false}
                            onChange={() => toggleFilter(field as string, value)}
                        />
                        <span>{value}</span>
                    </div>
                ))}
            </div>
        </Menu>
    );

    const filteredAndSortedData = useMemo(() => {
        if (!threatModels) return [];

        let processed = [...threatModels];

        // Apply column filters
        Object.entries(columnFilters).forEach(([field, values]) => {
            if (values && values.size > 0) {
                processed = processed.filter(item => {
                    const value = item[field as keyof ThreatModel];
                    const isEmpty = !value || (Array.isArray(value) && value.every(v => !v));

                    if (values.has('Empty') && isEmpty) return true;

                    if (Array.isArray(value)) {
                        return value.some(val => Array.from(values).some(filterValue =>
                            filterValue !== 'Empty' && String(val) === filterValue
                        ));
                    }

                    if (field === 'last_updated' && value) {
                        const date = new Date(String(value));
                        return !isNaN(date.getTime()) && values.has(date.toLocaleDateString());
                    }

                    return Array.from(values).some(filterValue =>
                        filterValue === 'Empty' ? isEmpty : String(value) === filterValue
                    );
                });
            }
        });

        // Apply search filter
        const searchTerms = searchQuery.toLowerCase().split(',').map(term => term.trim());
        if (searchQuery) {
            processed = processed.filter(model =>
                searchTerms.some(term =>
                    model.jira_issue_id.toLowerCase().includes(term) ||
                    model.product_team.toLowerCase().includes(term) ||
                    model.jira_release.toLowerCase().includes(term) ||
                    model.jira_title.toLowerCase().includes(term) ||
                    model.priority.toLowerCase().includes(term)
                )
            );
        }

        // Apply sorting
        return processed.sort((a, b) => {
            const aValue = String(a[sortConfig.field]);
            const bValue = String(b[sortConfig.field]);
            return sortConfig.direction === 'asc'
                ? aValue.localeCompare(bValue)
                : bValue.localeCompare(aValue);
        });
    }, [threatModels, sortConfig, searchQuery, columnFilters]);

    const feedbackStats = useMemo(() => {
        if (!threatModels) return { liked: 0, disliked: 0, neutral: 0, total: 0 };
        const total = threatModels.length;
        const liked = threatModels.filter(model => model.feedback === 'Liked').length;
        const disliked = threatModels.filter(model => model.feedback === 'Disliked').length;
        const neutral = total - liked - disliked;
        return { liked, disliked, neutral, total };
    }, [threatModels]);

    const handleDelete = async (jiraIssueId: string, event: React.MouseEvent) => {
        event.stopPropagation();
        if (window.confirm('Are you sure you want to delete this threat model? This action cannot be undone.')) {
            try {
                await axios.delete(`/threat-modeling/${jiraIssueId}`);
                queryClient.invalidateQueries('threatModels');
            } catch (error) {
                console.error('Error deleting threat model:', error);
                alert('Failed to delete threat model. Please try again.');
            }
        }
    };

    const toggleColumn = (field: string) => {
        setVisibleColumns(prev => {
            const newSet = new Set(prev);
            if (newSet.has(field)) {
                newSet.delete(field);
            } else {
                newSet.add(field);
            }
            localStorage.setItem('threatModelVisibleColumns', JSON.stringify(Array.from(newSet)));
            return newSet;
        });
    };

    const renderColumnHeader = (column: ColumnConfig) => {
        if (column.field === 'actions') return column.label;

        const filterSet = columnFilters[column.field as keyof ThreatModel | 'actions'];
        const hasActiveFilter = Boolean(filterSet?.size && filterSet.size > 0);

        return (
            <div className="column-header">
                <TableSortLabel
                    active={sortConfig.field === column.field}
                    direction={sortConfig.direction}
                    onClick={() => handleSort(column.field as keyof ThreatModel)}
                >
                    {column.label}
                </TableSortLabel>
                <div className="filter-actions">
                    <IconButton
                        size="small"
                        onClick={(e) => handleFilterClick(e, column.field as string)}
                        className={`filter-icon ${hasActiveFilter ? 'active' : ''}`}
                    >
                        <FaFilter size={12} />
                    </IconButton>
                    {hasActiveFilter && (
                        <IconButton
                            size="small"
                            onClick={(e) => clearColumnFilter(e, column.field as string)}
                            className="clear-filter-icon"
                            title="Clear filter"
                        >
                            <MdOutlineClose />
                        </IconButton>
                    )}
                </div>
                {renderFilterMenu(column.field as keyof ThreatModel | 'actions')}
            </div>
        );
    };

    if (isLoading) {
        return (
            <div className="application-table-container">
                <div id="loader">
                    <AnimatedShieldLoader />
                </div>
            </div>
        );
    }
    if (isError) return <div>Error: {(error as Error).message}</div>;

    return (
        <div className="application-table-container">
            <div className="application-table-search">
                <div className="feedback-stats">
                    <span title="Liked"><FaThumbsUp /> {feedbackStats.liked}</span>
                    <span title="Disliked"><FaThumbsDown /> {feedbackStats.disliked}</span>
                    <span title="Neutral"><FaMeh /> {feedbackStats.neutral}</span>
                    <span title="Total"><FaChartBar /> {feedbackStats.total}</span>
                </div>
                <button onClick={() => setIsAddModalOpen(true)} className="add-threat-model-btn">
                    <FaPlus /> New Threat Model
                </button>
                <button onClick={(e) => setColumnMenuAnchor(e.currentTarget)} className="add-threat-model-btn">
                    <CiViewColumn /> Columns
                </button>
                <button onClick={(e) => setFilterMenuAnchor(e.currentTarget)} className="add-threat-model-btn">
                    <MdOutlineFilterList /> Filters
                </button>
                <TextField
                    placeholder="Search..."
                    variant="outlined"
                    size="small"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <FaSearch className="search-icon" />
                            </InputAdornment>
                        ),
                        endAdornment: searchQuery && (
                            <InputAdornment position="end">
                                <IconButton
                                    size="small"
                                    onClick={() => setSearchQuery('')}
                                >
                                    <RiCloseCircleFill className="clear-icon" />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </div>

            <Menu
                anchorEl={columnMenuAnchor}
                open={Boolean(columnMenuAnchor)}
                onClose={() => setColumnMenuAnchor(null)}
                PaperProps={{
                    className: "column-menu-paper"
                }}
            >
                <div className="filter-section">
                    <h3>Columns</h3>
                    {COLUMNS.filter(col => col.canHide).map((column) => (
                        <div key={column.field} className="checkbox-container">
                            <input
                                type="checkbox"
                                id={`col-${column.field}`}
                                checked={visibleColumns.has(String(column.field))}
                                onChange={() => toggleColumn(String(column.field))}
                                disabled={!column.canHide}
                            />
                            <span className={!column.canHide ? 'required-column' : ''}>
                                {column.label}
                                {!column.canHide && <span className="required-indicator"> *</span>}
                            </span>
                        </div>
                    ))}
                </div>
                <div className="clear-filters-section">
                    <button
                        onClick={() => {
                            setVisibleColumns(new Set(
                                COLUMNS
                                    .filter(col => !col.canHide || !['priority', 'requestor'].includes(col.field as string))
                                    .map(col => col.field as string)
                            ));
                            localStorage.removeItem('threatModelVisibleColumns');
                            setColumnMenuAnchor(null);
                        }}
                        className="clear-all-btn"
                    >
                        <MdOutlineFilterListOff />
                        Reset to Default
                    </button>
                </div>
            </Menu>

            <Menu
                anchorEl={filterMenuAnchor}
                open={Boolean(filterMenuAnchor)}
                onClose={() => setFilterMenuAnchor(null)}
                PaperProps={{
                    className: "column-menu-paper"
                }}
            >
                <div className="filter-section">
                    <h3>Exercise Type</h3>
                    <div className="checkbox-container">
                        <input
                            type="checkbox"
                            id="filter-threat-modeling"
                            checked={columnFilters.exercise_type?.has('threat-modeling') || false}
                            onChange={() => toggleFilter('exercise_type', 'threat-modeling')}
                        />
                        <span>Threat Modeling</span>
                    </div>
                    <div className="checkbox-container">
                        <input
                            type="checkbox"
                            id="filter-arb-review"
                            checked={columnFilters.exercise_type?.has('arb-review') || false}
                            onChange={() => toggleFilter('exercise_type', 'arb-review')}
                        />
                        <span>ARB Review</span>
                    </div>
                </div>

                <div className="clear-filters-section">
                    <button
                        onClick={() => {
                            setSearchQuery('');
                            setColumnFilters({});
                            setActiveFilters(new Set());
                            localStorage.removeItem('searchQuery');
                            localStorage.removeItem('threatModelColumnFilters');
                            localStorage.removeItem('threatModelActiveFilters');
                            setFilterMenuAnchor(null);
                        }}
                        className="clear-all-btn"
                        disabled={!searchQuery && Object.keys(columnFilters).length === 0 && activeFilters.size === 0}
                    >
                        <MdOutlineFilterListOff />
                        Clear All Filters
                    </button>
                </div>
            </Menu>

            <TableContainer component={Paper} className="application-table-paper">
                <Table>
                    <TableHead>
                        <TableRow>
                            {COLUMNS.map((column) => (
                                visibleColumns.has(column.field as string) && (
                                    <TableCell key={column.field as string}>
                                        {renderColumnHeader(column)}
                                    </TableCell>
                                )
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredAndSortedData
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => (
                                <TableRow key={row.jira_issue_id} hover>
                                    {COLUMNS.map((column) => (
                                        visibleColumns.has(column.field as string) && (
                                            <TableCell key={column.field as string}>
                                                {column.field === 'exercise_type' && getExerciseTypeDisplay(row.exercise_type)}
                                                {column.field === 'jira_release' && row.jira_release}
                                                {column.field === 'jira_issue_id' && (
                                                    <div className="jira-id-container">
                                                        <a
                                                            href={`https://dayforce.atlassian.net/browse/${row.jira_issue_id}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="jira-link"
                                                        >
                                                            {row.jira_issue_id}
                                                        </a>
                                                        <div className="issue-type-badges">
                                                            <div
                                                                className={`issue-type-badge ${getIssueTypeBadgeClass(row.issue_type)}`}
                                                                title={row.issue_type || 'Unknown'}
                                                            >
                                                                {getIssueTypeLetter(row.issue_type)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {column.field === 'jira_title' && (
                                                    <div className={`jira-title-cell ${row.jira_title.length > 50 ? 'scrollable' : ''}`}>
                                                        {row.jira_title}
                                                    </div>
                                                )}
                                                {column.field === 'product_team' && (
                                                    <div className={`jira-title-cell ${row.product_team.length > 40 ? 'scrollable' : ''}`}>
                                                        {row.product_team}
                                                    </div>
                                                )}
                                                {column.field === 'priority' && row.priority}
                                                {column.field === 'requestor' && parseEmailToName(row.requestor)}
                                                {column.field === 'last_updated' && formatDate(row.last_updated)}
                                                {column.field === 'security_relevance_score' && (row.exercise_type === 'arb-review' ? 'N/A' : row.security_relevance_score)}
                                                {column.field === 'feedback' && (
                                                    <div className={`feedback-status ${row.feedback}`}>
                                                        {getFeedbackIcon(row.feedback)}
                                                        {row.feedback}
                                                    </div>
                                                )}
                                                {column.field === 'actions' && (
                                                    <div className="action-buttons">
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => setSelectedModel(row)}
                                                            className="view-model-btn"
                                                            title="View Details"
                                                        >
                                                            <FaEye />
                                                        </IconButton>
                                                        <IconButton
                                                            size="small"
                                                            onClick={(e) => handleDelete(row.jira_issue_id, e)}
                                                            className="delete-model-btn"
                                                            title="Delete Threat Model"
                                                        >
                                                            <FaTrash />
                                                        </IconButton>
                                                    </div>
                                                )}
                                            </TableCell>
                                        )
                                    ))}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                count={filteredAndSortedData.length}
                page={page}
                onPageChange={(_, newPage) => setPage(newPage)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(event) => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setPage(0);
                }}
                className="application-table-pagination"
            />

            {selectedModel && (
                <ThreatModelDetails
                    model={selectedModel}
                    onClose={() => setSelectedModel(null)}
                    onModelUpdate={(updatedModel) => {
                        setSelectedModel(updatedModel);
                        // Also update the data in the list
                        queryClient.setQueryData<ThreatModel[]>('threatModels', (old) => {
                            if (!old) return [];
                            return old.map(item =>
                                item.jira_issue_id === updatedModel.jira_issue_id ? updatedModel : item
                            );
                        });
                    }}
                    onRegenerateRequest={() => { }}
                />
            )}

            <AddThreatModelModal
                isOpen={isAddModalOpen}
                onClose={() => setIsAddModalOpen(false)}
            />
        </div>
    );
};

export default ThreatModelScanner;