import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider
} from 'react-router-dom'
import { PublicClientApplication } from "@azure/msal-browser";
import { QueryClient, QueryClientProvider } from "react-query";
import { msalConfig, appRoles } from "./authConfig"
import { RouteGuard } from './components/RouteGuard';

import Root from "./components/Root";
import Policies from "./components/Policies";
import { Applications } from "./components/Applications/Applications";
import { Dashboard } from "./components/Dashboard/Dashboard";
import { PolicyCreate } from "./components/PolicyCreate";
import ScanConfigurations from "./components/ScanConfigurations";
import ScanConfigurationCreate from "./components/ScanConfigurationCreate";
import ScanConfigurationEdit from "./components/ScanConfigurationEdit";
import { VeracodeApplications as VeracodeApps } from "./components/veracode/VeracodeApplications"
import { SecretsPage } from "./components/SecretsScanner/SecretsScanner";
import { ThreatModelScanner } from "./components/ThreatModeling/ThreatModelingList";
import { SettingsPage } from "./components/SettingsPage";
import ApplicationDetails from './components/Applications/ApplicationDetails';
import JiraStatistic from './components/JiraStatistic/JiraStatistic';
import SecurityMetadata from './components/SecurityMetadata/SecurityMetadata';
import { AssetRecords } from './components/AssetRecords/AssetRecords';
import AssetRecordDetails from './components/AssetRecords/AssetRecordDetails';
import { ReportingDashboard } from "./components/Reporting/ReportingDashboard";
import { AuditLogs } from './components/AuditLog/AuditLogs';
import { PolicyRecords } from './components/PolicyManager/PolicyRecords';
import { PolicyDetails } from './components/PolicyManager/PolicyRecordDetails';
import AdminPage from './components/Admin/AdminPage';
import { PolicyAuditLogs } from './components/AuditLog/CustomPolicy/PolicyAuditLogs';
import { ExceptionAuditLogs } from './components/AuditLog/Exceptions/ExceptionAuditLogs';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60,
        }
    }
})

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig);

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<Root instance={msalInstance} />}>
            <Route index element={
                <RouteGuard roles={[appRoles.RoleUser]}>
                    <Dashboard />
                </RouteGuard>} />
            <Route path="/scanConfigurations" element={
                <RouteGuard roles={[appRoles.RoleUser]}>
                    <ScanConfigurations />
                </RouteGuard>} />
            <Route path="/scanConfigurations/create" element={
                <RouteGuard roles={[appRoles.RoleAdmin]}>
                    <ScanConfigurationCreate />
                </RouteGuard>} />
            <Route path="/scanConfigurations/:id" element={
                <RouteGuard roles={[appRoles.RoleUser]}>
                    <ScanConfigurationEdit />
                </RouteGuard>} />
            <Route path="/policies" element={
                <RouteGuard roles={[appRoles.RoleUser]}>
                    <Policies />
                </RouteGuard>} />
            <Route path="/policies/create" element={
                <RouteGuard roles={[appRoles.RoleAdmin]}>
                    <PolicyCreate />
                </RouteGuard>} />
            <Route path="/applications" element={
                <RouteGuard roles={[appRoles.RoleUser]}>
                    <Applications />
                </RouteGuard>} />
            <Route path="/veracode/apps" element={
                <RouteGuard roles={[appRoles.RoleAdmin]}>
                    <VeracodeApps />
                </RouteGuard>} />
            <Route path="/application/:id" element={
                <RouteGuard roles={[appRoles.RoleAdmin]}>
                    <ApplicationDetails />
                </RouteGuard>} />
            <Route path="/jira-statistic" element={
                <RouteGuard roles={[appRoles.RoleAdmin]}>
                    <JiraStatistic />
                </RouteGuard>} />
            <Route path="/security-metadata" element={
                <RouteGuard roles={[appRoles.RoleAdmin]}>
                    <SecurityMetadata />
                </RouteGuard>} />
            <Route path="/secrets-scanner" element={
                <RouteGuard roles={[appRoles.RoleAdmin]}>
                    <SecretsPage />
                </RouteGuard>} />
            <Route path="/threat-modeling" element={
                <RouteGuard roles={[appRoles.RoleAdmin]}>
                    <ThreatModelScanner />
                </RouteGuard>} />
            <Route path="/assets" element={
                <RouteGuard roles={[appRoles.RoleAdmin]}>
                    <AssetRecords />
                </RouteGuard>} />
            <Route path="/assets/:id" element={
                <RouteGuard roles={[appRoles.RoleAdmin]}>
                    <AssetRecordDetails />
                </RouteGuard>} />
            <Route path="/settings" element={
                <RouteGuard roles={[appRoles.RoleAdmin]}>
                    <SettingsPage />
                </RouteGuard>} />
            <Route path="/reporting" element={
                <RouteGuard roles={[appRoles.RoleAdmin]}>
                    <ReportingDashboard />
                </RouteGuard>} />
            <Route path="/audit-log/:id?" element={
                <RouteGuard roles={[appRoles.RoleAdmin]}>
                    <AuditLogs />
                </RouteGuard>} />
            <Route path="/policy-manager/" element={
                <RouteGuard roles={[appRoles.RoleAdmin]}>
                    <PolicyRecords />
                </RouteGuard>} />
            <Route path="/policy-manager/:id" element={
                <RouteGuard roles={[appRoles.RoleAdmin]}>
                    <PolicyDetails />
                </RouteGuard>} />
            <Route path="/hidden-admin" element={
                <RouteGuard roles={[appRoles.RoleAdmin]}>
                    <AdminPage />
                </RouteGuard>} />
            <Route path="/audit-log-policy" element={
                <RouteGuard roles={[appRoles.RoleAdmin]}>
                    <PolicyAuditLogs />
                </RouteGuard>} />
            <Route path="/audit-log-exception" element={
                <RouteGuard roles={[appRoles.RoleAdmin]}>
                    <ExceptionAuditLogs />
                </RouteGuard>} />
        </Route>

    )
)

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
        </QueryClientProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
