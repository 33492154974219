import React, { useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination
} from '@mui/material';
import { ScanResult } from "./ScanResult";
import { ScanResultsItem } from "./ScanResultsItem";
import "./ScanResultsList.scss";

interface Props {
    items: ScanResult[];
}

export const ScanResultsList: React.FC<Props> = ({ items }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const sortedItems = [...items].sort((a, b) => {
        return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
    });

    return (
        <div className="application-table-container">
            <TableContainer component={Paper} className="application-table-paper">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Scan Run</TableCell>
                            <TableCell>GitHub Workflow Name</TableCell>
                            <TableCell>Scanner</TableCell>
                            <TableCell>Scan Type</TableCell>
                            <TableCell>Scan Status</TableCell>
                            <TableCell>Start Time</TableCell>
                            <TableCell>End Time</TableCell>
                            <TableCell>Vulnerabilities</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedItems
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((item) => (
                                <ScanResultsItem key={item.scanExecutionId} item={item} />
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                count={items.length}
                page={page}
                onPageChange={(_, newPage) => setPage(newPage)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(event) => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setPage(0);
                }}
                className="application-table-pagination"
            />
        </div>
    );
};