import React from "react";
import axios from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import { ApplicationsCard } from '../Dashboard/ApplicationsCard';
import { SecretsScannerCard } from '../Dashboard/SecretsScannerCard';
import { ThreatModelCard } from '../Dashboard/ThreatModelCard';
import { VulnerabilitiesChartCard } from '../Dashboard/VulnerabilitiesChartCard';
import { CurrentlyOpenCard } from '../Dashboard/CurrentlyOpenCard';
import { ResolvedCard } from '../Dashboard/ResolvedCard';
import { BlockedCard } from "../Dashboard/BlockedCard";
import { JiraStatisticScale, JiraStatisticAppFamilyFilter } from '../shared/JiraStatisticFilterConstants';
import { MultiselectOption } from '../shared/Multiselect/MultiselectOption';

import "./Dashboard.scss";

interface Secret {
    secret_id: string;
    file_path: string;
    last_seen: string;
    line_number: number;
    repo_name: string;
    secret_type: string;
    default_branch: string;
    true_secret: boolean;
}

interface ThreatModel {
    security_relevance_score: number;
}

interface Application {
    id: string;
    name: string;
    policyName: string;
    policyComplianceStatus: 'PASSED' | 'DID_NOT_PASS' | 'CONDITIONAL_PASS' | 'NOT_ASSESSED' | 'DETERMINING';
    lastCompletedScanDate: string;
}

interface CombinedDataPoint {
    timeStamp: string;
    [key: string]: string | number;
}

const fetchApplications = async (): Promise<Application[]> => {
    const response = await axios.get('/applications');
    return response.data.applications;
};

const fetchSecrets = async (): Promise<Secret[]> => {
    try {
        const repoName = '-';
        const response = await axios.get('/secrets-scanner/' + repoName.toLowerCase());
        return response.data;
    } catch (error) {
        console.error("Error fetching secrets:", error);
        return [];
    }
};

const fetchThreatModels = async (): Promise<ThreatModel[]> => {
    try {
        const response = await axios.get('/threat-modeling');
        return response.data;
    } catch (error) {
        console.error("Error fetching threat models:", error);
        return [];
    }
};

const fetchVulnerabilityData = async (): Promise<CombinedDataPoint[]> => {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setMonth(endDate.getMonth() - 1);

    const familiesResponse = await axios.get('/SecurityMetadata/app-and-families');
    const families = familiesResponse.data.families as MultiselectOption[];
    const allFamilies = families.map(f => f.value);

    const vulnerabilityResponse = await axios.post('/jirastatistic/getVulnerabilityRemediationExpanded', {
        scale: JiraStatisticScale.Week,
        startDate: startDate,
        endDate: endDate,
        nameType: JiraStatisticAppFamilyFilter.ProductFamily,
        selectedNames: allFamilies
    });

    if (!vulnerabilityResponse.data || !Array.isArray(vulnerabilityResponse.data)) {
        throw new Error('Invalid response data format');
    }

    const SCAN_TYPES = ['SAST', 'SCA', 'DAST', 'Pentest', 'Bug Bounty'];
    const combinedData: { [key: string]: CombinedDataPoint } = {};

    vulnerabilityResponse.data.forEach((item: any) => {
        if (item.statusCountsData && Array.isArray(item.statusCountsData)) {
            item.statusCountsData.forEach((stat: any) => {
                const date = stat.timeStamp.toString().split('T')[0];
                
                if (!combinedData[date]) {
                    combinedData[date] = {
                        timeStamp: date,
                        ...SCAN_TYPES.reduce((acc, type) => ({
                            ...acc,
                            [`${type}-open`]: 0,
                            [`${type}-closed`]: 0
                        }), {})
                    };
                }
                
                combinedData[date][`${item.scanType}-open`] = Math.ceil(stat.open);
                combinedData[date][`${item.scanType}-closed`] = Math.ceil(stat.closed);
                
                if (stat.created !== undefined) {
                    combinedData[date][`${item.scanType}-created`] = Math.ceil(stat.created);
                }
                if (stat.inProgress !== undefined) {
                    combinedData[date][`${item.scanType}-inProgress`] = Math.ceil(stat.inProgress);
                }
                if (stat.blocked !== undefined) {
                    combinedData[date][`${item.scanType}-blocked`] = Math.ceil(stat.blocked);
                }
            });
        }
    });

    return Object.values(combinedData)
        .sort((a, b) => new Date(a.timeStamp).getTime() - new Date(b.timeStamp).getTime());
};

export const Dashboard: React.FC = () => {
    const queryOptions = {
        refetchOnWindowFocus: false,
        refetchInterval: false as const,
        refetchOnMount: false,
        staleTime: Infinity
    } satisfies UseQueryOptions;

    const { 
        data: applications = [],
        isLoading: applicationsLoading
    } = useQuery('applications', fetchApplications, queryOptions);
    
    const { 
        data: secrets = [],
        isLoading: secretsLoading
    } = useQuery('secrets', fetchSecrets, queryOptions);

    const { 
        data: threatModels = [],
        isLoading: threatModelsLoading 
    } = useQuery('threatModels', fetchThreatModels, queryOptions);

    const {
        data: vulnerabilityData = [],
        isLoading: vulnerabilityLoading
    } = useQuery('vulnerabilities', fetchVulnerabilityData, queryOptions);

    const secretStats = React.useMemo(() => {
        if (!secrets) return { total: 0, keywords: 0, jwt: 0, entropy: 0, other: 0 };
        const trueSecrets = secrets.filter(s => s.true_secret);
        const keywords = trueSecrets.filter(s => s.secret_type === 'Secret Keyword').length;
        const jwt = trueSecrets.filter(s => s.secret_type === 'JSON Web Token').length;
        const entropy = trueSecrets.filter(s => s.secret_type.includes('High Entropy')).length;
        const total = trueSecrets.length;
        const other = total - (keywords + jwt + entropy);
        return {
            total,
            keywords,
            jwt,
            entropy,
            other
        };
    }, [secrets]);

    // Calculate currently open issues data from the latest timestamp
    const currentOpenData = React.useMemo(() => {
        if (!vulnerabilityData || !vulnerabilityData.length) {
            return { SAST: 0, SCA: 0, DAST: 0, Other: 0 };
        }

        // Get the latest timestamp data
        const latestData = vulnerabilityData[vulnerabilityData.length - 1];
        
        // Extract open issues by scan type
        const openByType: { [key: string]: number } = {
            SAST: 0,
            SCA: 0,
            DAST: 0,
            Other: 0
        };

        Object.keys(latestData).forEach(key => {
            if (key.endsWith('-open')) {
                const scanType = key.split('-')[0];
                if (['SAST', 'SCA', 'DAST'].includes(scanType)) {
                    openByType[scanType] += Number(latestData[key]);
                } else {
                    openByType.Other += Number(latestData[key]);
                }
            }
        });

        return openByType;
    }, [vulnerabilityData]);

    // Calculate resolved issues data from the latest timestamp
    const resolvedData = React.useMemo(() => {
        if (!vulnerabilityData || !vulnerabilityData.length) {
            return { SAST: 0, SCA: 0, DAST: 0, Other: 0 };
        }
        
        // Get the latest timestamp data
        const latestData = vulnerabilityData[vulnerabilityData.length - 1];
        
        // Extract closed issues by scan type
        const resolvedByType: { [key: string]: number } = {
            SAST: 0,
            SCA: 0,
            DAST: 0,
            Other: 0
        };

        Object.keys(latestData).forEach(key => {
            if (key.endsWith('-closed')) {
                const scanType = key.split('-')[0];
                if (['SAST', 'SCA', 'DAST'].includes(scanType)) {
                    resolvedByType[scanType] += Number(latestData[key]);
                } else {
                    resolvedByType.Other += Number(latestData[key]);
                }
            }
        });

        return resolvedByType;
    }, [vulnerabilityData]);

    // Calculate blocked issues data from the latest timestamp
    const blockedData = React.useMemo(() => {
        if (!vulnerabilityData || !vulnerabilityData.length) {
            return { SAST: 0, SCA: 0, DAST: 0, Other: 0 };
        }

        // Get the latest timestamp data
        const latestData = vulnerabilityData[vulnerabilityData.length - 1];
        
        // Extract blocked issues by scan type
        const blockedByType: { [key: string]: number } = {
            SAST: 0,
            SCA: 0,
            DAST: 0,
            Other: 0
        };

        Object.keys(latestData).forEach(key => {
            if (key.endsWith('-blocked')) {
                const scanType = key.split('-')[0];
                if (['SAST', 'SCA', 'DAST'].includes(scanType)) {
                    blockedByType[scanType] += Number(latestData[key]);
                } else {
                    blockedByType.Other += Number(latestData[key]);
                }
            }
        });

        return blockedByType;
    }, [vulnerabilityData]);

    return (
        <div className="dashboard-container">
            <div className="dashboard-stats">
                <ApplicationsCard 
                    applications={applications}
                    isLoading={applicationsLoading}
                />
                <SecretsScannerCard 
                    secretStats={secretStats}
                    isLoading={secretsLoading}
                />
                <ThreatModelCard 
                    threatModels={threatModels}
                    isLoading={threatModelsLoading}
                />
                <CurrentlyOpenCard 
                    data={currentOpenData}
                    isLoading={vulnerabilityLoading}
                />
                <ResolvedCard 
                    data={resolvedData}
                    isLoading={vulnerabilityLoading}
                />
                <BlockedCard 
                    data={blockedData}
                    isLoading={vulnerabilityLoading}
                />
                <VulnerabilitiesChartCard 
                    data={vulnerabilityData}
                    isLoading={vulnerabilityLoading}
                />
            </div>
        </div>
    );
};

export default Dashboard;