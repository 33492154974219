import React, { useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import {
  BsCheck,
  BsShieldFill,
  BsShieldFillExclamation,
  BsShieldFillMinus,
  BsSearch,
  BsChevronDown,
} from "react-icons/bs";
import {
  MdError,
  MdCheck,
  MdSave,
  MdClose,
  MdOutlineArrowBack,
  MdOutlineArrowForward,
} from "react-icons/md";
import { motion, AnimatePresence } from "framer-motion";
import AnimatedCheckmark from "../shared/AnimatedCheckmark";
import { AssetRecord } from "../AssetRecords/AssetRecord";

import "./PolicyForm.scss";

interface SeverityConfig {
  enabled: boolean;
  gracePeriod: number;
}

interface SeverityConfigs {
  critical: SeverityConfig;
  high: SeverityConfig;
  medium: SeverityConfig;
  low: SeverityConfig;
}

interface PolicyFormData {
  policyName: string;
  description: string;
  assetSelector: string;
  severityConfigs: SeverityConfigs;
  approver: string;
  isActive: boolean;
  isDraft: boolean;
}

interface SelectorsData {
  assets: AssetRecord[];
  users: string[];
}
interface WizardProps {
  isOpen: boolean;
  onClose: () => void;
}

const SEVERITY_LEVELS = ["critical", "high", "medium", "low"] as const;

// const ASSET_OPTIONS = [
//     { value: 'all', label: 'All Assets' },
//     { value: 'web', label: 'Web Applications' },
//     { value: 'mobile', label: 'Mobile Applications' },
//     { value: 'api', label: 'APIs' }
// ];

const GRACE_PERIOD_OPTIONS = [
  { value: 0, label: "Immediate" },
  { value: 15, label: "15 days" },
  { value: 30, label: "30 days" },
  { value: 60, label: "60 days" },
  { value: 90, label: "90 days" },
  { value: 180, label: "180 days" },
];

// const APPROVER_OPTIONS = [
//     { value: 'test@dayforce.com', label: 'Johnny Appleseed' },
//     { value: 'test@dayforce.com', label: 'Alison Clarke' },
//     { value: 'test@dayforce.com', label: 'Darth Vader' }
// ];

const STEPS = [
  {
    id: 1,
    title: "Basic Info",
    fields: ["policyName", "description", "assetSelector"],
    description: "Enter the target assets, policy name and description",
  },
  {
    id: 2,
    title: "AppSec Compliance",
    fields: ["scanConfigs"],
    description: "Configure tracked severity levels and grace periods",
  },
];

const FORM_FIELDS = {
  assetSelector: {
    label: "Target Asset(s)",
    required: true,
    placeholder: "Select the assets to which this policy will be applied",
  },
  policyName: {
    label: "Policy Name",
    required: true,
    placeholder: "Enter a name for the security policy",
  },
  description: {
    label: "Description",
    required: true,
    placeholder: "Enter description of security policy",
    rows: 4,
  },
};

const FormLabel: React.FC<{ fieldName: string; isRequired: boolean }> = ({
  fieldName,
  isRequired,
}) => (
  <label className={isRequired ? "required" : ""}>
    {fieldName}
    {isRequired && <span className="required-indicator">*</span>}
  </label>
);

const fetchData = async (): Promise<SelectorsData> => {
  let assetsResponse = await axios.get("/assets/all");
  let usersResponse = await axios.get("/userInfo");

  return {
    assets: assetsResponse.data,
    users: usersResponse.data.map((user: any) => user.email),
  };
};

const NewPolicyWizard: React.FC<WizardProps> = ({ isOpen, onClose }) => {
  const {
    data,
    //isLoading,
    //isSuccess
  } = useQuery("get-seletors-data", fetchData);

  const [currentStep, setCurrentStep] = useState(1);
  const [direction, setDirection] = useState(0);
  const [formData, setFormData] = useState<Partial<PolicyFormData>>({
    assetSelector: "",
    severityConfigs: {
      critical: { enabled: false, gracePeriod: 30 },
      high: { enabled: false, gracePeriod: 30 },
      medium: { enabled: false, gracePeriod: 30 },
      low: { enabled: false, gracePeriod: 30 },
    },
    approver: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationMessages, setValidationMessages] = useState<string[]>([]);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [assetSelectorOpen, setAssetSelectorOpen] = useState(false);
  const [assetSearchTerm, setAssetSearchTerm] = useState("");
  const navigate = useNavigate();

  // Filter assets based on search term
  const filteredAssets = useMemo(() => {
    if (!assetSearchTerm) return data?.assets || [];

    return (data?.assets || []).filter(
      (asset) =>
        asset.assetName.toLowerCase().includes(assetSearchTerm.toLowerCase()) ||
        asset.canonicalId.toLowerCase().includes(assetSearchTerm.toLowerCase())
    );
  }, [data?.assets, assetSearchTerm]);

  useEffect(() => {
    if (isOpen) document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  useEffect(() => {
    if (validationMessages.length > 0) {
      const timeoutId = setTimeout(() => setValidationMessages([]), 10000);
      return () => clearTimeout(timeoutId);
    }
  }, [validationMessages]);

  // Close asset selector dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (assetSelectorOpen && !target.closest(".asset-selector")) {
        setAssetSelectorOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [assetSelectorOpen]);

  const validateStep = (stepNumber: number): string[] => {
    const errors: string[] = [];

    switch (stepNumber) {
      case 1:
        if (!formData.policyName?.trim())
          errors.push("Policy name is required");
        if (!formData.description?.trim())
          errors.push("Description is required");
        if (!formData.assetSelector) errors.push("Asset selection is required");
        break;
      case 2:
        const hasAnySeverityEnabled = Object.values(
          formData.severityConfigs || {}
        ).some((config) => config.enabled);

        if (!hasAnySeverityEnabled) {
          errors.push("At least one severity level must be tracked");
        }
        break;
      case 3:
        if (!formData.approver?.trim())
          errors.push("Approver selection is required");
        break;
    }
    return errors;
  };

  const handleInputChange = (field: string, value: any) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleAssetSelect = (assetId: string) => {
    let newSelection;
    const currentAssetSelector = formData.assetSelector || "";

    if (currentAssetSelector === "") {
      newSelection = assetId;
    } else {
      const currentSelections = currentAssetSelector.split("|");

      if (currentSelections.includes(assetId)) {
        const filteredSelections = currentSelections.filter(
          (id) => id !== assetId
        );

        if (filteredSelections.length === 0) {
          newSelection = "";
        } else {
          newSelection = filteredSelections.join("|");
        }
      } else {
        newSelection = `${currentAssetSelector}|${assetId}`;
      }
    }

    handleInputChange("assetSelector", newSelection);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const errors = validateStep(currentStep);

    if (errors.length) {
      setValidationMessages(errors);
      return;
    }

    if (currentStep < STEPS.length) {
      setDirection(1);
      setTimeout(() => {
        setCurrentStep((prev) => prev + 1);
      }, 0);
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await axios.post("/policies", {
        ...formData,
        isDraft: true,
        isActive: false,
      });

      if (response.status === 200) {
        setSuccessMessage("New Policy Added Successfully");
        setTimeout(() => navigate(`/policy-manager/${response.data.id}`), 1000);
      }
    } catch (error: any) {
      setValidationMessages([
        error.response?.data?.message || "Error creating policy",
      ]);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePrevious = () => {
    setDirection(-1);
    setTimeout(() => {
      setCurrentStep((prev) => prev - 1);
    }, 0);
  };

  const renderBasicInfo = () => (
    <>
      <div className="form-group">
        <FormLabel
          fieldName={FORM_FIELDS.assetSelector.label}
          isRequired={true}
        />
        <div className="asset-selector">
          <div
            className="selected-assets"
            onClick={() => setAssetSelectorOpen(!assetSelectorOpen)}
          >
            <span>
              {(formData.assetSelector || "") === ""
                ? "Select Assets"
                : `${
                    (formData.assetSelector || "").split("|").length
                  } Assets Selected`}
            </span>
            <BsChevronDown className={assetSelectorOpen ? "rotated" : ""} />
          </div>

          {assetSelectorOpen && (
            <div className="asset-dropdown">
              <div className="filter-section__search">
                <BsSearch className="search-icon" />
                <input
                  type="text"
                  placeholder="Search assets..."
                  value={assetSearchTerm}
                  onChange={(e) => setAssetSearchTerm(e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                />
              </div>

              <div className="asset-options">
                {filteredAssets.map((option) => {
                  const currentSelector = formData.assetSelector || "";
                  const isSelected =
                    currentSelector !== "" &&
                    currentSelector.split("|").includes(option.id);

                  return (
                    <div
                      key={option.id}
                      className={`asset-option ${isSelected ? "selected" : ""}`}
                      onClick={() => handleAssetSelect(option.id)}
                    >
                      <span>
                        {option.assetName + " - " + option.canonicalId}
                      </span>
                      {isSelected && <BsCheck className="check-icon" />}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="form-group">
        <FormLabel fieldName={FORM_FIELDS.policyName.label} isRequired={true} />
        <input
          type="text"
          className="modern-input"
          value={formData.policyName || ""}
          onChange={(e) => handleInputChange("policyName", e.target.value)}
          placeholder={FORM_FIELDS.policyName.placeholder}
        />
      </div>
      <div className="form-group">
        <FormLabel
          fieldName={FORM_FIELDS.description.label}
          isRequired={true}
        />
        <textarea
          className="modern-input"
          value={formData.description || ""}
          onChange={(e) => handleInputChange("description", e.target.value)}
          placeholder={FORM_FIELDS.description.placeholder}
          rows={4}
        />
      </div>
    </>
  );

  const severityIcons = {
    critical: <BsShieldFillExclamation size={18} color="#ff4d4f" />,
    high: <BsShieldFillExclamation size={18} color="#ff7a45" />,
    medium: <BsShieldFillMinus size={18} color="#ffd666" />,
    low: <BsShieldFillMinus size={18} color="#95de64" />,
  };

  const renderScanConfigs = () => (
    <div className="scan-configs-section">
      <div className="scan-type-container">
        <div className="severity-grid">
          <div className="grid-header">
            <div className="severity-column">Severity Level</div>
            <div className="enabled-column">Track</div>
            <div className="grace-column">Grace Period</div>
          </div>
          {SEVERITY_LEVELS.map((severity) => (
            <div key={severity} className="grid-row">
              <div className="severity-column">
                <div className="severity-label">
                  {severityIcons[severity]}
                  <span>
                    {severity.charAt(0).toUpperCase() + severity.slice(1)}
                  </span>
                </div>
              </div>
              <div className="enabled-column">
                <input
                  type="checkbox"
                  id={severity}
                  checked={
                    formData.severityConfigs?.[severity]?.enabled ?? false
                  }
                  onChange={(e) => {
                    const newSeverityConfigs = {
                      ...formData.severityConfigs,
                      [severity]: {
                        enabled: e.target.checked,
                        gracePeriod:
                          formData.severityConfigs?.[severity]?.gracePeriod ??
                          30,
                      },
                    };
                    handleInputChange("severityConfigs", newSeverityConfigs);
                  }}
                />
              </div>
              <div className="grace-column">
                {formData.severityConfigs?.[severity]?.enabled ? (
                  <select
                    value={
                      formData.severityConfigs?.[severity]?.gracePeriod ?? 30
                    }
                    onChange={(e) => {
                      const newSeverityConfigs = {
                        ...formData.severityConfigs,
                        [severity]: {
                          enabled: true,
                          gracePeriod: Number(e.target.value),
                        },
                      };
                      handleInputChange("severityConfigs", newSeverityConfigs);
                    }}
                  >
                    {GRACE_PERIOD_OPTIONS.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                ) : (
                  <select disabled>
                    <option>30 days</option>
                  </select>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const renderContent = () => {
    const step = STEPS[currentStep - 1];

    return (
      <AnimatePresence mode="wait" initial={false}>
        <motion.div
          key={currentStep}
          initial={{ x: direction === 1 ? 100 : -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: direction === 1 ? -100 : 100, opacity: 0 }}
          transition={{
            duration: 0.3,
            ease: "easeInOut",
            type: "tween",
          }}
          className="step-content"
        >
          <h2>{step.title}</h2>
          <p className="step-description">{step.description}</p>
          {step.id === 1 && renderBasicInfo()}
          {step.id === 2 && renderScanConfigs()}
        </motion.div>
      </AnimatePresence>
    );
  };

  if (!isOpen) return null;

  return (
    <div className="add-application-modal-overlay">
      <div className="modal-content add-application-modal full-screen">
        <button className="close-btn" onClick={onClose}>
          ×
        </button>

        <nav className="wizard-nav">
          <div className="wizard-header">
            <div className="header-content">
              <BsShieldFill className="icon" />
              <h1>New Security Policy</h1>
            </div>
          </div>

          <div className="steps-indicator">
            {STEPS.map((step) => (
              <div
                key={step.id}
                className={`step ${currentStep > step.id ? "completed" : ""} ${
                  currentStep === step.id ? "active" : ""
                }`}
              >
                <div className="step-content">
                  <div className="step-number">
                    {currentStep > step.id ? (
                      <motion.div initial="hidden" animate="visible">
                        <AnimatedCheckmark />
                      </motion.div>
                    ) : (
                      step.id
                    )}
                  </div>
                  <span className="step-title">{step.title}</span>
                </div>
              </div>
            ))}
          </div>
        </nav>

        <form onSubmit={handleSubmit}>
          <div className="wizard-content">
            {renderContent()}

            {validationMessages.length > 0 && (
              <div className="error-message">
                {validationMessages.map((message, index) => (
                  <div key={index}>
                    <MdError /> {message}
                  </div>
                ))}
              </div>
            )}

            {successMessage && (
              <div className="success-message">
                <MdCheck /> {successMessage}
              </div>
            )}
          </div>

          <div className="button-group">
            <button
              type="button"
              onClick={onClose}
              className="cancel-btn"
              disabled={isSubmitting}
            >
              <MdClose />
              Cancel
            </button>
            {currentStep > 1 && (
              <button
                type="button"
                onClick={handlePrevious}
                className="previous-btn"
                disabled={isSubmitting}
              >
                <MdOutlineArrowBack />
                Previous
              </button>
            )}
            <button
              type="submit"
              className="submit-btn"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <Oval
                    visible={true}
                    height="14"
                    width="14"
                    color="#ffffff"
                    secondaryColor="#ffffff"
                    ariaLabel="loading"
                  />
                  Submitting...
                </>
              ) : currentStep === STEPS.length ? (
                <>
                  <MdSave />
                  Save as Draft
                </>
              ) : (
                <>
                  <MdOutlineArrowForward />
                  Next
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewPolicyWizard;
