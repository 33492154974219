import React from 'react';
import { BiBlock } from "react-icons/bi";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { motion } from "framer-motion";
import { LoadingCardContent } from '../shared/LoadingCardContent';
import { AnimatedNumber } from '../shared/AnimatedNumber';

interface BlockedCardProps {
    data: { [key: string]: number };
    isLoading: boolean;
}

const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
};

const itemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 }
};

const AnimatedCard: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <motion.div 
            className="stat-card"
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.3 }}
        >
            {children}
        </motion.div>
    );
};

const AnimatedStatItem: React.FC<{ 
    icon?: React.ReactNode;
    label: string;
    value: number;
    index: number;
}> = ({ icon, label, value, index }) => {
    return (
        <motion.div 
            className="stat-item"
            variants={itemVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.3, delay: index * 0.1 }}
        >
            {icon ? icon : <span className="type-indicator scan-type-indicator"></span>}
            <span>{value} {label}</span>
        </motion.div>
    );
};

const CardHeader = () => (
    <div className="card-header">
        <div className="title">
            <BiBlock size={18} />
            <span>Blocked Issues</span>
        </div>
    </div>
);

export const BlockedCard: React.FC<BlockedCardProps> = ({ data, isLoading }) => {
    if (isLoading) {
        return (
            <AnimatedCard>
                <CardHeader />
                <LoadingCardContent />
            </AnimatedCard>
        );
    }

    const totalBlocked = Object.values(data).reduce((sum, count) => sum + count, 0);

    const chartData = [
        { name: 'SAST', value: data.SAST || 0, color: '#1EB980' },
        { name: 'SCA', value: data.SCA || 0, color: '#FFCF44' },
        { name: 'DAST', value: data.DAST || 0, color: '#60a5fa' },
        { name: 'Other', value: data.Other || 0, color: '#6b7280' }
    ];

    const chartColors = [
        { color: '#60a5fa' },
        { color: '#FFCF44' },
        { color: '#1EB980' },
        { color: '#6b7280' }
    ];

    return (
        <AnimatedCard>
            <CardHeader />
            <div className="card-content">
                <div className="main-stat">
                    <span className="number">
                        <AnimatedNumber value={totalBlocked} />
                    </span>
                    <span className="label">Total Blocked Issues</span>
                    <div className="chart-container" style={{ height: '120px', marginTop: '1rem' }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart>
                                <Pie
                                    data={chartData}
                                    innerRadius={25}
                                    outerRadius={40}
                                    paddingAngle={2}
                                    dataKey="value"
                                >
                                    {chartColors.map((entry, index) => (
                                        <Cell key={index} fill={entry.color} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className="stat-breakdown">
                    <AnimatedStatItem
                        label="SAST Issues"
                        value={data.SAST || 0}
                        index={0}
                    />
                    <AnimatedStatItem
                        label="SCA Issues"
                        value={data.SCA || 0}
                        index={1}
                    />
                    <AnimatedStatItem
                        label="DAST Issues"
                        value={data.DAST || 0}
                        index={2}
                    />
                    <AnimatedStatItem
                        label="Other Issues"
                        value={data.Other || 0}
                        index={3}
                    />
                </div>
            </div>
        </AnimatedCard>
    );
};