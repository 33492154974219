import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Oval } from 'react-loader-spinner';
import { MdEdit, MdCheck, MdError, MdClose } from "react-icons/md";

interface BulkEditModalProps {
    isOpen: boolean;
    onClose: () => void;
    selectedAssetIds: string[];
    onSuccess: () => void;
}

const ARRAY_FIELDS = ['psArchitects', 'productSecurityPM', 'productManager', 'devLeads', 'securityChampions'];
const ENVIRONMENT_OPTIONS = ['Camelot', 'Avalon', 'CHIP', 'MDP', 'Other'];

const initialFormData = {
    productFamilyName: '',
    applicationName: '',
    componentName: '',
    psArchitects: '',
    productSecurityPM: '',
    productManager: '',
    devLeads: '',
    securityChampions: '',
    jiraProject: '',
    platformBase: ''
};

const BulkEditModal: React.FC<BulkEditModalProps> = ({
    isOpen,
    onClose,
    selectedAssetIds,
    onSuccess
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [formData, setFormData] = useState(initialFormData);
    const [progress, setProgress] = useState(0);
    const [totalAssets, setTotalAssets] = useState(0);
    const [processedAssets, setProcessedAssets] = useState(0);

    useEffect(() => {
        if (isOpen) {
            setFormData(initialFormData);
            setProgress(0);
            setTotalAssets(0);
            setProcessedAssets(0);
            setError(null);
            setSuccessMessage(null);
        }
    }, [isOpen]);

    const handleClose = () => {
        setError(null);
        setSuccessMessage(null);
        setProgress(0);
        onClose();
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
        setError(null);
        setSuccessMessage(null);
        setProgress(0);
        setProcessedAssets(0);

        try {
            // Only include non-empty fields
            const updatedFields: Record<string, any> = {};
            Object.entries(formData).forEach(([key, value]) => {
                if (value.trim() !== '') {
                    if (ARRAY_FIELDS.includes(key)) {
                        updatedFields[key] = value.split(',').map(item => item.trim()).filter(Boolean);
                    } else {
                        updatedFields[key] = value.trim();
                    }
                }
            });

            if (Object.keys(updatedFields).length === 0) {
                setError("Please fill at least one field to update");
                setIsSubmitting(false);
                return;
            }

            const assetsResponse = await axios.get('/assets');
            const allAssets = assetsResponse.data;
            const assetsToUpdate = allAssets
                .filter(asset => selectedAssetIds.includes(asset.id))
                .map(asset => ({ ...asset, ...updatedFields }));

            setTotalAssets(assetsToUpdate.length);
            setSuccessMessage(`Preparing to update ${assetsToUpdate.length} assets...`);

            const BATCH_SIZE = 5;
            let successCount = 0;
            let failCount = 0;

            for (let i = 0; i < assetsToUpdate.length; i += BATCH_SIZE) {
                try {
                    const batch = assetsToUpdate.slice(i, i + BATCH_SIZE);
                    const currentBatchSize = batch.length;

                    await axios.post('/assets/bulk', batch);
                    successCount += currentBatchSize;

                    // Update progress
                    const newProcessedCount = Math.min(i + BATCH_SIZE, assetsToUpdate.length);
                    setProcessedAssets(newProcessedCount);
                    setProgress(Math.floor((newProcessedCount / assetsToUpdate.length) * 100));
                    setSuccessMessage(`Processing ${newProcessedCount} of ${assetsToUpdate.length} assets...`);

                    await new Promise(resolve => setTimeout(resolve, 1000));
                } catch (err) {
                    console.error(`Failed to update batch starting at index ${i}:`, err);
                    failCount += Math.min(BATCH_SIZE, assetsToUpdate.length - i);

                    // Update progress even for failed batches
                    const newProcessedCount = Math.min(i + BATCH_SIZE, assetsToUpdate.length);
                    setProcessedAssets(newProcessedCount);
                    setProgress(Math.floor((newProcessedCount / assetsToUpdate.length) * 100));
                }
            }

            if (failCount === 0) {
                setProgress(100);
                setSuccessMessage(`All ${successCount} assets updated successfully.`);
            } else {
                setError(`Updated ${successCount} assets. Failed to update ${failCount} assets.`);
            }

            onSuccess();
        } catch (error) {
            console.error("Error updating assets:", error);
            setError("An error occurred while updating assets.");
        } finally {
            setIsSubmitting(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content add-threat-model-modal">
                <h3>
                    <MdEdit className="icon" />
                    Bulk Edit
                </h3>
                <form onSubmit={handleSubmit}>
                    <div className="info-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '1rem' }}>
                        <div className="form-group">
                            <label htmlFor="productFamilyName">Product Family</label>
                            <input
                                type="text"
                                id="productFamilyName"
                                name="productFamilyName"
                                value={formData.productFamilyName}
                                onChange={handleChange}
                                disabled={isSubmitting}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="applicationName">Application</label>
                            <input
                                type="text"
                                id="applicationName"
                                name="applicationName"
                                value={formData.applicationName}
                                onChange={handleChange}
                                disabled={isSubmitting}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="componentName">Component</label>
                            <input
                                type="text"
                                id="componentName"
                                name="componentName"
                                value={formData.componentName}
                                onChange={handleChange}
                                disabled={isSubmitting}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="jiraProject">Jira Project</label>
                            <input
                                type="text"
                                id="jiraProject"
                                name="jiraProject"
                                value={formData.jiraProject}
                                onChange={handleChange}
                                disabled={isSubmitting}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="platformBase">Environment</label>
                            <select
                                id="platformBase"
                                name="platformBase"
                                value={formData.platformBase}
                                onChange={handleChange}
                                disabled={isSubmitting}
                            >
                                <option value="">Select Environment</option>
                                {ENVIRONMENT_OPTIONS.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="psArchitects">ProdSec Lead</label>
                            <input
                                type="text"
                                id="psArchitects"
                                name="psArchitects"
                                value={formData.psArchitects}
                                onChange={handleChange}
                                disabled={isSubmitting}
                                placeholder="Comma separated emails"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="productSecurityPM">ProdSec PM</label>
                            <input
                                type="text"
                                id="productSecurityPM"
                                name="productSecurityPM"
                                value={formData.productSecurityPM}
                                onChange={handleChange}
                                disabled={isSubmitting}
                                placeholder="Comma separated emails"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="productManager">Product Manager</label>
                            <input
                                type="text"
                                id="productManager"
                                name="productManager"
                                value={formData.productManager}
                                onChange={handleChange}
                                disabled={isSubmitting}
                                placeholder="Comma separated emails"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="devLeads">Dev Lead</label>
                            <input
                                type="text"
                                id="devLeads"
                                name="devLeads"
                                value={formData.devLeads}
                                onChange={handleChange}
                                disabled={isSubmitting}
                                placeholder="Comma separated emails"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="securityChampions">Security Champions</label>
                            <input
                                type="text"
                                id="securityChampions"
                                name="securityChampions"
                                value={formData.securityChampions}
                                onChange={handleChange}
                                disabled={isSubmitting}
                                placeholder="Comma separated emails"
                            />
                        </div>
                    </div>

                    {/* Progress section */}
                    {isSubmitting && (
                        <div className="progress-container">
                            <div className="progress-info">
                                <span>{processedAssets} out of {totalAssets} assets updated</span>
                                <span>{progress}%</span>
                            </div>
                            <div className="progress-bar">
                                <div
                                    className="progress-bar-fill"
                                    style={{ width: `${progress}%` }}
                                />
                            </div>
                        </div>
                    )}

                    {error && <div className="error-message"><MdError /> {error}</div>}
                    {successMessage && !isSubmitting && <div className="success-message"><MdCheck /> {successMessage}</div>}

                    <div className="button-group">
                        <button type="button" onClick={handleClose} className="cancel-btn" disabled={isSubmitting}>
                            <MdClose />
                            Cancel
                        </button>
                        <button type="submit" className="submit-btn" disabled={isSubmitting}>
                            {isSubmitting ? (
                                <>
                                    <Oval
                                        visible={true}
                                        height="14"
                                        width="14"
                                        color="#ffffff"
                                        secondaryColor="#ffffff"
                                        ariaLabel="loading"
                                    />
                                    Updating...
                                </>
                            ) : (
                                <>
                                    <MdCheck />
                                    Update Assets
                                </>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default BulkEditModal;