import axios from "axios";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import {VscError} from "react-icons/vsc";

import React from "react";

import { Box, MenuItem, Paper, Select, SelectChangeEvent, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Button } from "react-bootstrap";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { TabContext, TabPanel } from "@mui/lab";
import { useParams } from "react-router-dom";
import AnimatedShieldLoader from "../../shared/AnimatedShieldLoader";
import SectionCard from "../../AssetRecords/SectionCard";
import { AssetRecord } from "../../AssetRecords/AssetRecord";
import { ExceptionAuditFilter } from "./ExceptionAuditFilter";
import { ExceptionAuditDto } from "./ExceptionAuditDto";
import { ExceptionAuditType } from "./ExceptionAuditType";

const fetchData = async (startDate: Date, endDate: Date, userEmail:string, owner: string | undefined, exceptionName: string|null, canonicalId: string|null): Promise<ExceptionAuditDto[]> => {
    let filter: ExceptionAuditFilter = {
        startDate: startDate,
        endDate: endDate,
        userEmail: userEmail,
        owner: owner,
        entityId: undefined,
        assetId: undefined,
        exceptionName: exceptionName,
        assetCanonicalId: canonicalId
    }

    let response = await axios.post("/audit/build-exception", filter);

    return response.data
}

interface info{
    emails: string[];
    assets: AssetRecord[];
}

interface exceptionSummary{
    exceptionName: string;
    length: number;
    ownerName: string;
    assetId: string;
}

const fetchUsers = async (): Promise<info> => {
    let response = await axios.get("/UserInfo")

    var emails = response.data.map((user: any) => user.email)
    let assetResponse = await axios.get("/assets/all")
    var assets = assetResponse.data

    return {emails, assets};
}

export const ExceptionAuditLogs: React.FC = () => {
    const {
        data,
        isLoading,
        isSuccess
    } = useQuery(["get-users"], fetchUsers);

    const params = useParams<{ id: string }>();
    const currentDate = new Date();
    const initialStartDate = new Date(currentDate.setDate(currentDate.getDate() - 1));

    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(new Date());
    const [userEmail, setUserEmail] = useState<string>("_");
    const [ownerEmail, setOwnerEmail] = useState<string | undefined>("_");
    const [exceptionName, setExceptionName] = useState<string>("");
    const [canonicalId, setCanonicalId] = useState<string | null>(null);

    const [logs, setLogs] = useState<ExceptionAuditDto[]>([]);
    const [selectedTab, setSelectedTab] = useState(ExceptionAuditType.Crud);
    const [isDataLoad, setIsDataLoad] = useState(false);

    useEffect(() => {
        if(params.id) {
            getData();
        }
    // eslint-disable-next-line
    }, []);

    const getData = () => {
        setIsDataLoad(true);
        let email = userEmail === "_" ? "" : userEmail;
        let ownEmail = ownerEmail === "_" ? "" : ownerEmail;
        fetchData(startDate, endDate, email, ownEmail, exceptionName, canonicalId).then(
            (data) => {
                setLogs(data);
                setIsDataLoad(false);
            });
    }

    const handleUserChange = (event: SelectChangeEvent) => {
        setUserEmail(event.target.value);
    }

    const handleOwnerChange = (event: SelectChangeEvent) => {
        setOwnerEmail(event.target.value);
    }
    
    const getGroupedData = (data: ExceptionAuditDto[]): exceptionSummary[] => {
        const groupedData = data.reduce((acc: { [key: string]: ExceptionAuditDto[] }, item) => {
            const key = `${item.exceptionName}-${item.assetId}`; //`${item.logDate}-${item.user}-${item.owner}-${item.exceptionName}`;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(item);
            return acc;
        }, {});

        let result: exceptionSummary[] = [];

        for (const key in groupedData) {
            const group = groupedData[key];
            const assetId = group[0].assetId;
            const exceptionName = group[0].exceptionName;
            const ownerName = group[0].owner;
            const length = group.length;
            result.push({ exceptionName, ownerName, length, assetId });
        }

        return result;
    }

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });
    };

    const parseEmailToName = (email: string | null): string => {
        if (!email) return '';
        const namePart = email.split('@')[0];
        const parts = namePart.split(/[._]/).map(part =>
            part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
        );
        return parts.length >= 2 ? `${parts.slice(0, -1).join(' ')} ${parts.slice(-1)}` : parts[0] || '';
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    if (isLoading) {
        return <div id="loader">
            <AnimatedShieldLoader />
        </div>
    }

    if (isSuccess) {
        return (
            <>
            <div className="audit-logs">
                <div className="filter">
                    <SectionCard title="Exceptions Audit Logs">
                        <div className="info-grid" style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "10px" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    sx={{
                                        color: "white",
                                        '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(228, 219, 233, 0.25)',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(228, 219, 233, 0.25)',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(228, 219, 233, 0.25)',
                                        },
                                        '.MuiSvgIcon-root ': {
                                        fill: "inherit !important",
                                        },
                                        label: {color: "gray"},
                                    }}
                                    className="date-picker"
                                    label="Start Date"
                                    value={dayjs(startDate.toISOString().split('T')[0])}
                                    onChange={(value) => setStartDate(dayjs(value).toDate())}/>
                                <DatePicker
                                    sx={{
                                        color: "white",
                                        '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(228, 219, 233, 0.25)',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(228, 219, 233, 0.25)',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(228, 219, 233, 0.25)',
                                        },
                                        '.MuiSvgIcon-root ': {
                                        fill: "inherit !important",
                                        },
                                        label: {color: "gray"},
                                    }}
                                    className="date-picker"
                                    label="End Date"
                                    value={dayjs(endDate.toISOString().split('T')[0])}
                                    onChange={(value) => setEndDate(dayjs(value).toDate())}/>
                            </LocalizationProvider>
                            <Select
                                size="small"
                                label="Actor"

                                sx={{
                                    color: "white",
                                    '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '.MuiSvgIcon-root ': {
                                    fill: "white !important",
                                    }
                                }}
                                    id="demo-simple-select1"
                                    value={userEmail}
                                    //label="Actor"
                                    onChange={handleUserChange}
                                >
                                    <MenuItem value={"_"}>All Actors</MenuItem>
                                    {data?.emails.map((email: string) => <MenuItem key={email} value={email}>{parseEmailToName(email)}</MenuItem>)}
                            </Select>
                            <Select
                                size="small"
                                sx={{
                                    color: "white",
                                    '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '.MuiSvgIcon-root ': {
                                    fill: "white !important",
                                    }
                                }}
                                    id="demo-simple-select"
                                    value={ownerEmail}
                                    label="Owner"
                                    onChange={handleOwnerChange}
                                >
                                    <MenuItem value={"_"}>All Owners</MenuItem>
                                    {data?.emails.map((email: string) => <MenuItem key={email} value={email}>{parseEmailToName(email)}</MenuItem>)}
                            </Select>
                            <TextField label="Asset CanonicalId" variant="outlined"
                                onChange={(e) => setCanonicalId(e.target.value)}
                                // error={entityValidationMessage !== undefined}
                                // helperText={entityValidationMessage}
                                sx={{
                                    color: "white",
                                    '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '.MuiSvgIcon-root ': {
                                    fill: "white !important",
                                    },
                                    label: {color: "gray"},
                                }}
                                value={canonicalId ?? ""}
                            />
                            <TextField label="Exception name" variant="outlined"
                                onChange={(e) => setExceptionName(e.target.value)}
                                sx={{
                                    color: "white",
                                    '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '.MuiSvgIcon-root ': {
                                    fill: "white !important",
                                    },
                                    label: {color: "gray"},
                                }}
                            />
                            <Button style={{gridColumn: "3"}} onClick={getData}>Load data</Button>
                        </div>
                    </SectionCard>
                </div>

                <Box sx={{ width: '100%' }}>
                    <TabContext value={selectedTab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs textColor="inherit" value={selectedTab} onChange={handleTabChange} aria-label="action-tabs">
                                <Tab label="Audit" value={ExceptionAuditType.Crud}  />
                                <Tab label="Usage" value={ExceptionAuditType.Usage}  />
                                <Tab label="Usage summary" value={-1}  />
                            </Tabs>
                        </Box>
                        {isDataLoad === true && 
                        <div id="table-loader">
                            <AnimatedShieldLoader />
                        </div>}
                        {isDataLoad === false && <>
                        <TabPanel value={ExceptionAuditType.Crud}>
                            <TableContainer component={Paper} className="application-table-paper">
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Actor</TableCell>
                                            <TableCell>Owner</TableCell>
                                            <TableCell>Exception Name</TableCell>
                                            <TableCell>Asset</TableCell>
                                            <TableCell>Message</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {logs.filter(x => ExceptionAuditType[ExceptionAuditType[x.logType]] === ExceptionAuditType[ExceptionAuditType.Crud]).map((x, index) => 
                                        <TableRow key={index}>
                                            <TableCell>{formatDate(x.logDate.toString())}</TableCell>
                                            <TableCell>{parseEmailToName(x.user)}</TableCell>
                                            <TableCell>{parseEmailToName(x.owner)}</TableCell>
                                            <TableCell>{x.exceptionName}</TableCell>
                                            <TableCell>{data?.assets.find(a => a.id === x.assetId)?.canonicalId}</TableCell>
                                            <TableCell>{x.eventMessage}</TableCell>
                                            {/* <TableCell>{prepareAssets(x.assets)}</TableCell> */}
                                        </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                        <TabPanel value={ExceptionAuditType.Usage}>
                            <TableContainer component={Paper} className="application-table-paper">
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Owner</TableCell>
                                            <TableCell>Exception name</TableCell>
                                            <TableCell>Asset</TableCell>
                                            <TableCell>Message</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {logs.filter(x => ExceptionAuditType[ExceptionAuditType[x.logType]] === ExceptionAuditType[ExceptionAuditType.Usage]).map((x, index) => 
                                        <TableRow key={index}>
                                            <TableCell>{formatDate(x.logDate.toString())}</TableCell>
                                            <TableCell>{parseEmailToName(x.owner)}</TableCell>
                                            <TableCell>{x.exceptionName}</TableCell>
                                            <TableCell>{data?.assets.find(a => a.id === x.assetId)?.canonicalId}</TableCell>
                                            <TableCell>{x.eventMessage}</TableCell>

                                            {/* <TableCell>{data?.assets.find(a => a.id === x.actionOnAsset)?.canonicalId}</TableCell> */}
                                        </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                        <TabPanel value={-1}>
                            <TableContainer component={Paper} className="application-table-paper">
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Exception name</TableCell>
                                            <TableCell>Owner</TableCell>
                                            <TableCell>Asset</TableCell>
                                            <TableCell>Count</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {getGroupedData(logs.filter(x => ExceptionAuditType[ExceptionAuditType[x.logType]] === ExceptionAuditType[ExceptionAuditType.Usage])).map((x, index) => 
                                        <TableRow key={index}>
                                            <TableCell>{x.exceptionName}</TableCell>
                                            <TableCell>{parseEmailToName(x.ownerName)}</TableCell>
                                            <TableCell>{data?.assets.find(a => a.id === x.assetId)?.canonicalId}</TableCell>
                                            <TableCell>{x.length}</TableCell>

                                            {/* <TableCell>{data?.assets.find(a => a.id === x.actionOnAsset)?.canonicalId}</TableCell> */}
                                        </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                        </>}
                    </TabContext>
                </Box>
            </div>
            </>
        )
    }

    return <><VscError /> Failed to load, please, check console</>
}
