import React from 'react';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
    ResponsiveContainer, BarChart, Bar, AreaChart, Area, PieChart, Pie, Cell,
} from 'recharts';
import { LoadingCardContent } from '../shared/LoadingCardContent';
import { DynamicChartProps, COLORS } from './types';
import './ReportingDashboard.scss';

interface CustomTooltipProps {
    active?: boolean;
    payload?: any[];
    label?: string;
    keyLabels?: Record<string, string>;
}

const CustomBarLabel = (props: any): React.ReactElement<SVGElement> => {
    const { x, y, width, value } = props;

    if (typeof x === 'undefined' || typeof y === 'undefined' ||
        typeof width === 'undefined' || typeof value === 'undefined') {
        return <g />;
    }

    const formattedValue = value >= 1000 ? `${(value / 1000).toFixed(1)}k` : value;
    return (
        <text
            x={x + width / 2}
            y={y - 10}
            className="bar-label"
            textAnchor="middle"
            fontSize="12"
            fontWeight="600"
        >
            {formattedValue}
        </text>
    );
};

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label, keyLabels }) => {
    if (active && payload && payload.length) {
        const isRemediationChart = payload.some((entry: any) =>
            ['criticalTime', 'highTime', 'mediumTime', 'lowTime'].includes(entry.dataKey)
        );

        return (
            <div className="custom-tooltip">
                <p className="tooltip-label">{`Date: ${label?.split('T')[0]}`}</p>
                {payload.map((entry: any, index: number) => (
                    <p key={index} style={{ color: entry.color }}>
                        {`${keyLabels?.[entry.dataKey] || entry.name}: ${entry.value}${isRemediationChart ? ' days' : ''}`}
                    </p>
                ))}
            </div>
        );
    }
    return null;
};

export const DynamicChart: React.FC<DynamicChartProps> = ({
    data,
    config,
    isLoading,
    dataKeys,
    keyLabels = {},
    colors = [COLORS.primary, COLORS.danger]
}) => {
    const chartProps = {
        data,
        margin: { top: 40, right: 30, left: 20, bottom: 20 }
    };

    const renderChart = () => {
        const formatXAxis = (timeStamp: string) => {
            if (!timeStamp) return '';
            return timeStamp.split('T')[0];
        };

        const commonAxisProps = {
            xAxis: {
                dataKey: "timeStamp",
                angle: -45,
                textAnchor: "end",
                height: 80,
                tickFormatter: formatXAxis,
                interval: "preserveEnd" as const,
                dx: -10,
            },
            margin: {
                top: 40,
                right: 30,
                left: 20,
                bottom: 50
            }
        };

        const getYAxisDomain = (): [number, string | number] => {
            const maxValue = Math.max(...data.flatMap(item =>
                dataKeys.map(key => item[key] || 0)
            ));
            return [0, Math.ceil(maxValue * 1.2)];
        };

        switch (config.chartType) {
            case 'line':
                const getLineYAxisDomain = (): [number, string | number] => {
                    // Special handling for 'open' key to adjust the Y-axis range
                    if (dataKeys.length === 1 && dataKeys[0] === 'open') {
                        const openValues = data.map(item => item.open || 0);
                        const min = Math.min(...openValues);
                        const max = Math.max(...openValues);

                        return [
                            Math.floor(Math.max(0, min - (max - min) * 0.1)),
                            Math.ceil(max + (max - min) * 0.1)
                        ];
                    }
                    
                    // Default behavior for other cases
                    const maxValue = Math.max(...data.flatMap(item =>
                        dataKeys.map(key => item[key] || 0)
                    ));
                    return [0, Math.ceil(maxValue * 1.2)];
                };

                return (
                    <LineChart {...chartProps} margin={commonAxisProps.margin}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis {...commonAxisProps.xAxis} />
                        <YAxis 
                            domain={getLineYAxisDomain()} 
                            tickFormatter={(value) => Math.round(value).toString()}
                        />
                        <Tooltip content={(props) => <CustomTooltip {...props} keyLabels={keyLabels} />} />
                        <Legend verticalAlign="top" height={36} />
                        {dataKeys.map((key, index) => (
                            <Line
                                key={key}
                                type="monotone"
                                dataKey={key}
                                stroke={colors[index % colors.length]}
                                name={keyLabels?.[key] || key.charAt(0).toUpperCase() + key.slice(1)}
                                dot={{ r: 4 }}
                                strokeWidth={2}
                                activeDot={{ r: 8 }}
                            />
                        ))}
                    </LineChart>
                );
            case 'bar':
                return (
                    <BarChart {...chartProps} margin={commonAxisProps.margin}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis {...commonAxisProps.xAxis} />
                        <YAxis domain={getYAxisDomain()} />
                        <Tooltip content={(props) => <CustomTooltip {...props} keyLabels={keyLabels} />} />
                        <Legend verticalAlign="top" height={36} />
                        {dataKeys.map((key, index) => (
                            <Bar
                                key={key}
                                dataKey={key}
                                fill={colors[index % colors.length]}
                                name={keyLabels?.[key] || key.charAt(0).toUpperCase() + key.slice(1)}
                                label={CustomBarLabel}
                            />
                        ))}
                    </BarChart>
                );

            case 'area':
                return (
                    <AreaChart {...chartProps}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis {...commonAxisProps.xAxis} />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend />
                        {dataKeys.map((key, index) => (
                            <Area
                                key={key}
                                type="monotone"
                                dataKey={key}
                                fill={colors[index % colors.length]}
                                stroke={colors[index % colors.length]}
                                name={keyLabels?.[key] || key.charAt(0).toUpperCase() + key.slice(1)}
                            />
                        ))}
                    </AreaChart>
                );

            case 'pie':
            default:
                // For reduction goal chart, use data directly with scanType
                if (data[0]?.scanType) {
                    interface ReductionTooltipProps {
                        active?: boolean;
                        payload?: any[];
                    }

                    const CustomReductionTooltip: React.FC<ReductionTooltipProps> = ({ active, payload }) => {
                        if (active && payload && payload.length) {
                            return (
                                <div className="custom-tooltip">
                                    <p style={{ color: payload[0].color }}>
                                        {`${payload[0].name}: ${payload[0].value} weeks`}
                                    </p>
                                </div>
                            );
                        }
                        return null;
                    };

                    return (
                        <PieChart>
                            <Pie
                                data={data}
                                innerRadius={60}
                                outerRadius={100}
                                dataKey="weeksCount"
                                nameKey="scanType"
                            >
                                {data.map((_, index) => (
                                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                ))}
                            </Pie>
                            <Tooltip content={<CustomReductionTooltip />} />
                            <Legend />
                        </PieChart>
                    );
                }

                // For other pie charts, aggregate the data
                const pieData = dataKeys.map(key => ({
                    name: keyLabels?.[key] || key.charAt(0).toUpperCase() + key.slice(1),
                    value: data.reduce((acc: number, curr: any) => acc + (curr[key] || 0), 0)
                }));

                return (
                    <PieChart>
                        <Pie
                            data={pieData}
                            innerRadius={60}
                            outerRadius={100}
                            paddingAngle={5}
                            dataKey="value"
                            nameKey="name"
                        >
                            {pieData.map((_, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                            ))}
                        </Pie>
                        <Tooltip content={<CustomTooltip />} />
                        <Legend />
                    </PieChart>
                );
        }
    };

    if (isLoading) {
        return <LoadingCardContent />;
    }

    return (
        <ResponsiveContainer height={350}>
            {renderChart()}
        </ResponsiveContainer>
    );
};

export default DynamicChart;