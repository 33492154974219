import React, { useState, useEffect } from 'react';
import { BsBarChartLine } from 'react-icons/bs';
import { MdOutlineFilterList } from "react-icons/md";
import { DynamicChart } from './DynamicChart';
import { ChartFilters } from './ChartFilters';
import FilterTags from './FilterTags';
import { TimeGranularity, VulnerabilityData, ChartMetricUpdate, COLORS } from './types';
import { MultiselectOption } from '../shared/Multiselect/MultiselectOption';
import './ReportingDashboard.scss';

const ALL_SCAN_TYPES = ['SAST', 'DAST', 'SCA', 'Pentest', 'Bug Bounty', 'Secure SDLC'];

interface DynamicVulnerabilityChartProps {
    data: VulnerabilityData[];
    isLoading: boolean;
    selectedNames?: string[];
    selectedScale: string;
    selectedDateRange: { startDate: Date; endDate: Date };
    selectedNameType: string;
    applications: MultiselectOption[];
    productFamilies: MultiselectOption[];
    psArchitects: MultiselectOption[];
    securityChampions: MultiselectOption[];
    devLeads: MultiselectOption[];
    productSecurityPM: MultiselectOption[];
    productManager: MultiselectOption[];
    tags: MultiselectOption[];
    appChanged: (selected: MultiselectOption[]) => Promise<void>;
    appFamilyChanged: (selected: MultiselectOption[]) => Promise<void>;
    psArchitectsChanged: (selected: MultiselectOption[]) => Promise<void>;
    securityChampionsChanged: (selected: MultiselectOption[]) => Promise<void>;
    devLeadsChanged: (selected: MultiselectOption[]) => Promise<void>;
    productSecurityPMChanged: (selected: MultiselectOption[]) => Promise<void>;
    productManagerChanged: (selected: MultiselectOption[]) => Promise<void>;
    tagsChanged: (selected: MultiselectOption[]) => Promise<void>;
    scaleChanged: (scale: string) => Promise<void>;
    dateRangeChanged: (startDate: Date, endDate: Date) => Promise<void>;
    onDataUpdate?: (update: ChartMetricUpdate) => void;
}

const processVulnerabilityData = (rawData: any[], selectedScanTypes: string[]): VulnerabilityData[] => {
    if (!rawData) return [];

    const normalizeDate = (timestamp: string) => {
        return new Date(timestamp).toISOString().split('T')[0];
    };

    let relevantData = selectedScanTypes.flatMap(scanType => {
        const scanData = rawData.find(item =>
            item.scanType?.toUpperCase() === scanType.toUpperCase()
        );
        return scanData?.statusCountsData || [];
    });

    const dataByTimestamp = new Map();
    relevantData.forEach(item => {
        const normalizedDate = normalizeDate(item.timeStamp);
        if (!dataByTimestamp.has(normalizedDate)) {
            dataByTimestamp.set(normalizedDate, {
                timeStamp: normalizedDate,
                open: 0,
                inProgress: 0,
                blocked: 0,
                created: 0,
                ResolvedIssuesCount: 0
            });
        }

        const existing = dataByTimestamp.get(normalizedDate);
        existing.open += Math.ceil(item.open || 0);
        existing.inProgress += Math.ceil(item.inProgress || 0);
        existing.blocked += Math.ceil(item.blocked || 0);
        existing.created += Math.ceil(item.created || 0);
        existing.ResolvedIssuesCount += Math.ceil(item.ResolvedIssuesCount || item.closed || 0);
    });

    return Array.from(dataByTimestamp.values())
        .sort((a, b) => new Date(a.timeStamp).getTime() - new Date(b.timeStamp).getTime());
};

export const DynamicVulnerabilityChart: React.FC<DynamicVulnerabilityChartProps> = ({
    data,
    isLoading,
    selectedNames,
    selectedScale,
    selectedDateRange,
    selectedNameType,
    applications,
    productFamilies,
    psArchitects,
    securityChampions,
    devLeads,
    productSecurityPM,
    productManager,
    tags,
    appChanged,
    appFamilyChanged,
    psArchitectsChanged,
    securityChampionsChanged,
    devLeadsChanged,
    productSecurityPMChanged,
    productManagerChanged,
    tagsChanged,
    scaleChanged,
    dateRangeChanged,
    onDataUpdate
}) => {
    const [activeFilter, setActiveFilter] = useState(false);
    const [selectedScanTypes, setSelectedScanTypes] = useState<string[]>(ALL_SCAN_TYPES);
    const [processedData, setProcessedData] = useState<VulnerabilityData[]>([]);
    const [processedStatusData, setProcessedStatusData] = useState<VulnerabilityData[]>([]);

    useEffect(() => {
        const processed = processVulnerabilityData(data, selectedScanTypes);

        // Use the same processed data for both charts
        setProcessedData(processed);
        setProcessedStatusData(processed);

        onDataUpdate?.({
            data: processed,
            timeGranularity: selectedScale as TimeGranularity
        });
    }, [data, selectedScanTypes, selectedScale, onDataUpdate]);

    const handleScanTypeChange = async (selected: MultiselectOption[]) => {
        const scanTypes = selected.length === 0
            ? ALL_SCAN_TYPES
            : selected.map(option => option.value);
        setSelectedScanTypes(scanTypes);
    };

    return (
        <div className="reporting-dashboard__chart-cell col-span-2">
            <div className="chart-header">
                <div className="chart-title">
                    <BsBarChartLine size={18} />
                    <h5>Vulnerability Trends</h5>
                    <FilterTags
                        selectedFilters={{
                            scale: selectedScale,
                            dateRange: selectedDateRange,
                            scanTypes: selectedScanTypes
                        }}
                    />
                </div>
                <button
                    className="filter-button"
                    onClick={() => setActiveFilter(!activeFilter)}
                >
                    <MdOutlineFilterList size={18} /> Filters
                </button>
            </div>

            <div className="vulnerability-analysis-container">
                {/* First Chart - Open Vulnerabilities */}
                <div className="chart-section">
                    <div className="chart-container">
                        <DynamicChart
                            data={processedData}
                            config={{
                                dateRange: selectedDateRange,
                                chartType: 'line',
                                granularity: selectedScale as TimeGranularity,
                                nameType: selectedNameType,
                                selectedNames: selectedNames || []
                            }}
                            isLoading={isLoading}
                            dataKeys={['open']}
                            keyLabels={{
                                open: 'Open'
                            }}
                            colors={[COLORS.info]}
                        />
                    </div>
                </div>

                {/* Second Chart - Vulnerability Status */}
                <div className="chart-section">
                    <div className="chart-container">
                        <DynamicChart
                            data={processedStatusData}
                            config={{
                                dateRange: selectedDateRange,
                                chartType: 'bar',
                                granularity: selectedScale as TimeGranularity,
                                nameType: selectedNameType,
                                selectedNames: selectedNames || []
                            }}
                            isLoading={isLoading}
                            dataKeys={['created', 'inProgress', 'blocked']}
                            keyLabels={{
                                created: 'Created',
                                inProgress: 'In Progress',
                                blocked: 'Blocked'
                            }}
                            colors={[COLORS.info, COLORS.warning, COLORS.danger]}
                        />
                    </div>
                </div>
            </div>

            <ChartFilters
                isOpen={activeFilter}
                onClose={() => setActiveFilter(false)}
                onApplyFilters={() => setActiveFilter(false)}
                applications={applications}
                productFamilies={productFamilies}
                psArchitects={psArchitects}
                securityChampions={securityChampions}
                devLeads={devLeads}
                productSecurityPM={productSecurityPM}
                productManager={productManager}
                tags={tags}
                chartId="vulnerability"
                defaultValues={{
                    startDate: selectedDateRange.startDate,
                    endDate: selectedDateRange.endDate,
                    scale: selectedScale,
                    appFamilyFilter: selectedNameType,
                    selectedScanTypes: selectedScanTypes
                }}
                callbacks={{
                    applicationChangedCallback: appChanged,
                    productFamilyChangedCallback: appFamilyChanged,
                    psArchitectsCallback: psArchitectsChanged,
                    securityChampionsCallback: securityChampionsChanged,
                    devLeadsCallback: devLeadsChanged,
                    productSecurityPMCallback: productSecurityPMChanged,
                    productManagerCallback: productManagerChanged,
                    tagsCallback: tagsChanged,
                    scaleChangedCallback: scaleChanged,
                    dateRangeChangedCallback: dateRangeChanged,
                    scanTypeCallback: handleScanTypeChange
                }}
            />
        </div>
    );
};

export default DynamicVulnerabilityChart;