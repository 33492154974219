import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { BsExclamationTriangle, BsCheckCircle, BsClockHistory, BsPieChart } from 'react-icons/bs';
import axios from 'axios';
import { addDays } from "date-fns";
import { MetricCard } from './MetricCard';
import { DynamicVulnerabilityChart } from './DynamicVulnerabilityChart';
import { DynamicIntroducedChart } from './DynamicIntroducedChart';
import { DynamicRemediationChart } from './DynamicRemediationChart';
import { DynamicRatioChart } from './DynamicRatioChart';
import { DynamicReductionChart } from './DynamicReductionChart';
import { DynamicCsvExport } from './DynamicCsvExport';
import {
    DashboardMetrics,
    ChartMetricUpdate,
    TimeGranularity,
    RatioData,
    VulnerabilityData,
    IntroducedData,
    RemediationData,
    ReductionGoalData,
    ChartId
} from './types';
import { MultiselectOption } from '../shared/Multiselect/MultiselectOption';
import { JiraStatisticScale, JiraStatisticAppFamilyFilter } from '../shared/JiraStatisticFilterConstants';
import './ReportingDashboard.scss';

const createEmailNameMapping = (options: MultiselectOption[]) => {
    const mapping: Record<string, MultiselectOption[]> = {};

    options.forEach(option => {
        const name = option.value.split('@')[0].toLowerCase();

        if (!mapping[name]) {
            mapping[name] = [];
        }

        mapping[name].push(option);
    });

    return mapping;
};

// De-duplicate options for UI display, preferring dayforce.com emails
const getUniqueDisplayOptions = (options: MultiselectOption[]) => {
    const nameMapping = createEmailNameMapping(options);

    return Object.values(nameMapping).map(relatedOptions => {
        const dayforceOption = relatedOptions.find(
            option => option.value.toLowerCase().includes('dayforce.com')
        );

        return dayforceOption || relatedOptions[0];
    });
};

// When a selection happens, expand it to include all related emails
const expandSelection = (
    selected: MultiselectOption[],
    allOptions: MultiselectOption[]
): MultiselectOption[] => {
    const nameMapping = createEmailNameMapping(allOptions);

    return selected.flatMap(option => {
        const name = option.value.split('@')[0].toLowerCase();
        return nameMapping[name] || [option];
    });
};

const getDefaultEndDate = (scale: string): Date => {
    const now = new Date();
    switch (scale) {
        case 'day':
            return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
        case 'week':
            const dayOfWeek = now.getDay();
            const diff = dayOfWeek + (dayOfWeek === 0 ? 7 : 0);
            return new Date(now.getFullYear(), now.getMonth(), now.getDate() - diff);
        case 'month':
            return new Date(now.getFullYear(), now.getMonth(), 0);
        default:
            return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
    }
};

interface ChartFilterParams {
    names: string[];
    scale: string;
    dateRange: {
        startDate: Date;
        endDate: Date;
    };
    nameType: string;
    selectedScanTypes?: string[];
}

export const ReportingDashboard: React.FC = () => {
    const isMounted = useRef(true);

    // Individual fetch controllers for each chart
    const fetchControllersRef = useRef<Record<ChartId, AbortController | null>>({
        vulnerability: null,
        vulnerabilityStatus: null,
        introduced: null,
        remediation: null,
        ratio: null,
        reduction: null,
        csv: null
    });

    // State for filter options
    const [applications, setApplications] = useState<MultiselectOption[]>([]);
    const [productFamilies, setProductFamilies] = useState<MultiselectOption[]>([]);
    const [psArchitects, setPsArchitects] = useState<MultiselectOption[]>([]);
    const [securityChampions, setSecurityChampions] = useState<MultiselectOption[]>([]);
    const [devLeads, setDevLeads] = useState<MultiselectOption[]>([]);
    const [productSecurityPM, setProductSecurityPM] = useState<MultiselectOption[]>([]);
    const [productManager, setProductManager] = useState<MultiselectOption[]>([]);
    const [tags, setTags] = useState<MultiselectOption[]>([]);

    // State for chart data
    const [vulnerabilityOpenData, setVulnerabilityOpenData] = useState<VulnerabilityData[]>([]);
    const [introducedData, setIntroducedData] = useState<IntroducedData[]>([]);
    const [remediationData, setRemediationData] = useState<RemediationData[]>([]);
    const [reductionData, setReductionData] = useState<ReductionGoalData[]>([]);
    const [ratioData, setRatioData] = useState<RatioData[]>([]);

    // Individual loading states for each chart
    const [chartLoadingStates, setChartLoadingStates] = useState<Record<ChartId, boolean>>({
        vulnerability: false,
        vulnerabilityStatus: false,
        introduced: false,
        remediation: false,
        ratio: false,
        reduction: false,
        csv: false
    });

    // State for metrics
    const [metrics, setMetrics] = useState<DashboardMetrics>({
        openIssues: 0,
        resolvedIssues: 0,
        remediationTime: 0,
        resolutionRatio: 0
    });

    const [metricsGranularity, setMetricsGranularity] = useState({
        vulnerability: TimeGranularity.Week,
        remediation: TimeGranularity.Week,
        ratio: TimeGranularity.Week
    });

    // Default filter params using useMemo to avoid unnecessary recreations
    const defaultFilterParams = useMemo(() => ({
        names: [],
        scale: JiraStatisticScale.Week,
        dateRange: {
            startDate: addDays(getDefaultEndDate(JiraStatisticScale.Week), -30),
            endDate: getDefaultEndDate(JiraStatisticScale.Week)
        },
        nameType: JiraStatisticAppFamilyFilter.ProductFamily
    }), []);

    // Individual filter params for each chart
    const [chartFilterParams, setChartFilterParams] = useState<Record<ChartId, ChartFilterParams>>({
        vulnerability: { ...defaultFilterParams },
        vulnerabilityStatus: { ...defaultFilterParams },
        introduced: { ...defaultFilterParams },
        remediation: { ...defaultFilterParams },
        ratio: { ...defaultFilterParams },
        reduction: { ...defaultFilterParams },
        csv: { ...defaultFilterParams }
    });

    // Format date for API calls consistently
    const formatDateForApi = useCallback((date: Date) => {
        const d = new Date(date);
        d.setHours(12, 0, 0, 0);
        return d.toISOString();
    }, []);

    // Individual fetch functions for each chart
    const fetchVulnerabilityData = useCallback(async () => {
        const params = chartFilterParams.vulnerability;
        if (!params.names.length) {
            setChartLoadingStates(prev => ({ ...prev, vulnerability: false }));
            return;
        }

        if (fetchControllersRef.current.vulnerability) {
            fetchControllersRef.current.vulnerability.abort();
        }
        fetchControllersRef.current.vulnerability = new AbortController();

        setChartLoadingStates(prev => ({ ...prev, vulnerability: true }));

        try {
            const response = await axios.post('/jirastatistic/getVulnerabilityRemediationExpanded', {
                scale: params.scale,
                startDate: formatDateForApi(params.dateRange.startDate),
                endDate: formatDateForApi(params.dateRange.endDate),
                nameType: params.nameType,
                selectedNames: params.names
            }, { signal: fetchControllersRef.current.vulnerability?.signal });

            if (isMounted.current) {
                setVulnerabilityOpenData(response.data);
            }
        } catch (error) {
            if (!axios.isCancel(error)) {
                console.error('Error fetching vulnerability data:', error);
            }
        } finally {
            if (isMounted.current) {
                setChartLoadingStates(prev => ({ ...prev, vulnerability: false }));
            }
        }
    }, [chartFilterParams.vulnerability, formatDateForApi]);

    const fetchIntroducedData = useCallback(async () => {
        const params = chartFilterParams.introduced;
        if (!params.names.length) {
            setChartLoadingStates(prev => ({ ...prev, introduced: false }));
            return;
        }

        if (fetchControllersRef.current.introduced) {
            fetchControllersRef.current.introduced.abort();
        }
        fetchControllersRef.current.introduced = new AbortController();

        setChartLoadingStates(prev => ({ ...prev, introduced: true }));

        try {
            const response = await axios.post('/jirastatistic/getIntroduced', {
                scale: params.scale,
                startDate: formatDateForApi(params.dateRange.startDate),
                endDate: formatDateForApi(params.dateRange.endDate),
                nameType: params.nameType,
                selectedNames: params.names
            }, { signal: fetchControllersRef.current.introduced?.signal });

            if (isMounted.current) {
                setIntroducedData(response.data);
            }
        } catch (error) {
            if (!axios.isCancel(error)) {
                console.error('Error fetching introduced data:', error);
            }
        } finally {
            if (isMounted.current) {
                setChartLoadingStates(prev => ({ ...prev, introduced: false }));
            }
        }
    }, [chartFilterParams.introduced, formatDateForApi]);

    const fetchRemediationData = useCallback(async () => {
        const params = chartFilterParams.remediation;
        if (!params.names.length) {
            setChartLoadingStates(prev => ({ ...prev, remediation: false }));
            return;
        }

        if (fetchControllersRef.current.remediation) {
            fetchControllersRef.current.remediation.abort();
        }
        fetchControllersRef.current.remediation = new AbortController();

        setChartLoadingStates(prev => ({ ...prev, remediation: true }));

        try {
            const response = await axios.post('/jirastatistic/getRemediation', {
                scale: params.scale,
                startDate: formatDateForApi(params.dateRange.startDate),
                endDate: formatDateForApi(params.dateRange.endDate),
                nameType: params.nameType,
                selectedNames: params.names
            }, { signal: fetchControllersRef.current.remediation?.signal });

            if (isMounted.current) {
                setRemediationData(response.data);
            }
        } catch (error) {
            if (!axios.isCancel(error)) {
                console.error('Error fetching remediation data:', error);
            }
        } finally {
            if (isMounted.current) {
                setChartLoadingStates(prev => ({ ...prev, remediation: false }));
            }
        }
    }, [chartFilterParams.remediation, formatDateForApi]);

    const fetchRatioData = useCallback(async () => {
        const params = chartFilterParams.ratio;
        if (!params.names.length) {
            setChartLoadingStates(prev => ({ ...prev, ratio: false }));
            return;
        }

        if (fetchControllersRef.current.ratio) {
            fetchControllersRef.current.ratio.abort();
        }
        fetchControllersRef.current.ratio = new AbortController();

        setChartLoadingStates(prev => ({ ...prev, ratio: true }));

        try {
            const response = await axios.post('/jirastatistic/getRatioOfIntroducedResolved', {
                scale: params.scale,
                startDate: formatDateForApi(params.dateRange.startDate),
                endDate: formatDateForApi(params.dateRange.endDate),
                nameType: params.nameType,
                selectedNames: params.names
            }, { signal: fetchControllersRef.current.ratio?.signal });

            if (isMounted.current) {
                setRatioData(response.data);
            }
        } catch (error) {
            if (!axios.isCancel(error)) {
                console.error('Error fetching ratio data:', error);
            }
        } finally {
            if (isMounted.current) {
                setChartLoadingStates(prev => ({ ...prev, ratio: false }));
            }
        }
    }, [chartFilterParams.ratio, formatDateForApi]);

    const fetchReductionData = useCallback(async () => {
        const params = chartFilterParams.reduction;
        if (!params.names.length) {
            setChartLoadingStates(prev => ({ ...prev, reduction: false }));
            return;
        }

        if (fetchControllersRef.current.reduction) {
            fetchControllersRef.current.reduction.abort();
        }
        fetchControllersRef.current.reduction = new AbortController();

        setChartLoadingStates(prev => ({ ...prev, reduction: true }));

        try {
            const response = await axios.post('/jirastatistic/getReductionGoal', {
                scale: params.scale,
                startDate: formatDateForApi(params.dateRange.startDate),
                endDate: formatDateForApi(params.dateRange.endDate),
                nameType: params.nameType,
                selectedNames: params.names
            }, { signal: fetchControllersRef.current.reduction?.signal });

            if (isMounted.current) {
                setReductionData(response.data);
            }
        } catch (error) {
            if (!axios.isCancel(error)) {
                console.error('Error fetching reduction data:', error);
            }
        } finally {
            if (isMounted.current) {
                setChartLoadingStates(prev => ({ ...prev, reduction: false }));
            }
        }
    }, [chartFilterParams.reduction, formatDateForApi]);

    // Update filter params for a specific chart
    const updateChartFilters = useCallback((chartId: ChartId, newParams: Partial<ChartFilterParams>) => {
        setChartFilterParams(prev => ({
            ...prev,
            [chartId]: {
                ...prev[chartId],
                ...newParams
            }
        }));
    }, []);

    // Effects to fetch data when chart filter params change
    useEffect(() => {
        fetchVulnerabilityData();
    }, [fetchVulnerabilityData]);

    useEffect(() => {
        fetchIntroducedData();
    }, [fetchIntroducedData]);

    useEffect(() => {
        fetchRemediationData();
    }, [fetchRemediationData]);

    useEffect(() => {
        fetchRatioData();
    }, [fetchRatioData]);

    useEffect(() => {
        fetchReductionData();
    }, [fetchReductionData]);

    useEffect(() => {
        if (chartFilterParams.csv.names.length > 0 && chartLoadingStates.csv) {
            setChartLoadingStates(prev => ({
                ...prev,
                csv: false
            }));
        }
    }, [chartFilterParams.csv.names, chartLoadingStates.csv]);

    const createFilterCallbacks = useCallback((chartId: ChartId) => {
        return {
            appChanged: async (selected: MultiselectOption[]) => {
                updateChartFilters(chartId, {
                    names: selected.map(x => x.value),
                    nameType: JiraStatisticAppFamilyFilter.Application
                });
            },
            appFamilyChanged: async (selected: MultiselectOption[]) => {
                updateChartFilters(chartId, {
                    names: selected.map(x => x.value),
                    nameType: JiraStatisticAppFamilyFilter.ProductFamily
                });
            },
            psArchitectsChanged: async (selected: MultiselectOption[]) => {
                const expandedSelection = expandSelection(selected, psArchitects);
                updateChartFilters(chartId, {
                    names: expandedSelection.map(x => x.value),
                    nameType: JiraStatisticAppFamilyFilter.PsArchitects
                });
            },
            securityChampionsChanged: async (selected: MultiselectOption[]) => {
                const expandedSelection = expandSelection(selected, securityChampions);
                updateChartFilters(chartId, {
                    names: expandedSelection.map(x => x.value),
                    nameType: JiraStatisticAppFamilyFilter.SecurityChampions
                });
            },
            devLeadsChanged: async (selected: MultiselectOption[]) => {
                const expandedSelection = expandSelection(selected, devLeads);
                updateChartFilters(chartId, {
                    names: expandedSelection.map(x => x.value),
                    nameType: JiraStatisticAppFamilyFilter.DevLeads
                });
            },
            productSecurityPMChanged: async (selected: MultiselectOption[]) => {
                const expandedSelection = expandSelection(selected, productSecurityPM);
                updateChartFilters(chartId, {
                    names: expandedSelection.map(x => x.value),
                    nameType: JiraStatisticAppFamilyFilter.ProductSecurityPM
                });
            },
            productManagerChanged: async (selected: MultiselectOption[]) => {
                const expandedSelection = expandSelection(selected, productManager);
                updateChartFilters(chartId, {
                    names: expandedSelection.map(x => x.value),
                    nameType: JiraStatisticAppFamilyFilter.ProductManager
                });
            },
            tagsChanged: async (selected: MultiselectOption[]) => {
                updateChartFilters(chartId, {
                    names: selected.map(x => x.value),
                    nameType: JiraStatisticAppFamilyFilter.Tags
                });
            },
            scaleChanged: async (scale: string) => {
                updateChartFilters(chartId, { scale });
            },
            dateRangeChanged: async (startDate: Date, endDate: Date) => {
                updateChartFilters(chartId, {
                    dateRange: { startDate, endDate }
                });
            },
            scanTypeCallback: (chartId === 'vulnerability' || chartId === 'vulnerabilityStatus') ?
                async (selected: MultiselectOption[]) => {
                    updateChartFilters(chartId, {
                        selectedScanTypes: selected.map(option => option.value)
                    });
                } : undefined
        };
    }, [updateChartFilters, psArchitects, securityChampions, devLeads, productSecurityPM, productManager]);

    // Load initial data
    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                // Set all charts to loading
                setChartLoadingStates({
                    vulnerability: true,
                    vulnerabilityStatus: true,
                    introduced: true,
                    remediation: true,
                    ratio: true,
                    reduction: true,
                    csv: true
                });

                const response = await axios.get('/assets/app-and-families');

                setApplications(response.data.applicationNames);
                setProductFamilies(response.data.families);
                setPsArchitects(response.data.psArchitects);
                setSecurityChampions(response.data.securityChampions);
                setDevLeads(response.data.devLeads);
                setProductSecurityPM(response.data.productSecurityPM);
                setProductManager(response.data.productManager);
                setTags(response.data.tags);

                if (response.data.families?.length > 0) {
                    const initialNames = response.data.families.map((f: MultiselectOption) => f.value);

                    // Update all chart filter params with initial data
                    const initialParams = {
                        names: initialNames,
                        nameType: JiraStatisticAppFamilyFilter.ProductFamily
                    };

                    setChartFilterParams({
                        vulnerability: { ...defaultFilterParams, ...initialParams },
                        vulnerabilityStatus: { ...defaultFilterParams, ...initialParams },
                        introduced: { ...defaultFilterParams, ...initialParams },
                        remediation: { ...defaultFilterParams, ...initialParams },
                        ratio: { ...defaultFilterParams, ...initialParams },
                        reduction: { ...defaultFilterParams, ...initialParams },
                        csv: { ...defaultFilterParams, ...initialParams }
                    });
                }
            } catch (error) {
                console.error('Error fetching initial data:', error);
                // Reset loading states
                setChartLoadingStates({
                    vulnerability: false,
                    vulnerabilityStatus: false,
                    introduced: false,
                    remediation: false,
                    ratio: false,
                    reduction: false,
                    csv: false
                });
            }
        };

        fetchInitialData();
    }, [defaultFilterParams]);

    // Cleanup on unmount
    useEffect(() => {
        const controllersRef = fetchControllersRef;

        return () => {
            isMounted.current = false;

            const controllers = controllersRef.current;
            Object.values(controllers).forEach(controller => {
                if (controller) controller.abort();
            });
        };
    }, []);

    // Metric update handlers
    const handleVulnerabilityDataUpdate = useCallback((update: ChartMetricUpdate) => {
        const { data, timeGranularity } = update;
        if (!data?.length) return;

        setMetricsGranularity(prev => ({
            ...prev,
            vulnerability: timeGranularity
        }));

        const latestData = data[data.length - 1];
        setMetrics(prev => ({
            ...prev,
            openIssues: latestData?.open || 0,
            resolvedIssues: latestData?.ResolvedIssuesCount || 0
        }));
    }, []);

    const handleRemediationDataUpdate = useCallback((update: ChartMetricUpdate) => {
        const { data, timeGranularity } = update;
        if (!data?.length) return;

        setMetricsGranularity(prev => ({
            ...prev,
            remediation: timeGranularity
        }));

        const latestData = data[data.length - 1];
        const averageTime = ((latestData?.criticalTime || 0) + (latestData?.highTime || 0)) / 2;

        setMetrics(prev => ({
            ...prev,
            remediationTime: averageTime
        }));
    }, []);

    const handleRatioDataUpdate = useCallback((update: ChartMetricUpdate) => {
        const { data, timeGranularity } = update;
        if (!data?.length) return;

        setMetricsGranularity(prev => ({
            ...prev,
            ratio: timeGranularity
        }));

        const calculateAverageRatio = (period: RatioData[]) => {
            if (period.length === 0) return 0;

            const lastEntry = period[period.length - 1];
            const scanTypes = ['sast', 'dast', 'sca', 'PENTEST', 'bugBounty'];
            const validRatios = scanTypes
                .map(type => lastEntry[type as keyof RatioData] as number)
                .filter(ratio => ratio > 0 && !isNaN(ratio) && isFinite(ratio));

            return validRatios.length === 0 ? 0 :
                validRatios.reduce((sum, ratio) => sum + ratio, 0) / validRatios.length;
        };

        setMetrics(prev => ({
            ...prev,
            resolutionRatio: calculateAverageRatio(data)
        }));
    }, []);

    return (
        <div className="reporting-dashboard">
            <div className="reporting-dashboard__metrics-grid">
                <MetricCard
                    title="Open Issues"
                    icon={<BsExclamationTriangle size={18} />}
                    value={metrics.openIssues}
                    previousValue={0}
                    timeGranularity={metricsGranularity.vulnerability}
                    label="Current open issues"
                    isLoading={chartLoadingStates.vulnerability}
                />
                <MetricCard
                    title="Resolved Issues"
                    icon={<BsCheckCircle size={18} />}
                    value={metrics.resolvedIssues}
                    previousValue={0}
                    timeGranularity={metricsGranularity.vulnerability}
                    label="Total resolved"
                    isLoading={chartLoadingStates.vulnerability}
                />
                <MetricCard
                    title="Average Remediation Time"
                    icon={<BsClockHistory size={18} />}
                    value={metrics.remediationTime}
                    previousValue={0}
                    timeGranularity={metricsGranularity.remediation}
                    label="Days per issue"
                    formatValue={(value) => Math.ceil(value).toString()}
                    isLoading={chartLoadingStates.remediation}
                />
                <MetricCard
                    title="Resolution Ratio"
                    icon={<BsPieChart size={18} />}
                    value={metrics.resolutionRatio}
                    previousValue={0}
                    timeGranularity={metricsGranularity.ratio}
                    label="Resolved vs Introduced"
                    formatValue={(value) => value.toFixed(2)}
                    isLoading={chartLoadingStates.ratio}
                />
            </div>

            <div className="reporting-dashboard__charts-grid">
                <DynamicVulnerabilityChart
                    data={vulnerabilityOpenData}
                    isLoading={chartLoadingStates.vulnerability}
                    selectedNames={chartFilterParams.vulnerability.names}
                    selectedScale={chartFilterParams.vulnerability.scale}
                    selectedDateRange={chartFilterParams.vulnerability.dateRange}
                    selectedNameType={chartFilterParams.vulnerability.nameType}
                    applications={applications}
                    productFamilies={productFamilies}
                    psArchitects={getUniqueDisplayOptions(psArchitects)}
                    securityChampions={getUniqueDisplayOptions(securityChampions)}
                    devLeads={getUniqueDisplayOptions(devLeads)}
                    productSecurityPM={getUniqueDisplayOptions(productSecurityPM)}
                    productManager={getUniqueDisplayOptions(productManager)}
                    tags={tags}
                    onDataUpdate={handleVulnerabilityDataUpdate}
                    {...createFilterCallbacks('vulnerability')}
                />

                <DynamicIntroducedChart
                    data={introducedData}
                    isLoading={chartLoadingStates.introduced}
                    selectedNames={chartFilterParams.introduced.names}
                    selectedScale={chartFilterParams.introduced.scale}
                    selectedDateRange={chartFilterParams.introduced.dateRange}
                    selectedNameType={chartFilterParams.introduced.nameType}
                    applications={applications}
                    productFamilies={productFamilies}
                    psArchitects={getUniqueDisplayOptions(psArchitects)}
                    securityChampions={getUniqueDisplayOptions(securityChampions)}
                    devLeads={getUniqueDisplayOptions(devLeads)}
                    productSecurityPM={getUniqueDisplayOptions(productSecurityPM)}
                    productManager={getUniqueDisplayOptions(productManager)}
                    tags={tags}
                    {...createFilterCallbacks('introduced')}
                />
                <DynamicRemediationChart
                    data={remediationData}
                    isLoading={chartLoadingStates.remediation}
                    selectedNames={chartFilterParams.remediation.names}
                    selectedScale={chartFilterParams.remediation.scale}
                    selectedDateRange={chartFilterParams.remediation.dateRange}
                    selectedNameType={chartFilterParams.remediation.nameType}
                    applications={applications}
                    productFamilies={productFamilies}
                    psArchitects={getUniqueDisplayOptions(psArchitects)}
                    securityChampions={getUniqueDisplayOptions(securityChampions)}
                    devLeads={getUniqueDisplayOptions(devLeads)}
                    productSecurityPM={getUniqueDisplayOptions(productSecurityPM)}
                    productManager={getUniqueDisplayOptions(productManager)}
                    tags={tags}
                    onDataUpdate={handleRemediationDataUpdate}
                    {...createFilterCallbacks('remediation')}
                />
                <DynamicReductionChart
                    data={reductionData}
                    isLoading={chartLoadingStates.reduction}
                    selectedNames={chartFilterParams.reduction.names}
                    selectedScale={chartFilterParams.reduction.scale}
                    selectedDateRange={chartFilterParams.reduction.dateRange}
                    selectedNameType={chartFilterParams.reduction.nameType}
                    applications={applications}
                    productFamilies={productFamilies}
                    psArchitects={getUniqueDisplayOptions(psArchitects)}
                    securityChampions={getUniqueDisplayOptions(securityChampions)}
                    devLeads={getUniqueDisplayOptions(devLeads)}
                    productSecurityPM={getUniqueDisplayOptions(productSecurityPM)}
                    productManager={getUniqueDisplayOptions(productManager)}
                    tags={tags}
                    {...createFilterCallbacks('reduction')}
                />
                <DynamicRatioChart
                    data={ratioData}
                    isLoading={chartLoadingStates.ratio}
                    selectedNames={chartFilterParams.ratio.names}
                    selectedScale={chartFilterParams.ratio.scale}
                    selectedDateRange={chartFilterParams.ratio.dateRange}
                    selectedNameType={chartFilterParams.ratio.nameType}
                    applications={applications}
                    productFamilies={productFamilies}
                    psArchitects={getUniqueDisplayOptions(psArchitects)}
                    securityChampions={getUniqueDisplayOptions(securityChampions)}
                    devLeads={getUniqueDisplayOptions(devLeads)}
                    productSecurityPM={getUniqueDisplayOptions(productSecurityPM)}
                    productManager={getUniqueDisplayOptions(productManager)}
                    tags={tags}
                    onDataUpdate={handleRatioDataUpdate}
                    {...createFilterCallbacks('ratio')}
                />
                <DynamicCsvExport
                    isLoading={chartLoadingStates.csv}
                    selectedNames={chartFilterParams.csv.names}
                    selectedScale={chartFilterParams.csv.scale}
                    selectedDateRange={chartFilterParams.csv.dateRange}
                    selectedNameType={chartFilterParams.csv.nameType}
                    applications={applications}
                    productFamilies={productFamilies}
                    psArchitects={getUniqueDisplayOptions(psArchitects)}
                    securityChampions={getUniqueDisplayOptions(securityChampions)}
                    devLeads={getUniqueDisplayOptions(devLeads)}
                    productSecurityPM={getUniqueDisplayOptions(productSecurityPM)}
                    productManager={getUniqueDisplayOptions(productManager)}
                    tags={tags}
                    {...createFilterCallbacks('csv')}
                />
            </div>
        </div>
    );
};

export default ReportingDashboard;