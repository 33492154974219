import React, { useState } from 'react';
import { MdOutlineFilterList } from "react-icons/md";
import { BsDownload } from 'react-icons/bs';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { ChartFilters } from './ChartFilters';
import FilterTags from './FilterTags';
import { Oval } from 'react-loader-spinner';
import { MultiselectOption } from '../shared/Multiselect/MultiselectOption';

interface DynamicCsvExportProps {
    selectedNames: string[];
    selectedScale: string;
    selectedDateRange: { startDate: Date; endDate: Date };
    selectedNameType: string;
    applications: MultiselectOption[];
    productFamilies: MultiselectOption[];
    psArchitects: MultiselectOption[];
    securityChampions: MultiselectOption[];
    devLeads: MultiselectOption[];
    productSecurityPM: MultiselectOption[];
    productManager: MultiselectOption[];
    tags: MultiselectOption[];
    isLoading: boolean;
    appChanged: (selected: MultiselectOption[]) => Promise<void>;
    appFamilyChanged: (selected: MultiselectOption[]) => Promise<void>;
    psArchitectsChanged: (selected: MultiselectOption[]) => Promise<void>;
    securityChampionsChanged: (selected: MultiselectOption[]) => Promise<void>;
    devLeadsChanged: (selected: MultiselectOption[]) => Promise<void>;
    productSecurityPMChanged: (selected: MultiselectOption[]) => Promise<void>;
    productManagerChanged: (selected: MultiselectOption[]) => Promise<void>;
    tagsChanged: (selected: MultiselectOption[]) => Promise<void>;
    scaleChanged: (scale: string) => Promise<void>;
    dateRangeChanged: (startDate: Date, endDate: Date) => Promise<void>;
}

export const DynamicCsvExport: React.FC<DynamicCsvExportProps> = ({
    selectedNames,
    selectedScale,
    selectedDateRange,
    selectedNameType,
    applications,
    productFamilies,
    psArchitects,
    securityChampions,
    devLeads,
    productSecurityPM,
    productManager,
    tags,
    isLoading,
    appChanged,
    appFamilyChanged,
    psArchitectsChanged,
    securityChampionsChanged,
    devLeadsChanged,
    productSecurityPMChanged,
    productManagerChanged,
    tagsChanged,
    scaleChanged,
    dateRangeChanged
}) => {
    const [activeFilter, setActiveFilter] = useState(false);
    const [exporting, setExporting] = useState(false);

    const handleExport = async () => {
        if (!selectedNames.length) return;

        setExporting(true);
        try {
            const response = await axios.post('/jirastatistic/getCsv', {
                scale: selectedScale,
                startDate: selectedDateRange.startDate.toISOString(),
                endDate: selectedDateRange.endDate.toISOString(),
                nameType: selectedNameType,
                selectedNames: selectedNames
            }, {
                responseType: 'blob'
            });

            const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, "RawVulnerabilityMetrics.csv");
        } catch (error) {
            console.error('Error exporting CSV:', error);
        } finally {
            setExporting(false);
        }
    };

    return (
        <div className="reporting-dashboard__chart-cell csv-export">
            <div className="chart-header">
                <div className="chart-title">
                    <BsDownload size={18} />
                    <h5>Export Raw Data</h5>
                    <FilterTags
                        selectedFilters={{
                            scale: selectedScale,
                            dateRange: selectedDateRange,
                        }}
                    />
                </div>
                <div className="chart-actions">
                    <button
                        className="filter-button"
                        onClick={() => setActiveFilter(!activeFilter)}
                    >
                        <MdOutlineFilterList size={18} /> Filters
                    </button>
                </div>
            </div>
            <div className="chart-container">
                <div className="chart-container">
                    <div className="export-content">
                        <p>Export raw vulnerability metrics data for the selected filters as CSV.</p>
                        <button
                            className="export-btn"
                            onClick={handleExport}
                            disabled={isLoading || exporting || !selectedNames.length}
                        >
                            {exporting ? (
                                <>
                                    <Oval
                                        visible={true}
                                        height="14"
                                        width="14"
                                        color="#ffffff"
                                        secondaryColor="#ffffff"
                                        ariaLabel="loading"
                                        strokeWidth={4}
                                    />
                                    Exporting...
                                </>
                            ) : (
                                'Export CSV'
                            )}
                        </button>
                    </div>
                </div>
            </div>
            <ChartFilters
                isOpen={activeFilter}
                onClose={() => setActiveFilter(false)}
                onApplyFilters={() => setActiveFilter(false)}
                applications={applications}
                productFamilies={productFamilies}
                psArchitects={psArchitects}
                securityChampions={securityChampions}
                devLeads={devLeads}
                productSecurityPM={productSecurityPM}
                productManager={productManager}
                tags={tags}
                chartId="csv"
                defaultValues={{
                    startDate: selectedDateRange.startDate,
                    endDate: selectedDateRange.endDate,
                    scale: selectedScale,
                    appFamilyFilter: selectedNameType
                }}
                callbacks={{
                    applicationChangedCallback: appChanged,
                    productFamilyChangedCallback: appFamilyChanged,
                    psArchitectsCallback: psArchitectsChanged,
                    securityChampionsCallback: securityChampionsChanged,
                    devLeadsCallback: devLeadsChanged,
                    productSecurityPMCallback: productSecurityPMChanged,
                    productManagerCallback: productManagerChanged,
                    tagsCallback: tagsChanged,
                    scaleChangedCallback: scaleChanged,
                    dateRangeChangedCallback: dateRangeChanged
                }}
            />
        </div>
    );
};