import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
import { motion } from "framer-motion";
import { IoIosApps } from "react-icons/io";
import { FaTrash } from "react-icons/fa6";
import { MdInfoOutline, MdEdit, MdCheck, MdError, MdModeEdit, MdSave, MdRocketLaunch, MdEditOff, MdSync, MdSyncProblem } from "react-icons/md";
import { SiJira } from "react-icons/si";
import { RiGitRepositoryFill } from "react-icons/ri";
import { Oval } from 'react-loader-spinner';
import { ExtraSmallLoadingCardContent } from '../shared/ExtraSmallLoadingCardContent';
import { TbReportSearch } from "react-icons/tb";
import {
    BsShieldFillCheck,
    BsShieldFillExclamation,
    BsShieldFillX,
    BsShieldSlash,
    BsShieldFillMinus
} from "react-icons/bs";
import { ScanResultsList } from "../ScanResults/ScanResultsList";
import AnimatedShieldLoader from '../shared/AnimatedShieldLoader';
import { AssetRecordDetailsModel } from "./AssetRecordDetailsModel";
import SectionCard from "./SectionCard";
import ExceptionsTable from './ExceptionsTable';
import ExceptionForm from './ExceptionForm';
import { Exception } from './Exception';

interface EditableFieldProps {
    value: string | string[] | boolean | undefined;
    onEdit: (value: string | boolean) => void;
    type?: 'text' | 'boolean' | 'environment';
    isEditMode: boolean;
}

interface JiraSearchResponse {
    total: number;
    maxResults: number;
    startAt: number;
    issues: any[];
}

const ARRAY_FIELDS = ['psArchitects', 'securityChampions', 'devLeads', 'productManager', 'productSecurityPM', 'tags'];
const ENVIRONMENT_OPTIONS = ['Camelot', 'Avalon', 'CHIP', 'MDP', 'Other'];

const fetchData = async (assetId: string | undefined): Promise<AssetRecordDetailsModel> => {
    const assetResponse = await axios.get(`/assets/${assetId}`);

    let result: AssetRecordDetailsModel = {
        assetRecord: assetResponse.data,
        applicationDetails: null,
        customPolicyName: null,
        exceptionsAccess: false
    }
    if (assetResponse?.data?.applicationId) {
        const [appResponse, scansResponse] = await Promise.all([
            axios.get(`/applications/details/${assetResponse.data.applicationId}`),
            axios.get(`/scan-results/${assetResponse.data.applicationId}`)
        ]);

        result.applicationDetails = {
            application: appResponse.data.app,
            scanResults: scansResponse.data.scanResults
        };
        // return {
        //     assetRecord: assetResponse.data,
        //     applicationDetails: {
        //         application: appResponse.data.app,
        //         scanResults: scansResponse.data.scanResults
        //     },
        //     customPolicyName: null
        // };
    }

    if(assetResponse?.data?.customPolicyId) {
        const policyResponse = await axios.get(`/policies/${assetResponse.data.customPolicyId}`);
        result.customPolicyName = policyResponse.data.policyName;
    }

    var exceptionsResponse = await axios.get(`/exception-management/asset/${assetId}`);
    result.assetRecord.exceptions = exceptionsResponse.data;

    var exceptionsAccessResponse = await axios.get(`/access-check/exception`);
    result.exceptionsAccess = exceptionsAccessResponse.data;

    return result;
};

const EditableField: React.FC<EditableFieldProps> = ({ value, onEdit, type = 'text', isEditMode }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedValue, setEditedValue] = useState(() => {
        if (type === 'boolean') {
            return (value as boolean) ? 'Yes' : 'No';
        }
        return Array.isArray(value) ? value.join(', ') : value?.toString() ?? '';
    });

    const handleBlur = () => {
        setIsEditing(false);
        if (type !== 'boolean') {
            onEdit(editedValue);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const newValue = e.target.value;
        setEditedValue(newValue);
        if (type === 'boolean') {
            onEdit(newValue.toLowerCase() === 'yes');
        } else {
            onEdit(newValue);
        }
    };

    if (type === 'environment' && isEditMode) {
        return (
            <select
                value={editedValue}
                onChange={handleChange}
                onBlur={handleBlur}
                className="environment-select"
            >
                <option value="">Select Environment</option>
                {ENVIRONMENT_OPTIONS.map(option => (
                    <option key={option} value={option}>{option}</option>
                ))}
            </select>
        );
    }

    if (type === 'boolean' && isEditMode) {
        return (
            <select
                value={editedValue}
                onChange={handleChange}
                onBlur={handleBlur}
                className="boolean-select"
            >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
        );
    }

    return (
        <div className="editable-field">
            {isEditing && isEditMode ? (
                <input
                    type="text"
                    value={editedValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={(e) => e.key === 'Enter' && handleBlur()}
                    autoFocus
                />
            ) : (
                <div className="display-value">
                    <span>{editedValue || ''}</span>
                    {isEditMode && (
                        <MdEdit className="edit-icon" onClick={() => setIsEditing(true)} />
                    )}
                </div>
            )}
        </div>
    );
};

const DescriptionField: React.FC<EditableFieldProps> = ({ value, onEdit, isEditMode }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedValue, setEditedValue] = useState(value?.toString() ?? '');

    const handleBlur = () => {
        setIsEditing(false);
        onEdit(editedValue);
    };

    return (
        <div className="editable-field description-field">
            {isEditing && isEditMode ? (
                <input
                    type="text"
                    value={editedValue}
                    onChange={(e) => setEditedValue(e.target.value)}
                    onBlur={handleBlur}
                    onKeyDown={(e) => e.key === 'Enter' && handleBlur()}
                    autoFocus
                />
            ) : (
                <div className="display-value">
                    <span dangerouslySetInnerHTML={{
                        __html: editedValue.replace(
                            /(https?:\/\/[^\s]+)/g,
                            '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>'
                        )
                    }} />
                    {isEditMode && (
                        <MdEdit className="edit-icon" onClick={() => setIsEditing(true)} />
                    )}
                </div>
            )}
        </div>
    );
};

const AssetRecordDetails: React.FC = () => {
    const params = useParams<{ id: string }>();
    const [editedValues, setEditedValues] = useState<Record<string, any>>({});
    const [hasChanges, setHasChanges] = useState(false);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isAddExceptionModalOpen, setIsAddExceptionModalOpen] = useState(false);

    const [isLoadingSeverityCounts, setIsLoadingSeverityCounts] = useState(true);
    const [severityCounts, setSeverityCounts] = useState<{
        criticalCount: number;
        highCount: number;
        mediumCount: number;
        lowCount: number;
    }>({
        criticalCount: 0,
        highCount: 0,
        mediumCount: 0,
        lowCount: 0
    });

    const { data, isLoading, refetch } = useQuery(
        ['assetRecord', params.id],
        () => fetchData(params.id),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            enabled: !!params.id,
            staleTime: Infinity
        }
    );

    useEffect(() => {
        const fetchSeverityCounts = async () => {
            if (!data?.assetRecord?.canonicalId) return;

            setIsLoadingSeverityCounts(true);
            try {
                const jqlQueries = {
                    criticalCount: `issuetype = SECBUG AND "Canonical ID[Short text]" ~ '"${data.assetRecord.canonicalId}"' AND status != Closed AND status != Done AND "Severity[Dropdown]" = Sev-0`,
                    highCount: `issuetype = SECBUG AND "Canonical ID[Short text]" ~ '"${data.assetRecord.canonicalId}"' AND status != Closed AND status != Done AND "Severity[Dropdown]" = Sev-1`,
                    mediumCount: `issuetype = SECBUG AND "Canonical ID[Short text]" ~ '"${data.assetRecord.canonicalId}"' AND status != Closed AND status != Done AND "Severity[Dropdown]" = Sev-2`,
                    lowCount: `issuetype = SECBUG AND "Canonical ID[Short text]" ~ '"${data.assetRecord.canonicalId}"' AND status != Closed AND status != Done AND "Severity[Dropdown]" = Sev-3`
                };

                const counts = {
                    criticalCount: 0,
                    highCount: 0,
                    mediumCount: 0,
                    lowCount: 0
                };

                await Promise.all(
                    Object.entries(jqlQueries).map(async ([key, jql]) => {
                        const response = await axios.get<JiraSearchResponse>('/jira/tickets/search', {
                            params: {
                                jql,
                                startAt: 0,
                                maxResults: 0
                            }
                        });
                        counts[key as keyof typeof counts] = response.data.total;
                    })
                );

                setSeverityCounts(counts);
            } catch (error) {
                console.error('Error fetching severity counts:', error);
            } finally {
                setIsLoadingSeverityCounts(false);
            }
        };

        fetchSeverityCounts();
    }, [data?.assetRecord?.canonicalId]);

    const handleAddException = async (exception: Exception) => {
        const assetExceptionsResponse = await axios.get(`/exception-management/asset/${data?.assetRecord.id}`);
        let sameNameException = assetExceptionsResponse.data.find((e: Exception) => e.exceptionName.toUpperCase() === exception.exceptionName.toUpperCase());
        if (sameNameException) {
            setErrorMessages(['An exception with the same name already exists']);
            setTimeout(() => setErrorMessages([]), 10000);
            return;
        }
        try {
            exception.assetId = data?.assetRecord?.id;
            const response = await axios.post(`exception-management`, exception);
            if (data?.assetRecord) {
                data.assetRecord.exceptions = response.data// [...(data.assetRecord.exceptions || []), response.data];
            }
            setSuccessMessage('Exception added successfully.');
            setTimeout(() => setSuccessMessage(null), 5000);
            setIsAddExceptionModalOpen(false);
        } catch (error) {
            setErrorMessages(['Failed to add exception']);
            setTimeout(() => setErrorMessages([]), 10000);
        }
    };

    const handleDeleteException = async (id: string) => {
        if (!data?.assetRecord?.id) return;
    
        const confirmDelete = window.confirm(`Are you sure you want to delete this exception? This action cannot be undone.`);
    
        if (!confirmDelete) return;
    
        try {
            await axios.delete(`/exception-management/${id}/${data.assetRecord.id}`);
            // After deletion, fetch the updated list
            const response = await axios.get(`/exception-management/asset/${data?.assetRecord.id}`);
            // Update the data in a way that triggers a re-render
            if (data?.assetRecord) {
                data.assetRecord.exceptions = response.data;
                setEditedValues({...editedValues});
            }
        } catch (error) {
            setErrorMessages(['Failed to delete exception']);
            setTimeout(() => setErrorMessages([]), 10000);
        }
    };

    const handleEdit = (field: string, value: string | boolean) => {
        setHasChanges(true);

        if (ARRAY_FIELDS.includes(field) && typeof value === 'string') {
            const arrayValue = value.split(',').map(x => x.trim());
            (data?.assetRecord as any)[field] = arrayValue;
        } else {
            (data?.assetRecord as any)[field] = value;
        }

        setEditedValues(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleSave = async (isDraft: boolean = false) => {
        if (data?.assetRecord) {
            data.assetRecord.isDraft = isDraft;
        }
    
        if (!isDraft) {
            const frontendValidationErrors: string[] = [];
            
            // Check all required fields
            if (!data?.assetRecord.canonicalId) {
                frontendValidationErrors.push("Canonical ID");
            }
            
            if (!data?.assetRecord.productFamilyName) {
                frontendValidationErrors.push("Product Family Name");
            }
            
            if (!data?.assetRecord.applicationName) {
                frontendValidationErrors.push("Application Name");
            }
            
            if (!data?.assetRecord.jiraProject) {
                frontendValidationErrors.push("Jira Project Name");
            }
            
            if (frontendValidationErrors.length > 0) {
                setErrorMessages(frontendValidationErrors);
                setTimeout(() => setErrorMessages([]), 10000);
                return;
            }
        }
    
        setIsSaving(true);
        try {
            const response = await axios.post("/assets", data?.assetRecord);
            if (response.status === 200) {
                setErrorMessages([]);
                setSuccessMessage('Asset record saved successfully.');
                setTimeout(() => setSuccessMessage(null), 5000);
                setIsEditMode(false);
                setHasChanges(false);
                await refetch();
            }
        } catch (error: any) {
            if (error.response?.status === 400 &&
                error.response?.data?.validationFailures) {
                setErrorMessages(error.response.data.validationFailures);
                setTimeout(() => setErrorMessages([]), 10000);
            }
        } finally {
            setIsSaving(false);
        }
    };

    const handleDelete = async () => {
        if (!data?.assetRecord?.id) return;

        const confirmDelete = window.confirm(`Are you sure you want to delete "${data.assetRecord.assetName}"? This action cannot be undone.`);

        if (!confirmDelete) return;

        setIsDeleting(true);
        try {
            await axios.delete(`/assets/${data.assetRecord.id}`);
            window.location.href = '/assets';
        } catch (error: any) {
            setErrorMessages(['Failed to delete asset record']);
            setTimeout(() => setErrorMessages([]), 10000);
        } finally {
            setIsDeleting(false);
        }
    };

    const getScaToken = async () => {
        if (!data?.applicationDetails?.application) return;
        const response = await axios.get(`/applications/${data.applicationDetails.application.id}/sca-token`);
        await navigator.clipboard.writeText(response.data.token);
    };

    const getValue = (field: string) => {
        return editedValues[field] ?? (data?.assetRecord as any)[field];
    };

    const renderInfoItem = (label: string, field: string, type: 'text' | 'boolean' | 'environment' = 'text', isRequired: boolean = false) => (
        <div className="info-item">
            <span className="label">
                {label}
                {isEditMode && isRequired && <span className="required-indicator">*</span>}
            </span>
            <EditableField
                value={getValue(field)}
                onEdit={(value) => handleEdit(field, value)}
                type={type}
                isEditMode={isEditMode}
            />
        </div>
    );

    const renderComplianceIcon = (status: string) => {
        const icons = {
            "PASSED": <BsShieldFillCheck className="status-icon passed" />,
            "DID_NOT_PASS": <BsShieldFillX className="status-icon failed" />,
            "FAILED": <BsShieldFillX className="status-icon failed" />,
            "CONDITIONAL_PASS": <BsShieldFillExclamation className="status-icon warning" />,
            "NOT_ASSESSED": <BsShieldSlash className="status-icon neutral" />,
            "DETERMINING": <BsShieldFillMinus className="status-icon neutral" />
        };
        return icons[status as keyof typeof icons] || null;
    };

    const renderHeader = () => (
        <motion.header
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            className="asset-header"
        >
            <div className="header-content">
                <div className="app-icon">
                    <IoIosApps size={32} />
                </div>
                <div className="title-badges">
                    <h1>{data?.assetRecord.assetName}</h1>
                    <div className="badges">
                        {data?.assetRecord.isDraft && (
                            <div className="environment-badge draft">
                                <span>Draft</span>
                            </div>
                        )}
                        {data?.assetRecord.isProduction && (
                            <div className="environment-badge production">
                                <span>Production</span>
                            </div>
                        )}
                        {data?.assetRecord.isFlagship && (
                            <div className="environment-badge flagship">
                                <span>Flagship</span>
                            </div>
                        )}
                        {data?.applicationDetails?.scanResults && 
                         data?.applicationDetails?.scanResults.length > 0 && (
                            <div className="environment-badge scanhub">
                                <span>ScanHub Enabled</span>
                            </div>
                        )}
                        {data?.assetRecord?.isExternallySynced === false && (
                            <div className="environment-badge not-synced">
                                <MdSyncProblem color="#fd4433" />
                            </div>
                        )}
                        {data?.assetRecord?.isExternallySynced === true && (
                            <div className="environment-badge synced">
                                <MdSync color="#1EB980" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="header-actions">
                {!isEditMode && (
                    <>
                        <button
                            onClick={() => window.open(`/audit-log/${data?.assetRecord.id}`)}
                            className="save-btn"
                            title="Audit Log">
                            <TbReportSearch />
                            Audit Log
                        </button>
                        {data?.applicationDetails?.application?.repoUrl || data?.assetRecord.githubRepoLink ? (
                            <button
                                onClick={() => window.open(data.applicationDetails?.application.repoUrl || data.assetRecord.githubRepoLink, '_blank', 'noreferrer')}
                                className="save-btn"
                                title="Repository"
                            >
                                <RiGitRepositoryFill />
                                Repository
                            </button>
                        ) : null}
                        <button
                            onClick={() => window.open(`https://dayforce.atlassian.net/issues/?jql=issuetype%20%3D%20SECBUG%20AND%20%22Canonical%20ID%5BShort%20text%5D%22%20~%20%27%22${data?.assetRecord.canonicalId}%22%27%20and%20status%20%21%3D%20Closed%20AND%20status%20%21%3D%20Done`, '_blank', 'noreferrer')}
                            className="save-btn"
                            title="Jira Issues"
                        >
                            <SiJira />
                            Jira Issues
                        </button>
                    </>
                )}
                {!isEditMode ? (
                    <button
                        onClick={() => setIsEditMode(true)}
                        className="save-btn"
                        disabled={isDeleting || isSaving}
                    >
                        <MdModeEdit />
                        Edit
                    </button>
                ) : (
                    <>
                        <button
                            onClick={handleDelete}
                            className="delete-btn"
                            disabled={isDeleting || isSaving}
                        >
                            {isDeleting ? (
                                <>
                                    <Oval
                                        visible={true}
                                        height="14"
                                        width="14"
                                        color="#ffffff"
                                        secondaryColor="#ffffff"
                                        ariaLabel="loading"
                                        strokeWidth={4}
                                    />
                                    Deleting...
                                </>
                            ) : (
                                <>
                                    <FaTrash />
                                    Delete
                                </>
                            )}
                        </button>
                        <button
                            onClick={() => {
                                setIsEditMode(false);
                                setEditedValues({});
                                setHasChanges(false);
                            }}
                            className="save-btn"
                            disabled={isDeleting || isSaving}
                        >
                            <MdEditOff />
                            Cancel
                        </button>
                        {hasChanges && (
                            <>
                                {data?.assetRecord.isDraft && (
                                    <button
                                        onClick={() => handleSave(true)}
                                        className="draft-btn"
                                        disabled={isDeleting || isSaving}
                                    >
                                        {isSaving ? (
                                            <>
                                                <Oval
                                                    visible={true}
                                                    height="16"
                                                    width="16"
                                                    color="#ffffff"
                                                    secondaryColor="#ffffff"
                                                    ariaLabel="loading"
                                                    strokeWidth={4}
                                                />
                                                Saving Draft...
                                            </>
                                        ) : (
                                            <>
                                                <MdSave />
                                                Save as Draft
                                            </>
                                        )}
                                    </button>
                                )}
                                <button
                                    onClick={() => handleSave(false)}
                                    className="save-btn production"
                                    disabled={isDeleting || isSaving}
                                >
                                    {isSaving ? (
                                        <>
                                            <Oval
                                                visible={true}
                                                height="16"
                                                width="16"
                                                color="#ffffff"
                                                secondaryColor="#ffffff"
                                                ariaLabel="loading"
                                                strokeWidth={4}
                                            />
                                            Saving...
                                        </>
                                    ) : (
                                        <>
                                            <MdRocketLaunch />
                                            {data?.assetRecord.isDraft ? 'Save to Production' : 'Save'}
                                        </>
                                    )}
                                </button>
                            </>
                        )}
                    </>
                )}
            </div>
        </motion.header>
    );

    const renderSecurityDetails = () => (
        <SectionCard title="Security Details">
            <div className="info-grid">
                {data?.applicationDetails?.application?.policyName && (
                    <div className="info-item">
                        <span className="label">Policy Name</span>
                        <span className="value">{data.assetRecord.customPolicyId ? data.customPolicyName : data.applicationDetails.application.policyName}</span>
                    </div>
                )}

                {data?.applicationDetails?.application?.policyComplianceStatus && (
                    <div className="info-item">
                        <span className="label">Policy Compliance</span>
                        <div className="compliance-status">
                            {renderComplianceIcon(data.assetRecord.customPolicyId ? data.assetRecord.customPolicyCompliance : data.applicationDetails.application.policyComplianceStatus)}
                            <span>{data.assetRecord.customPolicyId ? data.assetRecord.customPolicyCompliance : data.applicationDetails.application.policyComplianceStatus?.replace(/_/g, ' ')}</span>
                        </div>
                    </div>
                )}

                {data?.applicationDetails?.application && (
                    <>
                        <div className="info-item">
                            <span className="label">Severity Counts</span>
                            {isLoadingSeverityCounts ? (
                                <ExtraSmallLoadingCardContent />
                            ) : (
                                <div className="severity-metrics">
                                    <a href={`https://dayforce.atlassian.net/issues/?jql=issuetype%20%3D%20SECBUG%20AND%20%22Canonical%20ID%5BShort%20text%5D%22%20~%20%27%22${data.assetRecord.canonicalId}%22%27%20and%20status%20%21%3D%20Closed%20AND%20status%20%21%3D%20Done%20AND%20"Severity%5BDropdown%5D"%20%3D%20Sev-0`}
                                        className="metric critical"
                                        target="_blank"
                                        rel="noreferrer">
                                        C: {severityCounts.criticalCount}
                                    </a>
                                    <a href={`https://dayforce.atlassian.net/issues/?jql=issuetype%20%3D%20SECBUG%20AND%20%22Canonical%20ID%5BShort%20text%5D%22%20~%20%27%22${data.assetRecord.canonicalId}%22%27%20and%20status%20%21%3D%20Closed%20AND%20status%20%21%3D%20Done%20AND%20"Severity%5BDropdown%5D"%20%3D%20Sev-1`}
                                        className="metric high"
                                        target="_blank"
                                        rel="noreferrer">
                                        H: {severityCounts.highCount}
                                    </a>
                                    <a href={`https://dayforce.atlassian.net/issues/?jql=issuetype%20%3D%20SECBUG%20AND%20%22Canonical%20ID%5BShort%20text%5D%22%20~%20%27%22${data.assetRecord.canonicalId}%22%27%20and%20status%20%21%3D%20Closed%20AND%20status%20%21%3D%20Done%20AND%20"Severity%5BDropdown%5D"%20%3D%20Sev-2`}
                                        className="metric medium"
                                        target="_blank"
                                        rel="noreferrer">
                                        M: {severityCounts.mediumCount}
                                    </a>
                                    <a href={`https://dayforce.atlassian.net/issues/?jql=issuetype%20%3D%20SECBUG%20AND%20%22Canonical%20ID%5BShort%20text%5D%22%20~%20%27%22${data.assetRecord.canonicalId}%22%27%20and%20status%20%21%3D%20Closed%20AND%20status%20%21%3D%20Done%20AND%20"Severity%5BDropdown%5D"%20%3D%20Sev-3`}
                                        className="metric low"
                                        target="_blank"
                                        rel="noreferrer">
                                        L: {severityCounts.lowCount}
                                    </a>
                                </div>
                            )}
                        </div>

                        {data.applicationDetails.application.lastCompletedScanDate && (
                            <div className="info-item">
                                <span className="label">Last Scan</span>
                                <span className="value">
                                    {new Date(data.applicationDetails.application.lastCompletedScanDate)
                                        .toLocaleDateString('en-US', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit'
                                        })
                                    }
                                </span>
                            </div>
                        )}
                    </>
                )}

                {renderInfoItem('Product Family Name', 'productFamilyName', 'text', true)}
                {renderInfoItem('Application Name', 'applicationName', 'text', true)}
                {renderInfoItem('Component', 'componentName')}
            </div>
        </SectionCard>
    );

    const renderAssetDetails = () => (
        <SectionCard title="Asset Details">
            <div className="info-grid">
                {/* Row 1 */}
                <div style={{ gridColumn: "1" }}>
                    {renderInfoItem('Canonical ID', 'canonicalId', 'text', true)}
                </div>
                <div style={{ gridColumn: "2" }}>
                    {renderInfoItem('SAST & SCA Profile Name', 'veracodeName')}
                </div>
                <div style={{ gridColumn: "3" }}>
                    {renderInfoItem('DAST URL', 'dastUrl')}
                </div>
                <div style={{ gridColumn: "4" }}>
                    {renderInfoItem('Tags', 'tags')}
                </div>
                {/* Row 2 */}
                <div style={{ gridColumn: "1" }}>
                    {renderInfoItem('ProdSec Lead', 'psArchitects')}
                </div>
                <div style={{ gridColumn: "2" }}>
                    {renderInfoItem('Program Manager', 'productSecurityPM')}
                </div>
                {/* Row 3 */}
                <div style={{ gridColumn: "1" }}>
                    {renderInfoItem('Dev Lead', 'devLeads')}
                </div>
                <div style={{ gridColumn: "2" }}>
                    {renderInfoItem('Product Manager', 'productManager')}
                </div>
                <div style={{ gridColumn: "3" }}>
                    {renderInfoItem('Security Champion(s)', 'securityChampions')}
                </div>
                {/* <div style={{ gridColumn: "4" }}>
                    {renderInfoItem('Jira team', 'jiraTeam')}
                </div> */}
                {/* Row 4 */}
                <div style={{ gridColumn: "1 / span 4" }}>
                    <div className="info-item">
                        <span className="label">Description</span>
                        <DescriptionField
                            value={getValue('description')}
                            onEdit={(value) => handleEdit('description', value)}
                            isEditMode={isEditMode}
                        />
                    </div>
                </div>
            </div>
        </SectionCard>
    );

    const renderDevSecOpsDetails = () => (
        <SectionCard title="DevSecOps Details" defaultOpen={false}>
            <div className="info-grid">
                {data?.assetRecord?.id && (
                    <div className="info-item">
                        <span className="label">Asset ID</span>
                        <span className="value">{data?.assetRecord?.id}</span>
                    </div>
                )}

                {data?.applicationDetails?.application?.id && (
                    <div className="info-item">
                        <span className="label">ScanHub GUID</span>
                        <span className="value">{data.applicationDetails.application.id}</span>
                    </div>
                )}

                {data?.applicationDetails?.application?.externalId && (
                    <div className="info-item">
                        <span className="label">Veracode GUID</span>
                        <span className="value">{data.applicationDetails.application.externalId}</span>
                    </div>
                )}

                {data?.applicationDetails?.application && (
                    <div className="info-item">
                        <span className="label">Support Information</span>
                        <div className="value">
                            <div>Tier Level: {data.applicationDetails.application.appSecSupportTierLevel}</div>
                            <div>Policy: {data.applicationDetails.application.policyName}</div>
                        </div>
                    </div>
                )}

                {renderInfoItem('Import Results', 'importToJira', 'boolean')}
                {renderInfoItem('Production', 'isProduction', 'boolean')}
                {renderInfoItem('Flagship', 'isFlagship', 'boolean')}
                {renderInfoItem('Jira Project', 'jiraProject', 'text', true)}
                {renderInfoItem('Environment', 'platformBase', 'environment')}

                <div className="info-item">
                    <span className="label">
                        Repo URL
                        {isEditMode && data?.assetRecord.isDraft && <span className="required-indicator">*</span>}
                    </span>
                    {data?.applicationDetails?.application?.repoUrl ? (
                        <span className="value">{data.applicationDetails.application.repoUrl}</span>
                    ) : (
                        <EditableField
                            value={getValue('githubRepoLink')}
                            onEdit={(value) => handleEdit('githubRepoLink', value)}
                            isEditMode={isEditMode}
                        />
                    )}
                </div>
            </div>

            {data?.applicationDetails?.application && renderActionButtons()}
        </SectionCard>
    );

    const renderExceptions = () => (
        <SectionCard title="Exceptions" defaultOpen={false}>
            <ExceptionsTable
                exceptions={data?.assetRecord.exceptions || []}
                onAddException={() => setIsAddExceptionModalOpen(true)}
                onDeleteException={handleDeleteException}
                canEdit={data?.exceptionsAccess || false}
            />
        </SectionCard>
    );

    const renderActionButtons = () => (
        <>
            <div className="app-actions">
                <button
                    onClick={getScaToken}
                    className="action-button"
                    hidden={true}
                >
                    <MdInfoOutline />
                    Print Token
                </button>
            </div>
            {data?.applicationDetails?.scanResults && 
            data?.applicationDetails?.scanResults.length > 0 && (
                <div className="scan-results-container scans-list">
                    <ScanResultsList items={data?.applicationDetails?.scanResults || []} />
                </div>
            )}
        </>
    );

    if (isLoading) {
        return (
            <div id="loader">
                <AnimatedShieldLoader />
            </div>
        );
    }

    return (
        <div className="asset-record">
            {renderHeader()}

            {errorMessages.length > 0 && (
                <div className="error-message">
                    <div className="error-content">
                        <MdError size={20} />
                        <div>
                            {errorMessages.map((msg, index) => (
                                <span key={index}>{msg}{index < errorMessages.length - 1 ? ', ' : ' '}</span>
                            ))}
                            are required to save to production.
                        </div>
                    </div>
                </div>
            )}

            {successMessage && (
                <div className="success-message">
                    <MdCheck />
                    {successMessage}
                </div>
            )}

            <div className="asset-content">
                {renderSecurityDetails()}
                {renderAssetDetails()}
                {renderExceptions()}
                {renderDevSecOpsDetails()}
            </div>

            <ExceptionForm
                assetId={data?.assetRecord.id}
                isOpen={isAddExceptionModalOpen}
                onClose={() => setIsAddExceptionModalOpen(false)}
                onSubmit={handleAddException}
            />
        </div>
    );
};

export default AssetRecordDetails;