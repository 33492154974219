import React, { useState, useEffect } from 'react';
import { FaBug } from "react-icons/fa";
import { MdOutlineFilterList } from "react-icons/md";
import { DynamicChart } from './DynamicChart';
import { ChartFilters } from './ChartFilters';
import FilterTags from './FilterTags';
import { TimeGranularity, IntroducedData, VULNERABILITY_COLORS } from './types';
import { MultiselectOption } from '../shared/Multiselect/MultiselectOption';
import './ReportingDashboard.scss';

interface DynamicIntroducedChartProps {
    data: IntroducedData[];
    isLoading: boolean;
    selectedNames?: string[];
    selectedScale: string;
    selectedDateRange: { startDate: Date; endDate: Date };
    selectedNameType: string;
    applications: MultiselectOption[];
    productFamilies: MultiselectOption[];
    psArchitects: MultiselectOption[];
    securityChampions: MultiselectOption[];
    devLeads: MultiselectOption[];
    productSecurityPM: MultiselectOption[];
    productManager: MultiselectOption[];
    tags: MultiselectOption[];
    appChanged: (selected: MultiselectOption[]) => Promise<void>;
    appFamilyChanged: (selected: MultiselectOption[]) => Promise<void>;
    psArchitectsChanged: (selected: MultiselectOption[]) => Promise<void>;
    securityChampionsChanged: (selected: MultiselectOption[]) => Promise<void>;
    devLeadsChanged: (selected: MultiselectOption[]) => Promise<void>;
    productSecurityPMChanged: (selected: MultiselectOption[]) => Promise<void>;
    productManagerChanged: (selected: MultiselectOption[]) => Promise<void>;
    tagsChanged: (selected: MultiselectOption[]) => Promise<void>;
    scaleChanged: (scale: string) => Promise<void>;
    dateRangeChanged: (startDate: Date, endDate: Date) => Promise<void>;
}

const processIntroducedData = (rawData: any[]): IntroducedData[] => {
    if (!rawData) return [];

    return rawData
        .map(item => ({
            timeStamp: item.timeStamp,
            sast: Math.ceil(item.sast),
            dast: Math.ceil(item.dast),
            sca: Math.ceil(item.sca),
            PENTEST: Math.ceil(item.PENTEST || item.pentest || 0),
            bugBounty: Math.ceil(item.bugBounty),
            secureSDLC: Math.ceil(item.secureSDLC || 0)
        }))
        .sort((a, b) => new Date(a.timeStamp).getTime() - new Date(b.timeStamp).getTime());
};

export const DynamicIntroducedChart: React.FC<DynamicIntroducedChartProps> = ({
    data,
    isLoading,
    selectedNames,
    selectedScale,
    selectedDateRange,
    selectedNameType,
    applications,
    productFamilies,
    psArchitects,
    securityChampions,
    devLeads,
    productSecurityPM,
    productManager,
    tags,
    appChanged,
    appFamilyChanged,
    psArchitectsChanged,
    securityChampionsChanged,
    devLeadsChanged,
    productSecurityPMChanged,
    productManagerChanged,
    tagsChanged,
    scaleChanged,
    dateRangeChanged
}) => {
    const [activeFilter, setActiveFilter] = useState(false);
    const [processedData, setProcessedData] = useState<IntroducedData[]>([]);

    useEffect(() => {
        setProcessedData(processIntroducedData(data));
    }, [data]);

    return (
        <div className="reporting-dashboard__chart-cell">
            <div className="chart-header">
                <div className="chart-title">
                    <FaBug size={18} />
                    <h5>Introduced Vulnerabilities</h5>
                    <FilterTags
                        selectedFilters={{
                            scale: selectedScale,
                            dateRange: selectedDateRange,
                        }}
                    />
                </div>
                <button
                    className="filter-button"
                    onClick={() => setActiveFilter(!activeFilter)}
                >
                    <MdOutlineFilterList size={18} /> Filters
                </button>
            </div>
            <div className="chart-container">
                <DynamicChart
                    data={processedData}
                    config={{
                        dateRange: selectedDateRange,
                        chartType: 'line',
                        granularity: selectedScale as TimeGranularity,
                        nameType: selectedNameType,
                        selectedNames: selectedNames || []
                    }}
                    isLoading={isLoading}
                    dataKeys={['sast', 'dast', 'sca', 'PENTEST', 'bugBounty']}
                    keyLabels={{
                        sast: 'SAST',
                        dast: 'DAST',
                        sca: 'SCA',
                        PENTEST: 'Pentest',
                        bugBounty: 'Bug Bounty'
                    }}
                    colors={[
                        VULNERABILITY_COLORS.SAST.open,
                        VULNERABILITY_COLORS.DAST.open,
                        VULNERABILITY_COLORS.SCA.open,
                        VULNERABILITY_COLORS.PENTEST.open,
                        VULNERABILITY_COLORS['Bug Bounty'].open
                    ]}
                />
            </div>
            <ChartFilters
                isOpen={activeFilter}
                onClose={() => setActiveFilter(false)}
                onApplyFilters={() => setActiveFilter(false)}
                applications={applications}
                productFamilies={productFamilies}
                psArchitects={psArchitects}
                securityChampions={securityChampions}
                devLeads={devLeads}
                productSecurityPM={productSecurityPM}
                productManager={productManager}
                tags={tags}
                chartId="introduced"
                defaultValues={{
                    startDate: selectedDateRange.startDate,
                    endDate: selectedDateRange.endDate,
                    scale: selectedScale,
                    appFamilyFilter: selectedNameType
                }}
                callbacks={{
                    applicationChangedCallback: appChanged,
                    productFamilyChangedCallback: appFamilyChanged,
                    psArchitectsCallback: psArchitectsChanged,
                    securityChampionsCallback: securityChampionsChanged,
                    devLeadsCallback: devLeadsChanged,
                    productSecurityPMCallback: productSecurityPMChanged,
                    productManagerCallback: productManagerChanged,
                    tagsCallback: tagsChanged,
                    scaleChangedCallback: scaleChanged,
                    dateRangeChangedCallback: dateRangeChanged
                }}
            />
        </div>
    );
};

export default DynamicIntroducedChart;