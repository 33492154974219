import React, { useState, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  IconButton,
  TableSortLabel,
  InputAdornment
} from '@mui/material';
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  MdStar,
  MdStarOutline
} from "react-icons/md";
import {
  BsShieldFillCheck,
  BsShieldFillX,
  BsShieldFillExclamation,
  BsShieldSlash,
  BsShieldFillMinus
} from "react-icons/bs";
import { SiJira } from "react-icons/si";
import { BiSolidLock } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import { Application } from './Application';
import './Applications.scss';

interface Props {
  items: Application[];
}

type SortConfig = {
  field: keyof Application | '';
  direction: 'asc' | 'desc';
};

export const ApplicationList: React.FC<Props> = ({ items: initialItems }) => {
  const navigate = useNavigate();
  const [items, setItems] = useState(initialItems);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState<SortConfig>({ field: 'name', direction: 'asc' });

  const handleSort = (field: keyof Application) => {
    setSortConfig(prev => ({
      field,
      direction: prev.field === field && prev.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const addRemoveToFavorites = async (id: string) => {
    try {
      await axios.post(`/favorites/${id}`);
      const appResponse = await axios.get(`/applications/${id}`);
      setItems(prev => prev.map(item =>
        item.id === id ? appResponse.data.app : item
      ));
    } catch (error) {
      console.error('Error updating favorites:', error);
    }
  };

  const handleSecretsScanner = (repoName: string, repoUrl: string) => {
    if (repoName) {
      const repo = repoUrl.split('/').pop() || '';
      navigate(`/secrets-scanner?repo=${encodeURIComponent(repo)}`);
    }
  };

  const getPolicyComplianceIcon = (status: string) => {
    switch (status) {
      case "PASSED":
        return <BsShieldFillCheck size={15} />;
      case "DID_NOT_PASS":
        return <BsShieldFillX size={15} />;
      case "CONDITIONAL_PASS":
        return <BsShieldFillExclamation size={15} />;
      case "NOT_ASSESSED":
        return <BsShieldSlash size={15} />;
      case "DETERMINING":
        return <BsShieldFillMinus size={15} />;
      default:
        return null;
    }
  };

  const getScanDateColor = (scanDate: string) => {
    const currentDate = new Date();
    const lastCompletedScanDate = new Date(scanDate);
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(currentDate.getDate() - 30);

    return lastCompletedScanDate < thirtyDaysAgo ? "#fd4433" : "#1EB980";
  };

  const filteredAndSortedData = useMemo(() => {
    let processed = [...items];

    if (searchQuery) {
      processed = processed.filter(item =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.canonicalId.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (sortConfig.field) {
      processed.sort((a, b) => {
        const aValue = String(a[sortConfig.field]);
        const bValue = String(b[sortConfig.field]);
        return sortConfig.direction === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      });
    }

    return processed;
  }, [items, searchQuery, sortConfig]);

  return (
    <div className="application-table-container">
      <div className="application-table-search">
        <TextField
          placeholder="Search..."
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FaSearch className="search-icon" />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <TableContainer component={Paper} className="application-table-paper">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.field === 'name'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('name')}
                >
                  Application
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.field === 'policyName'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('policyName')}
                >
                  Policy
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.field === 'policyComplianceStatus'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('policyComplianceStatus')}
                >
                  Policy Compliance
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.field === 'canonicalId'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('canonicalId')}
                >
                  Canonical ID
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.field === 'appSecSupportTierLevel'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('appSecSupportTierLevel')}
                >
                  Support Tier
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.field === 'sastStatus'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('sastStatus')}
                >
                  SAST Status
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.field === 'lastCompletedScanDate'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('lastCompletedScanDate')}
                >
                  Last Scan
                </TableSortLabel>
              </TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAndSortedData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id} hover>
                  <TableCell>
                    <NavLink
                      to={`/application/${row.id}`}
                      className="application-table-link"
                    >
                      {row.name}
                    </NavLink>
                  </TableCell>
                  <TableCell>{row.policyName}</TableCell>
                  <TableCell>
                    <div className={`policyComplianceStatus ${row.policyComplianceStatus}`}>
                      {getPolicyComplianceIcon(row.policyComplianceStatus)}
                      {row.policyComplianceStatus}
                    </div>
                  </TableCell>
                  <TableCell>{row.canonicalId}</TableCell>
                  <TableCell>{row.appSecSupportTierLevel}</TableCell>
                  <TableCell>{row.sastStatus}</TableCell>
                  <TableCell style={{ color: getScanDateColor(row.lastCompletedScanDate) }}>
                    {new Date(row.lastCompletedScanDate).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    <div className="application-table-actions" style={{ justifyContent: 'flex-start' }}>
                      <IconButton
                        size="small"
                        onClick={() => addRemoveToFavorites(row.id)}
                      >
                        {row.isInFavorites ? <MdStar size={21} /> : <MdStarOutline size={21} />}
                      </IconButton>
                      {row.canonicalId && (
                        <IconButton
                          size="small"
                          component="a"
                          href={`https://dayforce.atlassian.net/issues/?jql=issuetype%20%3D%20SECBUG%20AND%20%22Canonical%20ID%5BShort%20text%5D%22%20~%20%27%22${row.canonicalId}%22%27%20and%20status%20%21%3D%20Closed%20AND%20status%20%21%3D%20Done`}
                          target="_blank"
                        >
                          <SiJira />
                        </IconButton>
                      )}
                      {row.repoName && (
                        <IconButton
                          size="small"
                          onClick={() => handleSecretsScanner(row.repoName, row.repoUrl)}
                        >
                          <BiSolidLock />
                        </IconButton>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={filteredAndSortedData.length}
        page={page}
        onPageChange={(_, newPage) => setPage(newPage)}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
        className="application-table-pagination"
      />
    </div>
  );
};

export default ApplicationList;