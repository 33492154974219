import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

const AnimatedShieldLoader = () => {
  const [color, setColor] = useState('#92F2CC');

  useEffect(() => {
    // Get the computed style
    const style = getComputedStyle(document.documentElement);
    const mainColor = style.getPropertyValue('--mainColor').trim();
    if (mainColor) {
      setColor(mainColor);
    }
  }, []);

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <motion.svg
        width="55"
        height="55"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <motion.path
          d="M12 3L5 6V12C5 16.14 8.04 20.02 12 21C15.96 20.02 19 16.14 19 12V6L12 3Z"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
          initial={{
            strokeDasharray: 58,
            strokeDashoffset: 58
          }}
          animate={{
            strokeDashoffset: -58
          }}
          transition={{
            duration: 1.5,
            ease: "linear",
            repeat: Infinity,
          }}
        />
      </motion.svg>
    </div>
  );
};

export default AnimatedShieldLoader;