// import axios from "axios";
import { useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import { VscError } from "react-icons/vsc";

import AnimatedShieldLoader from "../shared/AnimatedShieldLoader";
import { PolicyList } from "./PolicyList";
import axios from "axios";
import { PolicyPageModel } from "./PolicyPageModel";

const fetchData = async (): Promise<PolicyPageModel> => {
    // API Call
    const response = await axios.get("/policies");
    const usersResponse = await axios.get("/userInfo");
    const accessResponse = await axios.get(`/access-check/policy`);

    return {
        canEdit: accessResponse.data,
        policies: response.data.map((item: any) => {
            return {
                id: item.id,
                policyName: item.policyName,
                isDraft: item.isDraft,
                isActive: item.isActive,
                createdBy: usersResponse.data.filter(x => x.id === item.createdBy)[0].email,
                createdDate: new Date(item.createdDate),
                updatedDate: new Date(item.updatedDate)
            }})
    }
    // return response.data.map((item: any) => {
    //     return {
    //         id: item.id,
    //         policyName: item.policyName,
    //         isDraft: item.isDraft,
    //         isActive: item.isActive,
    //         createdBy: usersResponse.data.filter(x => x.id === item.createdBy)[0].email,
    //         createdDate: new Date(item.createdDate),
    //         updatedDate: new Date(item.updatedDate)
    //     };
    // })

    // dummy data
    // return [
    //     {
    //         id: "1",
    //         policyName: "Test Policy 1",
    //         isDraft: false,
    //         isActive: true,
    //         createdBy: "Test User",
    //         createdDate: new Date(),
    //         updatedDate: new Date()
    //     },
    //     {
    //         id: "2",
    //         policyName: "Test Policy 2",
    //         isDraft: true,
    //         isActive: false,
    //         createdBy: "Test User 2",
    //         createdDate: new Date(Date.now() - 86400000),
    //         updatedDate: new Date()
    //     },
    //     {
    //         id: "3",
    //         policyName: "Test Policy 3",
    //         isDraft: false,
    //         isActive: true,
    //         createdBy: "Test User 3",
    //         createdDate: new Date(Date.now() - 172800000),
    //         updatedDate: new Date(Date.now() - 86400000)
    //     }
    // ];
};

export const PolicyRecords: React.FC = () => {
    const {
        data,
        isLoading,
        isSuccess
    } = useQuery("get-policies", fetchData, {
        enabled: true
    });

    const queryClient = useQueryClient();
    const location = useLocation();

    useEffect(() => {
        queryClient.invalidateQueries("get-policies");
    }, [location, queryClient]);

    if (isLoading) {
        return (
            <div id="loader">
                <AnimatedShieldLoader />
            </div>
        );
    }

    if (isSuccess && data) {
        return <PolicyList items={data.policies} canEditPolicy={data.canEdit} />;
    }

    return <><VscError /> Failed to load, please check console</>;
};

export default PolicyRecords;