import React, { useState, useMemo, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Paper, TablePagination, TextField,
    TableSortLabel, IconButton, Menu, InputAdornment
} from '@mui/material';
import { NavLink } from "react-router-dom";
import { PolicyRecord } from './PolicyRecord';
import { FaPlus, FaFilter, FaSearch } from 'react-icons/fa';
import { RiCloseCircleFill } from "react-icons/ri";
import { MdOutlineFilterList, MdOutlineFilterListOff, MdOutlineClose } from 'react-icons/md';
import './PolicyList.scss';

import PolicyForm from './PolicyForm';

interface Props {
    items: PolicyRecord[];
    canEditPolicy: boolean;
}

type SortConfig = {
    field: keyof PolicyRecord | '';
    direction: 'asc' | 'desc';
};

type FilterState = {
    [key: string]: Set<string>;
};

interface ColumnConfig {
    field: keyof PolicyRecord;
    label: string;
}

const COLUMNS: ColumnConfig[] = [
    { field: 'policyName', label: 'Policy Name' },
    { field: 'createdBy', label: 'Creator' },
    { field: 'createdDate', label: 'Created Date' },
    { field: 'lastUpdated', label: 'Updated Date' },
    { field: 'isActive', label: 'Status' }
];

export const PolicyList: React.FC<Props> = ({ items: initialItems, canEditPolicy: canEdit }) => {
    const [items] = useState(initialItems);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState(() => localStorage.getItem('searchQuery') || '');
    const [sortConfig, setSortConfig] = useState<SortConfig>({ field: 'policyName', direction: 'asc' });
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [filterMenuAnchor, setFilterMenuAnchor] = useState<null | HTMLElement>(null);
    const [filterAnchorEl, setFilterAnchorEl] = useState<{ [key: string]: HTMLElement | null }>({});

    const [columnFilters, setColumnFilters] = useState<FilterState>(() => {
        const saved = localStorage.getItem('columnFilters');
        return saved ? JSON.parse(saved, (key, value) => {
            return value instanceof Object && value.hasOwnProperty('values')
                ? new Set(value.values)
                : value;
        }) : {};
    });

    const [activeFilters, setActiveFilters] = useState<Set<string>>(() => {
        const saved = localStorage.getItem('activeFilters');
        return saved ? new Set(JSON.parse(saved)) : new Set();
    });

    const getFilteredValues = (field: keyof PolicyRecord, currentFilters: FilterState) => {
        const values = new Set<string>();
        values.add('Empty');
        const valuesArray: string[] = [];

        let itemsToProcess = [...items];

        // Apply existing filters from other columns
        Object.entries(currentFilters).forEach(([filterField, filterValues]) => {
            if (filterField !== field && filterValues.size > 0) {
                itemsToProcess = itemsToProcess.filter(item => {
                    const value = item[filterField as keyof PolicyRecord];
                    const isEmpty = !value;

                    if (filterValues.has('Empty') && isEmpty) return true;

                    if (filterField === 'createdDate' || filterField === 'updatedDate') {
                        const date = new Date(value as Date);
                        return !isNaN(date.getTime()) && filterValues.has(date.toLocaleDateString());
                    }

                    return Array.from(filterValues).some(filterValue =>
                        filterValue === 'Empty' ? isEmpty : String(value) === filterValue
                    );
                });
            }
        });

        // Apply search if present
        // if (searchQuery) {
        //     const searchLower = searchQuery.toLowerCase();
        //     itemsToProcess = itemsToProcess.filter(item =>
        //         item.policyAction?.toLowerCase().includes(searchLower)
        //     );
        // }

        // Collect values based on field type
        itemsToProcess.forEach(item => {
            const value = item[field];

            if (field === 'createdDate' || field === 'lastUpdated') {
                const date = new Date(value as Date);
                if (!isNaN(date.getTime())) {
                    valuesArray.push(date.toLocaleDateString());
                }
            } else if (value) {
                valuesArray.push(String(value));
            }
        });

        // Sort values
        valuesArray.sort((a, b) => {
            if (field === 'createdDate' || field === 'lastUpdated') {
                return new Date(a).getTime() - new Date(b).getTime();
            }
            return a.toLowerCase().localeCompare(b.toLowerCase());
        });

        // Add sorted values to Set
        valuesArray.forEach(value => values.add(value));

        return Array.from(values);
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setSearchQuery(newValue);
        localStorage.setItem('searchQuery', newValue);
    };

    const handleSort = (field: keyof PolicyRecord) => {
        setSortConfig(prev => ({
            field,
            direction: prev.field === field && prev.direction === 'asc' ? 'desc' : 'asc'
        }));
    };

    const handleFilterClick = (event: React.MouseEvent<HTMLElement>, field: string) => {
        event.stopPropagation();
        setFilterAnchorEl(prev => ({
            ...prev,
            [field]: event.currentTarget
        }));
    };

    const clearColumnFilter = (event: React.MouseEvent<HTMLElement>, field: string) => {
        event.stopPropagation();
        setColumnFilters(prev => {
            const newFilters = { ...prev };
            delete newFilters[field];
            localStorage.setItem('columnFilters', JSON.stringify(newFilters, (key, value) => {
                return value instanceof Set ? { values: Array.from(value) } : value;
            }));
            return newFilters;
        });
    };

    const handleFilterClose = (field: string) => {
        setFilterAnchorEl(prev => ({
            ...prev,
            [field]: null
        }));
    };

    const toggleFilter = (field: string, value: string) => {
        setColumnFilters(prev => {
            const newFilters = { ...prev };
            if (!newFilters[field]) {
                newFilters[field] = new Set([value]);
            } else if (newFilters[field].has(value)) {
                newFilters[field].delete(value);
                if (newFilters[field].size === 0) {
                    delete newFilters[field];
                }
            } else {
                newFilters[field].add(value);
            }
            localStorage.setItem('columnFilters', JSON.stringify(newFilters, (key, value) => {
                return value instanceof Set ? { values: Array.from(value) } : value;
            }));
            return newFilters;
        });
    };

    const toggleTypeFilter = (field: string) => {
        setActiveFilters(prev => {
            const newSet = new Set(prev);
            if (newSet.has(field)) {
                newSet.delete(field);
            } else {
                newSet.add(field);
            }
            localStorage.setItem('activeFilters', JSON.stringify(Array.from(newSet)));
            return newSet;
        });
    };

    useEffect(() => {
        setPage(0);
    }, [searchQuery, activeFilters, columnFilters]);

    const renderFilterMenu = (field: string) => (
        <Menu
            anchorEl={filterAnchorEl[field]}
            open={Boolean(filterAnchorEl[field])}
            onClose={() => handleFilterClose(field)}
            PaperProps={{
                className: "column-menu-paper"
            }}
        >
            <div className="filter-section">
                <h3>Filter Values</h3>
                {getFilteredValues(field as keyof PolicyRecord, columnFilters).map((value) => (
                    <div key={value} className="checkbox-container">
                        <input
                            type="checkbox"
                            id={`filter-${field}-${value}`}
                            checked={columnFilters[field]?.has(value) || false}
                            onChange={() => toggleFilter(field, value)}
                        />
                        <span>{value}</span>
                    </div>
                ))}
            </div>
        </Menu>
    );

    const renderColumnHeader = (column: ColumnConfig) => (
        <div className="column-header">
            <TableSortLabel
                active={sortConfig.field === column.field}
                direction={sortConfig.direction}
                onClick={() => handleSort(column.field)}
            >
                {column.label}
            </TableSortLabel>
            <div className="filter-actions">
                <IconButton
                    size="small"
                    onClick={(e) => handleFilterClick(e, column.field)}
                    className={`filter-icon ${columnFilters[column.field]?.size ? 'active' : ''}`}
                >
                    <FaFilter size={12} />
                </IconButton>
                {columnFilters[column.field]?.size > 0 && (
                    <IconButton
                        size="small"
                        onClick={(e) => clearColumnFilter(e, column.field)}
                        className="clear-filter-icon"
                        title="Clear filter"
                    >
                        <MdOutlineClose />
                    </IconButton>
                )}
            </div>
            {renderFilterMenu(column.field)}
        </div>
    );

    const filteredAndSortedData = useMemo(() => {
        console.log("Processing data:", items);
        let processed = [...items];

        // Apply column filters
        Object.entries(columnFilters).forEach(([field, values]) => {
            if (values.size > 0) {
                processed = processed.filter(item => {
                    const value = item[field as keyof PolicyRecord];
                    const isEmpty = !value;

                    if (values.has('Empty') && isEmpty) return true;

                    if (field === 'createdDate' || field === 'updatedDate') {
                        const date = new Date(value as Date);
                        return !isNaN(date.getTime()) && values.has(date.toLocaleDateString());
                    }

                    return Array.from(values).some(filterValue =>
                        filterValue === 'Empty' ? isEmpty : String(value) === filterValue
                    );
                });
            }
        });

        // Apply search filter
        if (searchQuery) {
            const searchLower = searchQuery.toLowerCase();
            processed = processed.filter(item =>
                item.policyName.toLowerCase().includes(searchLower) ||
                item.createdBy.toLowerCase().includes(searchLower) ||
                new Date(item.createdDate).toLocaleDateString().includes(searchLower) ||
                new Date(item.lastUpdated).toLocaleDateString().includes(searchLower)
            );
        }

        // Apply type filters
        const typeFilters = ['isActive'].filter(f => activeFilters.has(f));
        if (typeFilters.length > 0) {
            processed = processed.filter(item =>
                typeFilters.some(filter => item[filter as keyof PolicyRecord])
            );
        }

        // Apply sorting
        if (sortConfig.field) {
            processed.sort((a, b) => {
                const aValue = a[sortConfig.field as keyof PolicyRecord];
                const bValue = b[sortConfig.field as keyof PolicyRecord];

                if (sortConfig.field === 'createdDate' || sortConfig.field === 'lastUpdated') {
                    const aDate = new Date(aValue as Date);
                    const bDate = new Date(bValue as Date);
                    return sortConfig.direction === 'asc'
                        ? aDate.getTime() - bDate.getTime()
                        : bDate.getTime() - aDate.getTime();
                }

                const aString = String(aValue || '');
                const bString = String(bValue || '');
                return sortConfig.direction === 'asc'
                    ? aString.localeCompare(bString)
                    : bString.localeCompare(aString);
            });
        }

        console.log("Processed data:", processed);
        return processed;
    }, [items, searchQuery, sortConfig, activeFilters, columnFilters]);

    const currentPageData = filteredAndSortedData.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    return (
        <div className="application-table-container">
            <div className="application-table-search">
                {canEdit && <button onClick={() => setIsAddModalOpen(true)} className="add-threat-model-btn">
                    <FaPlus /> Create New Policy
                </button>}
                <button onClick={(e) => setFilterMenuAnchor(e.currentTarget)} className="add-threat-model-btn">
                    <MdOutlineFilterList /> Filters
                </button>
                <TextField
                    placeholder="Search..."
                    variant="outlined"
                    size="small"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <FaSearch className="search-icon" />
                            </InputAdornment>
                        ),
                        endAdornment: searchQuery && (
                            <InputAdornment position="end">
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setSearchQuery('');
                                        localStorage.removeItem('searchQuery');
                                    }}
                                >
                                    <RiCloseCircleFill className="clear-icon" />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </div>

            <Menu
                anchorEl={filterMenuAnchor}
                open={Boolean(filterMenuAnchor)}
                onClose={() => setFilterMenuAnchor(null)}
                PaperProps={{
                    className: "column-menu-paper"
                }}
            >
                <div className="filter-section">
                    <h3>Policy Status</h3>
                    <div className="checkbox-container">
                        <input
                            type="checkbox"
                            id="filter-active"
                            checked={activeFilters.has('isActive')}
                            onChange={() => toggleTypeFilter('isActive')}
                        />
                        <span>Active</span>
                    </div>
                </div>

                <div className="clear-filters-section">
                    <button
                        onClick={() => {
                            setSearchQuery('');
                            setColumnFilters({});
                            setActiveFilters(new Set());
                            localStorage.removeItem('searchQuery');
                            localStorage.removeItem('columnFilters');
                            localStorage.removeItem('activeFilters');
                            setFilterMenuAnchor(null);
                        }}
                        className="clear-all-btn"
                        disabled={!searchQuery && Object.keys(columnFilters).length === 0 && activeFilters.size === 0}
                    >
                        <MdOutlineFilterListOff />
                        Clear All Filters
                    </button>
                </div>
            </Menu>

            <TableContainer component={Paper} className="application-table-paper">
                <Table>
                    <TableHead>
                        <TableRow>
                            {COLUMNS.map((column) => (
                                <TableCell key={column.field}>
                                    {renderColumnHeader(column)}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentPageData.map((row) => (
                            <TableRow key={row.id} hover>
                                {COLUMNS.map((column) => (
                                    <TableCell key={column.field}>
                                        {column.field === 'policyName' && (
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                                <div className="canonical-id-container">
                                                    <NavLink to={`/policy-manager/${row.id}`} className="application-table-link">
                                                        {row.policyName}
                                                    </NavLink>
                                                </div>
                                                <div className="status-indicators">
                                                    {row.isDraft && <span className="status-dot draft" title="Draft">D</span>}
                                                </div>
                                            </div>
                                        )}
                                        {column.field === 'createdBy' && (
                                            <span className="creator-name">{row.createdBy}</span>
                                        )}
                                        {column.field === 'isActive' && (
                                            <span className={`status-text ${row[column.field] ? 'active' : 'inactive'}`}>
                                                {row[column.field] ? 'Active' : 'Inactive'}
                                            </span>
                                        )}
                                        {column.field === 'createdDate' && (
                                            new Date(row[column.field]).toLocaleDateString()
                                        )}
                                        {column.field === 'lastUpdated' && (
                                            new Date(row[column.field]).toLocaleDateString()
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                count={filteredAndSortedData.length}
                page={page}
                onPageChange={(_, newPage) => setPage(newPage)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(event) => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setPage(0);
                }}
                className="application-table-pagination"
            />

            <PolicyForm
                isOpen={isAddModalOpen}
                onClose={() => setIsAddModalOpen(false)}
            />
        </div>
    );
};

export default PolicyList;