import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
import { motion } from "framer-motion";
import { IoIosApps, IoMdTrash } from "react-icons/io";
import { FaTrash } from "react-icons/fa6";
import { MdEdit, MdSave, MdEditOff, MdCheck, MdError } from "react-icons/md";
import {
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, TablePagination, Paper,
    IconButton
} from '@mui/material';
import { Oval } from 'react-loader-spinner';
import AnimatedShieldLoader from '../shared/AnimatedShieldLoader';
import { PolicyRecord } from './PolicyRecord';
import { PolicyDetailsModel } from "./PolicyDetailsModel";
import { NavLink } from "react-router-dom";

interface EditableFieldProps {
    value: string | boolean;
    onEdit: (value: string | boolean) => void;
    type?: 'text' | 'boolean';
    isEditMode: boolean;
}

const EditableField: React.FC<EditableFieldProps> = ({ value, onEdit, type = 'text', isEditMode }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedValue, setEditedValue] = useState(String(value ?? ''));

    const handleBlur = () => {
        setIsEditing(false);
        if (type !== 'boolean') {
            onEdit(editedValue);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const newValue = e.target.value;
        setEditedValue(newValue);
        if (type === 'boolean') {
            onEdit(newValue === 'Yes');
        }
    };

    if (type === 'boolean' && isEditMode) {
        return (
            <select
                value={value ? 'Yes' : 'No'}
                onChange={handleChange}
                onBlur={handleBlur}
                className="boolean-select"
            >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
        );
    }

    return (
        <div className="editable-field">
            {isEditing && isEditMode ? (
                <input
                    type="text"
                    value={editedValue}
                    onChange={(e) => setEditedValue(e.target.value)}
                    onBlur={handleBlur}
                    onKeyDown={(e) => e.key === 'Enter' && handleBlur()}
                    autoFocus
                />
            ) : (
                <div className="display-value">
                    <span>{editedValue}</span>
                    {isEditMode && (
                        <MdEdit className="edit-icon" onClick={() => setIsEditing(true)} />
                    )}
                </div>
            )}
        </div>
    );
};

const fetchPolicyDetails = async (policyId: string | undefined): Promise<PolicyDetailsModel> => {
    if (!policyId) throw new Error("Policy ID is required");

    const response = await axios.get(`/policies/${policyId}`);
    const usersResponse = await axios.get("/userInfo");
    const accessResponse = await axios.get(`/access-check/policy`);
    const assetsResponse = await await axios.get("/assets/all");
    const policiesResponse = await axios.get("/policies");
    response.data.createdBy = usersResponse.data.filter((x: any) => x.id === response.data.createdBy)[0].email;

    return {
        canEdit: accessResponse.data,
        policy: response.data,
        assets: assetsResponse.data,
        policies: policiesResponse.data
    };
};

export const PolicyDetails: React.FC = () => {
    const params = useParams<{ id: string }>();
    const [editedValues, setEditedValues] = useState<Partial<PolicyRecord>>({});
    const [hasChanges, setHasChanges] = useState(false);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isAboutToActivate, setIsAboutToActivate] = useState(false);

    const { data, isLoading, refetch } = useQuery(
        ['policy', params.id],
        () => fetchPolicyDetails(params.id),
        {
            enabled: !!params.id,
            refetchOnWindowFocus: false,
        }
    );

    const handleEdit = (field: keyof PolicyRecord, value: string | boolean) => {
        if (field === 'isActive' && value === true) {
            setIsAboutToActivate(true);
        }
        setHasChanges(true);
        setEditedValues(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const getValue = (field: keyof PolicyRecord): string | boolean => {
        const value = field in editedValues
            ? editedValues[field]
            : data?.policy[field];

        if (field === 'createdDate' || field === 'lastUpdated') {
            return (value as Date)?.toLocaleDateString() ?? '';
        }

        if (field === 'isActive' || field === 'isDraft') {
            return Boolean(value);
        }

        return String(value ?? '');
    };

    const handleSave = async () => {
        if (!data?.policy.id) return;
        if (isAboutToActivate) {
            let affectedAssets = data.assets.filter(asset => data.policy.assetSelector.split('|').includes(asset.id) && asset.customPolicyId && asset.customPolicyId !== data.policy.id);
            if (affectedAssets.length > 0) {
                let errors: string[] = []
                errors.push("Some assets already have a policy assigned.");
                affectedAssets.forEach(asset => {
                    errors.push(`${asset.canonicalId} -> ${data.policies.find(x => x.isActive && x.id === asset.customPolicyId)?.policyName}`);
                });
                errors.push("Cannot activate policy.");
                setErrorMessages(errors);
                setTimeout(() => setErrorMessages([]), 5000);
                return;
            }
        }
        setIsSaving(true);
        try {
            const updatedPolicy = {
                ...data.policy,
                ...editedValues
            };

            const response = await axios.put(`/policies`, updatedPolicy);

            if (response.status === 200) {
                setErrorMessages([]);
                setSuccessMessage('Policy saved successfully.');
                setTimeout(() => setSuccessMessage(null), 5000);
                setIsEditMode(false);
                setHasChanges(false);
                setIsAboutToActivate(false);
                await refetch();
            }
        } catch (error: any) {
            const message = error.response?.data?.message || 'Failed to save policy';
            setErrorMessages([message]);
            setTimeout(() => setErrorMessages([]), 5000);
        } finally {
            setIsSaving(false);
        }
    };

    const handleDelete = async () => {
        if (!data?.policy.id) return;

        const confirmDelete = window.confirm('Are you sure you want to delete this policy? This action cannot be undone.');
        if (!confirmDelete) return;

        setIsDeleting(true);
        try {
            await axios.delete(`/policies/${data.policy.id}`);
            window.location.href = '/policy-manager';
        } catch (error: any) {
            const message = error.response?.data?.message || 'Failed to delete policy';
            setErrorMessages([message]);
            setTimeout(() => setErrorMessages([]), 5000);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleAssetRemove = async (assetId: string) => {
        const confirmDelete = window.confirm(`Are you sure you want to unassign this asset? This action cannot be undone.`);
    
        if (!confirmDelete) return;
        if(data?.policy?.assetSelector){
            data.policy.assetSelector = data.policy.assetSelector.split('|').filter(id => id !== assetId).join('|');
            await axios.put(`/policies/unassign-asset/${data.policy.id}/${assetId}`);
            await refetch();
        }
    }

    const startEdit = () => {
        if(data?.policy.assetSelector && data.policy.assetSelector.length > 0 && data.policy.isActive){
            setErrorMessages(["Cannot edit an active policy with assigned assets."]);
            setTimeout(() => setErrorMessages([]), 5000);
            return;
        }
        setIsEditMode(true);
    }

    if (isLoading) {
        return (
            <div id="loader">
                <AnimatedShieldLoader />
            </div>
        );
    }

    if (!data) {
        return <div className="error-message">Custom policy not found</div>;
    }

    return (
        <div className="asset-record">
            <motion.header
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                className="asset-header"
            >
                <div className="header-content">
                    <div className="app-icon">
                        <IoIosApps size={32} />
                    </div>
                    <div className="title-badges">
                        <h1>{String(getValue('policyName'))}</h1>
                        <div className="badges">
                            {data?.policy.isDraft && (
                                <div className="environment-badge draft">
                                    <span>Draft</span>
                                </div>
                            )}
                            {Boolean(getValue('isActive')) && (
                                <div className="environment-badge flagship">
                                    <span>Active</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="header-actions">
                    {!isEditMode ? (
                        data.canEdit &&   <button
                            onClick={() => startEdit()}
                            className="save-btn"
                            disabled={isDeleting || isSaving}
                        >
                            <MdEdit />
                            Edit
                        </button>
                    ) : (
                        <>
                            <button
                                onClick={handleDelete}
                                className="delete-btn"
                                disabled={isDeleting || isSaving}
                            >
                                {isDeleting ? (
                                    <>
                                        <Oval
                                            visible={true}
                                            height="16"
                                            width="16"
                                            color="#ffffff"
                                            secondaryColor="#ffffff"
                                            ariaLabel="loading"
                                            strokeWidth={4}
                                        />
                                        Deleting...
                                    </>
                                ) : (
                                    <>
                                        <FaTrash />
                                        Delete
                                    </>
                                )}
                            </button>
                            <button
                                onClick={() => {
                                    setIsEditMode(false);
                                    setEditedValues({});
                                    setHasChanges(false);
                                }}
                                className="save-btn"
                                disabled={isDeleting || isSaving}
                            >
                                <MdEditOff />
                                Cancel
                            </button>
                            {hasChanges && (
                                <button
                                    onClick={handleSave}
                                    className="save-btn"
                                    disabled={isDeleting || isSaving}
                                >
                                    {isSaving ? (
                                        <>
                                            <Oval
                                                visible={true}
                                                height="16"
                                                width="16"
                                                color="#ffffff"
                                                secondaryColor="#ffffff"
                                                ariaLabel="loading"
                                                strokeWidth={4}
                                            />
                                            Saving...
                                        </>
                                    ) : (
                                        <>
                                            <MdSave />
                                            Save
                                        </>
                                    )}
                                </button>
                            )}
                        </>
                    )}
                </div>
            </motion.header>

            {errorMessages.length > 0 && (
                <div className="error-message">
                    {errorMessages.map((message, index) => (
                        <div key={index}><MdError /> {message}</div>
                    ))}
                </div>
            )}

            {successMessage && (
                <div className="success-message">
                    <MdCheck /> {successMessage}
                </div>
            )}

            <div className="asset-content">
                <div className="section-card">
                    <div className="section-header">
                        <h3>Policy Details</h3>
                    </div>
                    <div className="section-content">
                        <div className="info-grid">
                            <div className="info-item">
                                <span className="label">Policy Name</span>
                                <EditableField
                                    value={getValue('policyName') as string}
                                    onEdit={(value) => handleEdit('policyName', value)}
                                    isEditMode={isEditMode}
                                />
                            </div>
                            <div className="info-item">
                                <span className="label">Active</span>
                                <EditableField
                                    value={getValue('isActive') as boolean}
                                    onEdit={(value) => handleEdit('isActive', value)}
                                    type="boolean"
                                    isEditMode={isEditMode}
                                />
                            </div>
                            <div className="info-item">
                                <span className="label">Created By</span>
                                <span className="value">{data?.policy.createdBy}</span>
                            </div>
                            <div className="info-item">
                                <span className="label">Created Date</span>
                                <span className="value">
                                    {new Date(data?.policy.createdDate as Date).toLocaleDateString()}
                                </span>
                            </div>
                            <div className="info-item">
                                <span className="label">Last Updated</span>
                                <span className="value">
                                    {new Date(data?.policy.lastUpdated as Date).toLocaleDateString()}
                                </span>
                            </div>
                            <div className="info-item">
                                <span className="label">Critical Grace Period</span>
                                <span className="value">
                                    {(data?.policy.severityConfigs.critical.enabled ? `${data.policy.severityConfigs.critical.gracePeriod} day(s)` : 'Not Tracked')}
                                </span>
                            </div>
                            <div className="info-item">
                                <span className="label">High Grace Period</span>
                                <span className="value">
                                    {(data?.policy.severityConfigs.high.enabled ? `${data.policy.severityConfigs.high.gracePeriod} day(s)` : 'Not Tracked')}
                                </span>
                            </div>
                            <div className="info-item">
                                <span className="label">Medium Grace Period</span>
                                <span className="value">
                                    {(data?.policy.severityConfigs.medium.enabled ? `${data.policy.severityConfigs.medium.gracePeriod} day(s)` : 'Not Tracked')}
                                </span>
                            </div>
                            <div className="info-item">
                                <span className="label">Low Grace Period</span>
                                <span className="value">
                                    {(data?.policy.severityConfigs.low.enabled ? `${data.policy.severityConfigs.low.gracePeriod} day(s)` : 'Not Tracked')}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-card">
                    <div className="section-header">
                        <h3>Applied To</h3>
                    </div>
                    <div className="section-content">
                        {data?.policy.assetSelector ? (
                            data.policy.assetSelector === 'all' ? (
                                <div className="target-asset-item all-assets">All Assets</div>
                            ) : (
                                <div className="application-table-container">
                                    <TableContainer component={Paper} className="application-table-paper">
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Canonical ID</TableCell>
                                                    <TableCell>Product Family</TableCell>
                                                    <TableCell>Application</TableCell>
                                                    <TableCell>Component</TableCell>
                                                    {data?.canEdit && <TableCell>
                                                        Actions
                                                    </TableCell>}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data.assets.filter(asset => data.policy.assetSelector.split('|').includes(asset.id)).map((asset, index) => (
                                                    <TableRow hover key={index}>
                                                        <TableCell>
                                                            <div className="canonical-id-container">
                                                                <NavLink to={`/assets/${asset.id}`} className="application-table-link">
                                                                    {asset.canonicalId}
                                                                </NavLink>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>{asset.productFamilyName}</TableCell>
                                                        <TableCell>{asset.applicationName}</TableCell>
                                                        <TableCell>{asset.componentName}</TableCell>
                                                        {data?.canEdit  && <TableCell>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => handleAssetRemove(asset.id)}
                                                                title="Unassign asset"
                                                                >
                                                                <IoMdTrash />
                                                            </IconButton>
                                                        </TableCell>}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    <TablePagination
                                        component="div"
                                        count={data.policy.assetSelector.split('|').length}
                                        page={page}
                                        onPageChange={(_, newPage) => setPage(newPage)}
                                        rowsPerPage={rowsPerPage}
                                        onRowsPerPageChange={(event) => {
                                            setRowsPerPage(parseInt(event.target.value, 10));
                                            setPage(0);
                                        }}
                                        className="application-table-pagination"
                                    />
                                </div>
                            )
                        ) : (
                            <div className="empty-state">
                                <p>No assets are currently covered by this policy. Apply this policy to assets in the policy configuration.</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PolicyDetails;