import React, { useState } from 'react';
import {
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, TablePagination,
    IconButton,
} from '@mui/material';
import { FaPlus, FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { Exception } from './Exception';
import { IoMdTrash } from 'react-icons/io';
//import axios from 'axios';

interface Props {
    exceptions: Exception[];
    onAddException: () => void;
    onDeleteException: (id: string) => void;
    canEdit: boolean;
}

const COLUMNS = [
    { field: 'expand', label: '' },
    { field: 'exceptionName', label: 'Exception Name' },
    { field: 'createdBy', label: 'Creator' },
    { field: 'createdDate', label: 'Created Date' },
    { field: 'updatedDate', label: 'Updated Date' },
    { field: 'status', label: 'Status' }
];

export const ExceptionsTable: React.FC<Props> = ({ exceptions, onAddException, onDeleteException, canEdit }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});

    const sortedExceptions = [...exceptions].sort((a, b) => {
        return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
    });

    const currentPageData = sortedExceptions.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    const toggleRowExpansion = (id: string) => {
        setExpandedRows(prev => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    // const deleteException = (id: string) => {
    //     exceptions = exceptions.filter(exception => exception.id !== id);
    //     axios.delete(`/exception-management/${id}`);
    // }

    const renderConditionDetails = (exception: Exception) => {
        if (!exception.conditions || exception.conditions.length === 0) {
            return <p>No conditions defined</p>;
        }

        const actionType = exception.conditions[0]?.action || 'ignore';
        const capitalizedAction = actionType.charAt(0).toUpperCase() + actionType.slice(1);

        // Map operator words to symbols
        const operatorSymbols: Record<string, string> = {
            'equals': '=',
            'contains': '⊃',
            'startsWith': '^',
            'endsWith': '$',
            'matches': '≈'
        };

        return (
            <div className="exception-details">
                <span className="condition-item"><strong>{capitalizedAction}</strong></span>
                <span className="condition-separator">IF</span>

                {exception.conditions.map((condition, idx) => {
                    const typeLabels: Record<string, string> = {
                        'file_path': 'File Path',
                        'file_name': 'File Name',
                        'module': 'Module',
                        'cwe': 'CWE',
                        'cve': 'CVE'
                    };

                    const valueToDisplay = condition.value;
                    const operatorSymbol = operatorSymbols[condition.operator] || '=';

                    return (
                        <React.Fragment key={idx}>
                            {idx > 0 && <span className="condition-separator">AND</span>}
                            <span className="condition-item">
                                <strong>{typeLabels[condition.type]}</strong> <span className="operator-symbol">{operatorSymbol}</span> <strong>{valueToDisplay}</strong>
                            </span>
                        </React.Fragment>
                    );
                })}
            </div>
        );
    };

    return (
        <div className="application-table-container">
            <div className="application-table-search">
                {canEdit && 
                <button onClick={onAddException} className="add-threat-model-btn">
                    <FaPlus /> Add Exception
                </button>}
            </div>

            <TableContainer component={Paper} className="application-table-paper">
                <Table>
                    <TableHead>
                        <TableRow>
                            {COLUMNS.map((column) => (
                                <TableCell
                                    key={column.field}
                                    className={column.field === 'expand' ? 'expand-cell' : ''}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                            {canEdit &&
                            <TableCell>
                                Actions
                            </TableCell>}
                        </TableRow>
                        
                    </TableHead>
                    <TableBody>
                        {currentPageData.map((row) => (
                            <React.Fragment key={row.id}>
                                <TableRow hover>
                                    <TableCell className="expand-cell">
                                        <button
                                            onClick={() => toggleRowExpansion(row.id)}
                                            className="expand-collapse-btn"
                                            aria-label={expandedRows[row.id] ? "Collapse details" : "Expand details"}
                                        >
                                            {expandedRows[row.id] ? <FaChevronDown /> : <FaChevronRight />}
                                        </button>
                                    </TableCell>
                                    <TableCell>
                                        <div>
                                            <div className="canonical-id-container">
                                                {row.exceptionName}
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <span className="creator-name">{row.createdBy}</span>
                                    </TableCell>
                                    <TableCell>
                                        {new Date(row.createdDate).toLocaleDateString()}
                                    </TableCell>
                                    <TableCell>
                                        {new Date(row.updatedDate).toLocaleDateString()}
                                    </TableCell>
                                    <TableCell>
                                        <span className={`status-text ${row.status.toLowerCase()}`}>
                                            {row.status}
                                        </span>
                                    </TableCell>
                                    {canEdit && <TableCell>
                                        <IconButton
                                            size="small"
                                            onClick={() => onDeleteException(row.id)}
                                            title="Delete exception"
                                            >
                                            <IoMdTrash />
                                        </IconButton>
                                    </TableCell>}
                                </TableRow>
                                {expandedRows[row.id] && (
                                    <TableRow className="expanded-row">
                                        <TableCell colSpan={7} className="expanded-cell">
                                            {renderConditionDetails(row)}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                count={exceptions.length}
                page={page}
                onPageChange={(_, newPage) => setPage(newPage)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(event) => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setPage(0);
                }}
                className="application-table-pagination"
            />
        </div>
    );
};

export default ExceptionsTable;